import { AnyAction } from "redux";
import { GET_USER, LOGIN_USER, LOGOUT_USER } from "../actions/Actions";

const initState = {
	user: null,
};

export const userReducer = (state = initState, action: AnyAction) => {
	switch (action.type) {
		case LOGIN_USER:
			return {
				...state,
				user: action.payload,
			};
		case GET_USER:
			return {
				...state,
				user: action.payload,
			};
		case LOGOUT_USER:
			return {
				...state,
				user: null,
			};
		default:
			return {
				...state,
			};
	}
};
