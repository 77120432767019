import React, { useEffect, useState } from "react";
import Tool from "../../../tool/components/Tool";
import { useParams } from "react-router-dom";
import { Project, ProjectState } from "../../../types/Project";
import ProjectService from "../../../services/ProjectService";
import { AppMode } from "../../../tool/components/context/AppModeContext";
import { View as ViewType } from "../../../types/View";
import { getHomeView } from "../../../tool/components/utils/projectUtils";

const View = () => {
	const { id } = useParams<{ id: string }>();
	const [project, setProject] = useState<ProjectState>();

	const convertProjectToProjectState = (project: Project): ProjectState => {
		const homeView: ViewType = getHomeView(project);
		return {
			...project,
			currentView: homeView,
		};
	};

	useEffect(() => {
		const href: string = window.location.href;
		const substring = "draw";

		if (href.includes(substring)) {
			const token = localStorage.getItem("AccessToken");
			if (token && id) {
				ProjectService.getProjectByPrimaryIdPlain(id, token || "").then(
					(project: Project) => {
						const projectState = convertProjectToProjectState(project);
						setProject(projectState);
					}
				);
			}
		} else {
			ProjectService.getProjectPlain(id).then(project => {
				const projectState = convertProjectToProjectState(project);
				setProject(projectState);
			});
		}
	}, []);

	return <>{project && <Tool project={project} appMode={AppMode.view} />}</>;
};

export default View;
