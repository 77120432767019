import React, { useCallback, useContext, useEffect, useState } from "react";
import { AriaViewUploaderComponent } from "../AriaViewUploaderComponent";
import { MapBuild } from "./map/MapBuild";
import ConfirmationPopUp from "../ConfirmationPopUp";
import { ToolBoxComponent } from "./toolbox/ToolBoxComponent";
import { HouseContext } from "../context/HouseContext";
import { ToolboxContext } from "../context/ToolboxContext";
import { AppMode, AppModeContext } from "../context/AppModeContext";
import { ProjectState } from "../../../types/Project";
import { PolygonType } from "../../../types/PolygonType";
import { GuideLineType } from "../../../types/GuideLineType";
import { DEFAULT_EDITOR_SETTINGS } from "../../../types/EditorSettings";
import { ToolProps } from "../Tool";
import cloneDeep from "clone-deep";
import { useDrop, XYCoord } from "react-dnd";
import update from "immutability-helper";
import { DragItem } from "../../../types/DragItem";
import { House } from "../../../types/House";
import { View } from "../../../types/View";
import { ToolBox, ToolBoxImage, ToolPanelWrapper } from "./ToolPanelElements";

export const ToolPanel = ({
	project,
	updateProjectData,
	appMode,
	publishButtons,
	stepMode,
	setToolView,
	tableButton,
	isDrawingPage,
	handleUndo,
	handleRedo,
}: ToolProps) => {
	const [stateProject, setStateProject] = useState<ProjectState | null>(project);
	const [savedPolygons, setSavedPolygons] = useState<PolygonType[]>([]);
	const [savedGuideLines, setSavedGuideLines] = useState<GuideLineType[]>([]);
	const [imageLoadUrl, setImageLoadUrl] = useState<string>("");
	const [selectedPlotId, setSelectedPlotId] = useState<string | null>(null);
	const [isAlertPolygonShown, setIsAlertPolygonShown] = useState<boolean>(false);
	const [isUndoHistoryPresent, setIsUndoHistoryPresent] =
		useState<boolean>(false);
	const [isRedoHistoryPresent, setIsRedoHistoryPresent] =
		useState<boolean>(false);
	const [houses, setHouses] = useState<House[]>([]);
	const [views, setViews] = useState<View[]>([]);

	const { house, setHouse, setNewPlot, polygonId, setPolygonId } =
		useContext(HouseContext);

	const { setAppMode } = useContext(AppModeContext);
	const {
		isDrawingButtonClicked,
		setIsDrawingButtonClicked,
		isEditButtonClicked,
		setIsEditButtonClicked,
		setIsSavePolygonButtonClicked,
		isAddInnerLineButtonClicked,
		setIsAddInnerLineButtonClicked,
		toolBoxShown,
		setIsPointerButtonClicked,
		isDrawGuideLineButtonClicked,
	} = useContext(ToolboxContext);

	const [toolDnd, setToolDnd] = useState<{
		[key: string]: {
			top: number;
			left: number;
		};
	}>({
		tool: { top: 100, left: 150 },
	});

	useEffect(() => {
		setAppMode(appMode || AppMode.edit);
	}, []);

	useEffect(() => {
		if (project && imageLoadUrl !== "") {
			setAerialViewImage(imageLoadUrl);

			const updatedViews: View[] = cloneDeep(project.views);
			const viewIndexToRemove = project.views.findIndex(
				(view: View) => view.id === project.currentView.id
			);
			const viewToUpdate = project.views[viewIndexToRemove];
			updatedViews.splice(viewIndexToRemove, 1);
			viewToUpdate.aerialViewImage = imageLoadUrl;
			updatedViews.push(viewToUpdate);

			updateProjectData &&
				updateProjectData({
					...project,
					currentView: {
						...project.currentView,
						aerialViewImage: imageLoadUrl,
					},
					views: updatedViews,
				});
		}
	}, [imageLoadUrl]);

	useEffect(() => {
		if (project) {
			setStateProject(project);

			const sortedHouses = sortHousesByCustomId(project.houses);
			const sortedViews = sortViewsByNumber(project.views);

			setHouses(sortedHouses);
			setViews(sortedViews);
		}
	}, [project]);

	useEffect(() => {
		setAppMode(appMode || AppMode.edit);
		if (appMode === AppMode.view) {
			setIsDrawingButtonClicked(false);
			setIsEditButtonClicked(false);
		}
	}, [appMode]);

	useEffect(() => {
		if (stateProject) {
			setAerialViewImage(stateProject.currentView.aerialViewImage);
		}
	}, [stateProject]);

	useEffect(() => {
		if (!isDrawingButtonClicked && !isDrawGuideLineButtonClicked) {
			setIsPointerButtonClicked(true);
			setPolygonId(null);
			setSelectedPlotId(null);
		} else {
			setIsPointerButtonClicked(false);
		}
	}, [isDrawingButtonClicked, isDrawGuideLineButtonClicked]);

	useEffect(() => {
		if (stateProject) {
			updateSavedPolygons(stateProject);
		}

		if (stateProject?.currentView.viewProperties?.guideLines) {
			setSavedGuideLines(stateProject.currentView.viewProperties.guideLines);
		} else {
			setSavedGuideLines([]);
		}
	}, [stateProject]);

	if (!stateProject) {
		return (
			<ConfirmationPopUp
				actionPopupButtonLabel={"Ok"}
				customClosePopUpButton={null}
				isOpen={true}
				closePopUp={() => {}}
				confirmationLabel={"Project does not exists"}
				confirmationText={
					<>
						Click <b>Ok</b> button to return to projects list
					</>
				}
				action={() => {
					setToolView && setToolView(false);
				}}
			/>
		);
	}

	const extractId = (customId: string): number => {
		let idEndPosition = 0;
		for (let i = customId.length - 1; i >= 0; i--) {
			const char = +customId.charAt(i);
			if (!isNaN(char)) {
				idEndPosition = i + 1;
				break;
			}
		}

		const id = customId.toString().substring(0, idEndPosition);
		return +id;
	};

	const sortHousesByCustomId = (houses: House[]): House[] => {
		const housesToSort = cloneDeep(houses);
		return housesToSort.sort(
			(a: House, b: House) =>
				extractId(a.houseProperties.customId.toString()) -
				extractId(b.houseProperties.customId.toString())
		);
	};

	const sortViewsByNumber = (views: View[]): View[] => {
		const viewToSort = cloneDeep(views);
		return viewToSort.sort((a: View, b: View) => a.viewNumber - b.viewNumber);
	};

	const updateSavedPolygons = (project: ProjectState) => {
		const views: View[] = project.views;
		let polygons: PolygonType[] = [];
		for (const view of views) {
			if (view.polygons) {
				polygons = [...polygons, ...view.polygons];
			}
		}
		setSavedPolygons(polygons);
	};

	const [aerialViewImage, setAerialViewImage] = useState(
		stateProject.currentView.aerialViewImage
	);

	const handleAlertClose = () => {
		setIsAlertPolygonShown(false);
	};

	const checkPolygonPointsAmount = (polygons: PolygonType[]): boolean => {
		for (const polygon of polygons) {
			if (polygon.points.length < 3) {
				return true;
			}
		}
		return false;
	};

	function savePolygons(polygons: PolygonType[]) {
		// TODO 6th step - saving polygons
		if (polygons.length === 0) {
			return;
		}
		const houseToSave = stateProject?.houses?.find(
			statePolygonHouse => statePolygonHouse.id === house!.id
		);
		const viewToSave = stateProject?.views.find(
			statePolygonView => statePolygonView.id === project.currentView.id
		);

		if (!houseToSave || !viewToSave) {
			return;
		}

		houseToSave.polygons = polygons.filter(
			polygon => polygon.houseId === houseToSave.id
		);
		viewToSave.polygons = polygons.filter(
			polygon => polygon.viewId === viewToSave.id
		);
		if (
			houseToSave.polygons.length === 0 ||
			checkPolygonPointsAmount(houseToSave.polygons)
		) {
			setIsAlertPolygonShown(true);
		} else {
			setSavedPolygons(polygons);
			setIsSavePolygonButtonClicked(false);
			updateProjectData && updateProjectData(project);
		}
	}

	const handleDrawPolygon = () => {
		if (!house) {
			setHouse(stateProject.houses[0]);
		}

		setPolygonId(null);
		setSelectedPlotId(null);
		setIsDrawingButtonClicked(!isDrawingButtonClicked);
	};

	const handleEditPolygon = () => {
		if (!house) {
			return;
		}

		if (isEditButtonClicked) {
			setIsEditButtonClicked(false);
		} else {
			setIsEditButtonClicked(true);
		}
	};

	const handleDrawInnerLine = () => {
		setIsAddInnerLineButtonClicked(!isAddInnerLineButtonClicked);
	};

	const handleDeletePolygon = async () => {
		if (!house) {
			return;
		}

		const findHouse = stateProject.houses.find(
			statePolygonHouse => statePolygonHouse.id === house.id
		);
		const findView = stateProject.currentView;

		if (findHouse && findHouse.polygons.length > 0 && polygonId) {
			const deletedIndex = findHouse.polygons.findIndex(
				polygon => polygon.id === polygonId
			);

			const deleteFromViewIndex = findView.polygons.findIndex(
				(polygon: PolygonType) => polygon.id === polygonId
			);

			const index = savedPolygons.findIndex(polygon => polygon.id === polygonId);
			setSavedPolygons([...savedPolygons.splice(index, 1)]);
			findHouse.polygons.splice(deletedIndex, 1);
			findView.polygons.splice(deleteFromViewIndex, 1);

			const findHouseInProject = stateProject.houses.findIndex(
				house => house.id === findHouse.id
			);
			const findViewInProject = stateProject.views.findIndex(
				view => view.id === findView.id
			);

			const newProject = cloneDeep(stateProject);
			newProject.houses[findHouseInProject].polygons.splice(deletedIndex, 1);
			newProject.views[findViewInProject].polygons.splice(deleteFromViewIndex, 1);
			newProject.currentView = newProject.views[findViewInProject];

			setStateProject({ ...newProject });

			updateProjectData && updateProjectData(stateProject);
		}
		setPolygonId(null);
		setNewPlot(null);
		setIsEditButtonClicked(false);
	};

	function handleSaveGuideLine(line: GuideLineType, moving?: boolean) {
		const lineToSave = { ...line };
		if (stateProject && stateProject.id) {
			const stateProjectCopy = cloneDeep(stateProject);
			const viewIndexToUpdate = stateProjectCopy.views.findIndex(
				(view: View) => view.id === stateProjectCopy.currentView.id
			);
			const guideLines =
				stateProjectCopy?.views[viewIndexToUpdate].viewProperties?.guideLines;
			if (moving) {
				if (guideLines) {
					const lineToDeleteIndex = guideLines.findIndex(
						(line: any) => line.id === lineToSave.id
					);
					guideLines.splice(lineToDeleteIndex, 1);
				}
			}
			if (stateProjectCopy?.views[viewIndexToUpdate].viewProperties?.guideLines) {
				stateProjectCopy.views[viewIndexToUpdate].viewProperties!.guideLines = [
					...stateProjectCopy.views[viewIndexToUpdate].viewProperties!.guideLines!,
					lineToSave,
				];
			} else if (!stateProjectCopy.views[viewIndexToUpdate].viewProperties) {
				stateProjectCopy.views[viewIndexToUpdate].viewProperties = {
					guideLines: [lineToSave],
				};
			}
			updateProjectData && updateProjectData({ ...stateProjectCopy });
		}
	}

	function handleDeleteGuideLine(guideLine: GuideLineType) {
		if (stateProject && stateProject.currentView.viewProperties?.guideLines) {
			const guideLines: GuideLineType[] = [
				...stateProject.currentView.viewProperties.guideLines,
			];
			const lineIndex = guideLines.findIndex(line => line.id === guideLine.id);
			guideLines.splice(lineIndex, 1);
			stateProject.currentView.viewProperties.guideLines = guideLines;

			const updatedProject = { ...stateProject, guideLines };
			updateProjectData && updateProjectData(updatedProject);
		}
	}

	const selectPlot = (plotId: string | null) => {
		if (plotId) {
			setSelectedPlotId(plotId);
			if (plotId.startsWith("polygon")) {
				const polygon: PolygonType | undefined = savedPolygons.find(
					polygon => polygon.id === plotId
				);
				const house = stateProject.houses?.find(h => {
					if (polygon) {
						return polygon.houseId === h.id;
					}
					return false;
				});
				if (house) {
					setHouse(house);
				}
			}
		} else {
			setSelectedPlotId(null);
			setHouse(null!);
			setIsDrawingButtonClicked(false);
		}
	};

	const moveBox = useCallback(
		(id: string, left: number, top: number) => {
			setToolDnd(
				update(toolDnd, {
					[id]: {
						$merge: { left, top },
					},
				})
			);
		},
		[toolDnd, setToolDnd]
	);

	const [, drop] = useDrop(
		() => ({
			accept: "box",
			drop(item: DragItem, monitor) {
				const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
				const left = Math.round(item.left + delta.x);
				const top = Math.round(item.top + delta.y);
				moveBox(item.id, left, top);
				return undefined;
			},
		}),
		[moveBox]
	);

	const image = aerialViewImage ? (
		<MapBuild
			handleUndoPolygon={handleUndo}
			handleRedoPolygon={handleRedo}
			isDrawingPage={isDrawingPage}
			aerialView={aerialViewImage}
			savedPolygons={savedPolygons}
			savePolygons={savePolygons}
			selectedPlot={selectedPlotId}
			setSelectedPlot={selectPlot}
			isAlertPolygonShown={isAlertPolygonShown}
			showPolygonAlert={setIsAlertPolygonShown}
			handleAlertPolygonClose={handleAlertClose}
			savedGuideLines={savedGuideLines}
			saveGuideLine={handleSaveGuideLine}
			deleteGuideLine={handleDeleteGuideLine}
			houses={houses}
			views={views}
			setIsRedoHistoryPresent={setIsRedoHistoryPresent}
			setIsUndoHistoryPresent={setIsUndoHistoryPresent}
			projectEditorSettings={{
				...(stateProject?.currentView.viewProperties?.editorSettings ||
					DEFAULT_EDITOR_SETTINGS),
			}}
			publishButtons={publishButtons}
			stepMode={stepMode}
			projectId={stateProject.id}
			updateProjectData={updateProjectData}
			project={project}
			deletePolygon={handleDeletePolygon}
		/>
	) : (
		<div style={{ width: "25%", paddingLeft: "50%", paddingTop: "50px" }}>
			<AriaViewUploaderComponent setImageLoadUrl={setImageLoadUrl} />
		</div>
	);

	return (
		<ToolPanelWrapper>
			<ToolBox ref={drop}>
				<ToolBoxImage toolBoxShown={toolBoxShown}>{image}</ToolBoxImage>
				{Object.keys(toolDnd).map(key => {
					const { left, top } = toolDnd[key];
					return (
						<ToolBoxComponent
							left={left}
							top={top}
							id={key}
							key={key}
							houses={stateProject?.houses ? stateProject.houses : []}
							editorSettings={
								stateProject?.currentView.viewProperties?.editorSettings
									? stateProject.currentView.viewProperties.editorSettings
									: { ...DEFAULT_EDITOR_SETTINGS }
							}
							updateEditorSettings={editorSettings =>
								setStateProject({
									...stateProject,
									currentView: {
										...stateProject?.currentView,
										viewProperties: {
											...stateProject?.currentView.viewProperties,
											editorSettings,
										},
									},
								})
							}
							drawInnerLine={handleDrawInnerLine}
							isRedoHistory={isRedoHistoryPresent}
							isUndoHistory={isUndoHistoryPresent}
							handleReturnToProjectsList={() => setToolView && setToolView(false)}
							updateProjectData={editorSettings => {
								updateProjectData &&
									updateProjectData({
										...project,
										currentView: {
											...stateProject?.currentView,
											viewProperties: {
												...stateProject?.currentView.viewProperties,
												editorSettings,
											},
										},
									});
							}}
							tableButton={tableButton}
							deletePolygon={handleDeletePolygon}
							editPolygon={handleEditPolygon}
							drawPolygon={handleDrawPolygon}
							handleUndo={handleUndo}
							handleRedo={handleRedo}
							polygonId={polygonId}
							setSelectedPlotId={setSelectedPlotId}
							setImageLoadUrl={setImageLoadUrl}
						/>
					);
				})}
			</ToolBox>
		</ToolPanelWrapper>
	);
};
