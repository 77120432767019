import React from "react";
import { useHistory } from "react-router-dom";
import { PROJECTS_PATH } from "../../utils/constants/PathConstants";
import ProjectList from "./ProjectList";
import NewProjectStep from "../new-user-flow/steps/NewProjectStep";
import { ProjectData } from "../new-user-flow/NewUserFlow";
import { Project } from "../../../types/Project";

const Projects = (props: {
	projects: Project[];
	setProjects: (project: any) => void;
}) => {
	const history = useHistory();
	const [projectData, setProjectData] = React.useState<ProjectData>({
		project: {
			id: "-1",
			name: "",
			currentView: {
				id: "-1",
				name: "",
				viewNumber: 0,
				isHome: true,
				aerialViewImage: "",
				houses: [],
				polygons: [],
			},
			views: [],
			houses: [],
		},
		numberOfHouses: 0,
	});

	return (
		<div>
			{/* <CreateProject/> */}
			<NewProjectStep
				projectData={projectData}
				setProjectData={setProjectData}
				headerless
			/>

			{props.projects && props.projects.length > 0 && (
				<ProjectList
					data={props.projects}
					setData={props.setProjects}
					onGo={(id: string) => {
						history.push(PROJECTS_PATH + id);
					}}
				/>
			)}
		</div>
	);
};

export default Projects;
