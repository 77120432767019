import React, { useEffect, useState } from "react";
import { Project, ProjectState } from "types/Project";
import { getHomeView } from "tool/components/utils/projectUtils";
import ProjectService from "services/ProjectService";
import { ModalWindow } from "./ModalWindow";
import { DrawingPage } from "./DrawingPage";
import { AwaitingRequest, Container } from "./styles";

export const RequestHandler = () => {
	const [project, setProject] = useState<ProjectState>();
	const [activeInterval, setActiveInterval] =
		useState<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		const interval = setInterval(() => {
			ProjectService.checkForProjectRequest().then(res => {
				const data = res.data as { requestId: string; project: Project | null };
				if (data.project) {
					localStorage.setItem(
						"AccessToken",
						data.project?.accessToken?.value || ""
					);
					setProject({
						...data.project,
						currentView: getHomeView(data.project),
					});
				}
			});
		}, 1000);

		setActiveInterval(interval);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (project && activeInterval) {
			clearInterval(activeInterval);
			ProjectService.deleteRequests().then(() => console.log("requests removed"));
		}
	}, [project, activeInterval]);

	if (!project || (project && project.currentView.aerialViewImage === "")) {
		return (
			<Container>
				<AwaitingRequest>Awaiting for Request...</AwaitingRequest>
				{project && <ModalWindow project={project} />}
			</Container>
		);
	}

	return <DrawingPage projectId={project.primaryId!} />;
};
