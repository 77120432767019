import React from "react";
import RegisterStep from "./steps/RegisterStep";
import { ProjectState } from "../../../types/Project";
import { IconType } from "react-icons";

export type ProjectData = {
	numberOfHouses: number;
	project: ProjectState;
};

export type Step = {
	title: string;
	stepCompleted: boolean;
	canReturn?: boolean;
	icon: IconType;
};

const NewUserFlow = () => {
	return (
		<div
			style={{
				display: "flex",
				minHeight: "100vh",
				overflow: "hidden",
			}}
		>
			<RegisterStep />
		</div>
	);
};

export default NewUserFlow;
