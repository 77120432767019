import React from "react";
import { IsHomeSvg } from "./ViewsBarElements";

export const IsHome = (props: {
	isHome: boolean;
	onClick: (event: any) => void;
	setIsBarEventsDisabled?: (value: boolean) => void;
}) => {
	const { isHome, onClick, setIsBarEventsDisabled } = props;

	return (
		<IsHomeSvg
			viewBox='1670.57 102 11.43 10.287'
			isHome={isHome}
			onClick={onClick}
		>
			<path
				d='M1676.285 102s-3.535 3.052-5.51 4.704a.571.571 0 0 0 .367 1.01h1.143v4.001c0 .316.256.572.571.572h1.715a.571.571 0 0 0 .571-.572v-2.286h2.286v2.286c0 .316.256.572.572.572h1.714a.571.571 0 0 0 .572-.572v-4h1.143a.571.571 0 0 0 .571-.572.56.56 0 0 0-.219-.439c-1.962-1.652-5.496-4.704-5.496-4.704Z'
				fill={isHome ? "black" : "transparent"}
				stroke={isHome ? "transparent" : "black"}
			/>
		</IsHomeSvg>
	);
};
