import React, { useState } from "react";
import LabeledInput from "../../../components/form/LabeledInput";
import {
	Form,
	FormButton,
	FormContent,
	FormH1,
	FormWrap,
} from "../../../components/form/FormElements";
import { Container } from "../../../components/layouts/LayoutElements";
import { Link as LinkR } from "react-router-dom";
import { NEW_USER_FLOW_PATH } from "../../../utils/constants/PathConstants";

const LoginForm = (props: {
	onSubmit: (username: string, password: string) => void;
}) => {
	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	return (
		<>
			<Container>
				<FormWrap>
					<FormContent>
						<Form>
							<FormH1>Login</FormH1>
							<LabeledInput
								labelText={"Username"}
								value={username}
								onChange={e => setUsername(e.target.value)}
								labelLeft={true}
								errorText='logInErrorText'
							/>
							<LabeledInput
								labelText={"Password"}
								value={password}
								onChange={e => setPassword(e.target.value)}
								type='password'
								labelLeft={true}
								errorText='logInErrorText'
							/>
							<FormButton onClick={() => props.onSubmit(username, password)}>
								<div style={{ fontFamily: "Galano Grotesque" }}>Login</div>
							</FormButton>
							<div
								style={{
									display: "flex",
									justifySelf: "end",
									width: "315px",
									flexDirection: "column",
									alignItems: "flex-start",
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										marginTop: "30px",
										fontFamily: "Galano Grotesque",
									}}
								>
									{"Don't have an account?"}
									<LinkR
										style={{
											color: "#3392FF",
											marginLeft: "8px",
											fontFamily: "Galano Grotesque",
										}}
										to={NEW_USER_FLOW_PATH}
									>
										{" "}
										Sign Up
									</LinkR>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										marginTop: "15px",
										fontFamily: "Galano Grotesque",
									}}
								>
									Forgot Password?
									<LinkR
										style={{
											color: "#3392FF",
											marginLeft: "8px",
											fontFamily: "Galano Grotesque",
										}}
										to={"new-password"}
									>
										Reset Password
									</LinkR>
								</div>
							</div>
						</Form>
					</FormContent>
				</FormWrap>
			</Container>
		</>
	);
};

export default LoginForm;
