import React, { ReactNode, useEffect, useState } from "react";
import { PublishSVG } from "../../../../../platform/pages/publish/PublishElements";
import { ViewBoxScale } from "../Canvas";
import {
	INITIAL_VIEW_BOX_X_VALUE,
	INITIAL_VIEW_BOX_Y_VALUE,
} from "../../../constants/constants";
import { ExportSvg } from "../../toolbox/ExportSvg";
import { renderToStaticMarkup } from "react-dom/server";
import { ProjectState } from "../../../../../types/Project";
import { exportZip } from "../utils/export";
import { View } from "../../../../../types/View";
import { ptBR } from "@material-ui/core/locale";

type Props = {
	project: ProjectState;
	aerialViewImage?: string;
	children: ReactNode;
};

export type ViewToExport = {
	html: string;
	svgName: string;
	imageName: string;
	imageBlob: Blob;
};

const PublishButton = ({ project, aerialViewImage, children }: Props) => {
	const [viewsToPublish, setViewsToPublish] = useState<ViewToExport[]>([]);

	useEffect(() => {
		if (viewsToPublish.length === project.views.length) {
			exportZip(viewsToPublish, project.name);
			setViewsToPublish([]);
		}
	}, [viewsToPublish]);

	const handleExport = () => {
		project.views.forEach((view: View) => {
			if (view.aerialViewImage) {
				let imageName: string = view.name;
				const svgName: string = `${view.name}.svg`;
				fetch(view.aerialViewImage)
					.then(res => res.blob())
					.then((imageBlob: Blob) => {
						if (imageBlob.type === "image/jpeg") {
							imageName += ".jpg";
						} else if (imageBlob.type === "image/png") {
							imageName += ".png";
						}

						const img = new Image();
						img.src = view.aerialViewImage;

						let viewBoxScale: ViewBoxScale = {
							point1: { x: 0, y: 0 },
							point2: { x: INITIAL_VIEW_BOX_X_VALUE, y: INITIAL_VIEW_BOX_Y_VALUE },
						};

						img.onload = () => {
							const y = (viewBoxScale.point2.x / img.width) * img.height;
							viewBoxScale = {
								point1: { x: 0, y: 0 },
								point2: { x: INITIAL_VIEW_BOX_X_VALUE, y: y },
							};

							const exportSvg = (
								<ExportSvg
									houses={view.houses}
									aerialView={imageName}
									viewBoxScale={viewBoxScale}
								/>
							);
							let html = renderToStaticMarkup(exportSvg);
							html = html.replaceAll(
								"></polygon>",
								' class="polygonStyle"></polygon>'
							);
							const formatter = require("html-formatter");
							html = formatter.render(html);

							setViewsToPublish(prevState => [
								...prevState,
								{
									html,
									svgName,
									imageName,
									imageBlob,
								},
							]);
						};
					});
			}
		});
	};

	return (
		<PublishSVG
			onClick={() => {
				handleExport();
			}}
		>
			{children}
		</PublishSVG>
	);
};

export default PublishButton;
