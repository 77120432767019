import axios from "axios";
import { SVG_URL } from "../platform/utils/constants/UrlConstants";
import { authHeader } from "./AuthHeader";

const saveSvg = (html: string, viewId: string | undefined) => {
	return axios.post(SVG_URL, { html, viewId }, { headers: authHeader() });
};

export default {
	saveSvg,
};
