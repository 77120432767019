import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
	loadImageDownloadUrl,
	saveImage,
} from "../../../persistance/persistence";
import HouseTable from "./house-table/HouseTable";
import TypeTable, { CustomColumnType } from "./type-table/TypeTable";
import ProjectToggle from "../ProjectToggle";
import { BackButton, TableButtonNext } from "./TableElements";
import * as AiIcons from "react-icons/ai";
import { mapHousePropertiesToHouses } from "../../utils/HouseUtils";
import { ProjectState } from "../../../types/Project";
import { HouseViewWrapper, ProjectToggleWrapper } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import HouseService from "../../../services/HouseService";
import { toast } from "react-toastify";
import { House } from "../../../types/House";

export type ProjectTableProps = {
	project: ProjectState;
	additionalColumns?: CustomColumnType[];
	setAdditionalColumns?: (columns: any[]) => void;
	handleSave: (project: ProjectState) => void;
	setIsToolView?: any;
	buttons?: boolean;
};

const ProjectTable = ({
	project,
	additionalColumns,
	setAdditionalColumns,
	handleSave,
	buttons,
	setIsToolView,
}: ProjectTableProps) => {
	const hiddenFileInput = React.useRef(null);

	const [houseIdsToDelete, setHouseIdsToDelete] = useState<string[]>([]);
	const projectGlobal: ProjectState = useSelector(
		(state: any) => state.projectReducer.project
	);

	const [isProjectView, setProjectView] = useState<boolean>(true);

	const [file, setFile] = useState<any>();
	const [stateProject, setStateProject] = useState<ProjectState>(project || {});
	const [houseData, setHouseData] = useState<House[]>(project && project.houses);

	useEffect(() => {
		houseIdsToDelete.forEach(id => {
			HouseService.deleteHouse(id)
				.then(() => toast.success("House deleted"))
				.catch(e => {
					toast.error(e.message);
				});
		});
		const updatedHouses = stateProject.houses.filter(
			(house: any) => !houseIdsToDelete.includes(house.id)
		);
		if (houseIdsToDelete && houseIdsToDelete.length !== 0) {
			setStateProject({
				...stateProject,
				houses: updatedHouses,
			});
			setHouseData(updatedHouses);
		}
	}, [houseIdsToDelete]);

	const fillProjectState = () => {
		if (project && project.houses) {
			project.houses = mapHousePropertiesToHouses(project.houses);
			setStateProject({
				...stateProject,
				houses: project.houses,
				currentView: {
					...stateProject.currentView,
					name: project.currentView.name,
					isHome: project.currentView.isHome,
					aerialViewImage: project.currentView.aerialViewImage,
				},
				name: project.name,
			});
			setHouseData(project.houses);
		}
	};

	useEffect(() => {
		fillProjectState();
	}, []);

	useEffect(() => {
		fillProjectState();
	}, [project]);

	useEffect(() => {
		if (houseIdsToDelete.length > 0) {
			setHouseIdsToDelete([]);
		}
	}, [houseData]);

	useEffect(() => {
		if (stateProject.id !== projectGlobal.id) {
			setStateProject(projectGlobal);
		}
	}, [stateProject]);

	return (
		<div style={{ position: "relative", padding: "50px 70px", width: "100%" }}>
			<ProjectToggleWrapper>
				<ProjectToggle
					isProjectView={isProjectView}
					setIsProjectView={setProjectView}
				/>
			</ProjectToggleWrapper>
			{isProjectView ? (
				stateProject && (
					<>
						<HouseViewWrapper>
							<div>
								<h2>Project name: {stateProject.name}</h2>
							</div>
							<input
								ref={hiddenFileInput}
								style={{ display: "none" }}
								type='file'
								onChange={async (event: any) => {
									const url = uuidv4();
									await saveImage(url, event.target.files[0]);
									const newImageUrl = await loadImageDownloadUrl(url);
									if (newImageUrl) {
										setStateProject({
											...stateProject,
											currentView: {
												...stateProject.currentView,
												aerialViewImage: newImageUrl,
											},
										});
										setFile(event.target.files[0]);
										project.currentView.aerialViewImage = newImageUrl;
									}
								}}
							/>
						</HouseViewWrapper>
						<HouseTable
							data={houseData}
							setData={setHouseData}
							additionalColumns={additionalColumns}
							setAdditionalColumns={setAdditionalColumns}
							paginationEnabled
							searchable
							sortable
							editable
							onDelete={(ids: string[]) => {
								setHouseIdsToDelete(ids);
							}}
						/>
						{buttons ? (
							<div
								style={{
									display: "flex",
									justifyContent: "end",
								}}
							>
								<BackButton
									onClick={async () => {
										await handleSave({
											...stateProject,
											currentView: { ...stateProject.currentView, houses: houseData },
										});
									}}
								>
									Save
								</BackButton>
							</div>
						) : (
							<TableButtonNext
								onClick={() => {
									handleSave({
										...stateProject,
										currentView: { ...stateProject.currentView, houses: houseData },
									});
								}}
							>
								Next
								<AiIcons.AiOutlineArrowRight />
							</TableButtonNext>
						)}
					</>
				)
			) : (
				<div style={{ marginTop: 100 }}>
					<TypeTable />
				</div>
			)}
		</div>
	);
};

export default ProjectTable;
