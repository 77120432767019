import React from "react";
import { useSelector } from "react-redux";
import NavBar from "../NavBar";
import LoggedUserHeader from "./LoggedUserHeader";

const Header = () => {
	const { user } = useSelector((state: any) => state.userReducer);
	const { isFlowStarted } = useSelector(
		(state: any) => state.newUserFlowReducer
	);

	return <>{!isFlowStarted && (user ? <LoggedUserHeader /> : <NavBar />)}</>;
};

export default Header;
