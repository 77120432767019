import NewProject from "../../new-project/NewProject";
import React, { useEffect, useState } from "react";
import {
	mapHousesToHouseProperties,
	prefillWithDefaultValuesForHouseProperties,
} from "../../../utils/HouseUtils";
import ProjectService from "../../../../services/ProjectService";
import { useHistory } from "react-router";

const NewProjectStep = (props: any) => {
	const [imageUrl, setImageUrl] = useState<string>("");
	const history = useHistory();

	useEffect(() => {
		if (props.projectData) {
			props.setProjectData({
				...props.projectData,
				project: { ...props.projectData.project, aerialViewImage: imageUrl },
			});
		}
	}, [imageUrl]);

	return (
		<>
			<NewProject
				onBack={props.previousStep}
				onSubmit={async (projectName, numberOfHouses) => {
					const houses = props.projectData.houses;
					const mappedHouses = mapHousesToHouseProperties(houses);
					const mappedHousesWithPrefilledHouseProperties =
						prefillWithDefaultValuesForHouseProperties(mappedHouses);

					const projectDataToCreate: any = {
						name: projectName,
						aerialViewImage: imageUrl || "",
						houses: mappedHousesWithPrefilledHouseProperties,
					};
					const newPrjct = await ProjectService.createProject(
						projectDataToCreate,
						numberOfHouses
					);
					const prjctId = newPrjct.data.id;
					history.push(`/projects/${prjctId}`);
				}}
				setImageUrl={setImageUrl}
			/>
		</>
	);
};

export default NewProjectStep;
