import React, { ReactNode } from "react";
import { SaveButton } from "../../../../../platform/pages/publish/PublishElements";
import { ProjectState } from "../../../../../types/Project";
import { ViewBoxScale } from "../Canvas";
import {
	INITIAL_VIEW_BOX_X_VALUE,
	INITIAL_VIEW_BOX_Y_VALUE,
} from "../../../constants/constants";
import { ExportSvg } from "../../toolbox/ExportSvg";
import { renderToStaticMarkup } from "react-dom/server";
import SvgService from "../../../../../services/SvgService";
import { toast } from "react-toastify";

type Props = {
	project: ProjectState;
	aerialViewImage?: string;
	children: ReactNode;
};

const SaveSvgButton = ({ project, aerialViewImage, children }: Props) => {
	const handleExport = () => {
		if (aerialViewImage) {
			fetch(aerialViewImage)
				.then(res => res.blob()) // Gets the response and returns it as a blob
				.then(() => {
					const img = new Image();
					img.src = aerialViewImage;

					let viewBoxScale: ViewBoxScale = {
						point1: { x: 0, y: 0 },
						point2: { x: INITIAL_VIEW_BOX_X_VALUE, y: INITIAL_VIEW_BOX_Y_VALUE },
					};

					img.onload = () => {
						const y = (viewBoxScale.point2.x / img.width) * img.height;
						viewBoxScale = {
							point1: { x: 0, y: 0 },
							point2: { x: INITIAL_VIEW_BOX_X_VALUE, y: y },
						};

						const exportSvg = (
							<ExportSvg
								houses={project.houses}
								aerialView={aerialViewImage}
								viewBoxScale={viewBoxScale}
							/>
						);
						let html = renderToStaticMarkup(exportSvg);
						html = html.replaceAll("></polygon>", ' class="polygonStyle"></polygon>');
						const formatter = require("html-formatter");
						html = formatter.render(html);

						SvgService.saveSvg(html, project.currentView.id).then(() =>
							toast.success("SVG uploaded")
						);
					};
				});
		}
	};

	return <SaveButton onClick={handleExport}>{children}</SaveButton>;
};

export default React.memo(SaveSvgButton);
