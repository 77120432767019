import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectService from "../../../services/ProjectService";
import { useParams } from "react-router-dom";
import { useReduxFetch } from "../../utils/hooks/useReduxFetch";
import { projectTableColumns } from "../../components/Table/house-table/HouseTable";
import { ProjectState } from "../../../types/Project";
import { CustomColumnType } from "../../components/Table/type-table/TypeTable";
import Tool from "../../../tool/components/Tool";
import { mapHousePropertiesToHouses } from "../../utils/HouseUtils";
import ProjectTable from "../../components/Table/ProjectTable";
import UserService from "../../../services/UserService";
import { TOOL_VIEW_SET } from "../../redux/actions/Actions";
import { toast } from "react-toastify";
import cloneDeep from "clone-deep";
import { ToolboxContext } from "../../../tool/components/context/ToolboxContext";
import { AdditionalProperty, View } from "../../../types/View";
import { getProject, getTool, getUser } from "../../redux/Selectors";

const Project = (props: { data?: any }) => {
	const dispatch = useDispatch();
	const { user } = useSelector(getUser);
	const { view: tool } = useSelector(getTool);
	const { id } = useParams<{ id: string }>();
	const token: string = localStorage.getItem("AccessToken") || "";
	const isDrawingPage: boolean = window.location.href.includes("draw");
	const { project }: { project: ProjectState } = useSelector(getProject);
	const [stateProject, setStateProject] = useState<ProjectState>();
	const [additionalColumns, setAdditionalColumns] = useState<CustomColumnType[]>(
		[]
	);
	const [history, setHistory] = useState<any>([]);
	const [undoHistory, setUndoHistory] = useState<any>([]);
	const { isDrawingButtonClicked } = useContext(ToolboxContext);

	useReduxFetch(user, UserService.getUser());

	useEffect(() => {
		if (isDrawingPage) {
			if (performance.navigation.type === 1) {
				dispatch({ type: TOOL_VIEW_SET, payload: true });
			}
		}
	}, [isDrawingPage]);

	useEffect(() => {
		if (token && isDrawingPage && id) {
			dispatch(ProjectService.getProjectByPrimaryId(id, token));
		} else {
			dispatch(ProjectService.getProject(id));
		}
	}, []);

	useEffect(() => {
		if (props.data) {
			setAdditionalColumns(props.data.additionalProperties);
		} else if (project) {
			fillStateProject();
		}
	}, []);

	useEffect(() => {
		if (!tool) {
			setHistory([]);
			setUndoHistory([]);
		}
	}, [tool]);

	useEffect(() => {
		if (project) {
			fillStateProject();
		}
	}, [project]);

	useEffect(() => {
		if (stateProject && stateProject?.id !== project.id) {
			setStateProject(project);
		}
	}, [stateProject]);

	const fillStateProject = () => {
		setStateProject({
			...project,
			houses: mapHousePropertiesToHouses(project.houses).sort(
				(a: any, b: any) => a.customId - b.customId
			),
		});
		if (project.currentView.additionalProperties) {
			setAdditionalColumns(
				mapAdditionalPropertiesToColumns(project.currentView.additionalProperties)
			);
		}
	};

	// undo
	function handleUndo() {
		if (tool && stateProject && !isDrawingButtonClicked) {
			if (history.length > 1) {
				const cloneHistory = cloneDeep(history);
				const cloneUndoHistory = cloneDeep(undoHistory);

				const lastAction = cloneHistory.pop();
				cloneUndoHistory.push(lastAction);

				const index = cloneHistory.length - 1;

				setHistory(cloneHistory);
				setUndoHistory(cloneUndoHistory);

				ProjectService.updateProject(project.id, cloneHistory[index]).then(resp => {
					const housesFromResponse = mapHousePropertiesToHouses(
						resp.data.houses
					).sort((a: any, b: any) => a.customId - b.customId);

					const updatedCurrentView = resp.data.views.find(
						(view: any) => view.id === stateProject.currentView.id
					);

					setStateProject({
						...resp.data,
						currentView: {
							...updatedCurrentView,
							houses: housesFromResponse,
							viewProperties: updatedCurrentView.viewProperties,
						},
					});
				});
			}
		}
	}

	// redo
	function handleRedo() {
		if (tool && stateProject && !isDrawingButtonClicked) {
			if (undoHistory.length > 0) {
				const cloneHistory = cloneDeep(history);
				const cloneUndoHistory = cloneDeep(undoHistory);

				const lastAction = cloneUndoHistory.pop();
				cloneHistory.push(lastAction);

				const index = cloneHistory.length - 1;

				setHistory(cloneHistory);
				setUndoHistory(cloneUndoHistory);

				ProjectService.updateProject(project.id, cloneHistory[index]).then(resp => {
					const housesFromResponse = mapHousePropertiesToHouses(
						resp.data.houses
					).sort((a: any, b: any) => a.customId - b.customId);

					const updatedCurrentView = resp.data.views.find(
						(view: any) => view.id === stateProject.currentView.id
					);

					setStateProject({
						...resp.data,
						currentView: {
							...updatedCurrentView,
							houses: housesFromResponse,
							viewProperties: updatedCurrentView.viewProperties,
						},
					});
				});
			}
		}
	}

	const mapAdditionalPropertiesToColumns = (
		additionalProperties: AdditionalProperty[]
	): CustomColumnType[] => {
		if (additionalProperties) {
			return additionalProperties.map(property => {
				return {
					accessor: property.name,
					Header: property.name,
					type: property.columnType,
					values: property.values,
				};
			});
		}
		return [];
	};

	const renderProjectTable = () => {
		document.body.style.background = "#EEEEEE";
		if (stateProject) {
			if (tool) {
				return (
					<Tool
						handleRedo={handleRedo}
						handleUndo={handleUndo}
						isDrawingPage={isDrawingPage}
						project={stateProject}
						updateProjectData={(projectFromTool: ProjectState) => {
							additionalColumns.forEach(column => {
								if (
									!projectTableColumns.find(
										baseColumn => baseColumn.accessor === column.accessor
									) &&
									column.accessor !== "type"
								) {
									if (stateProject.currentView.additionalProperties) {
										if (!stateProject.currentView.additionalProperties) {
											project.currentView.additionalProperties = [];
										}
										if (
											!stateProject.currentView.additionalProperties.find(
												property => property.name === column.accessor
											) &&
											column.accessor !== "additionalProperties"
										) {
											stateProject.currentView.additionalProperties.push({
												name: column.accessor,
												columnType: column ? column.type : "-1",
												values: column && column.values,
											});
										} else {
											console.log("Already exist");
										}
									}
								}
							});
							projectFromTool.houses.forEach((house: any) => {
								for (const [key, value] of Object.entries(house)) {
									if (
										key !== "id" &&
										key !== "type" &&
										key !== "houseProperties" &&
										key !== "editorSettings" &&
										key !== "imagesMap" &&
										key !== "polygons" &&
										key !== "createdAt" &&
										key !== "updatedAt"
									) {
										if (!house.houseProperties) {
											house.houseProperties = {};
										}
										house.houseProperties[key] = value;

										delete house[key];
									}
								}
							});
							if (projectFromTool.currentView.aerialViewImage === "") {
								projectFromTool.currentView.aerialViewImage =
									stateProject.currentView.aerialViewImage;
							}
							ProjectService.updateProject(project.id, projectFromTool)
								.then(resp => {
									const housesFromResponse = mapHousePropertiesToHouses(
										resp.data.houses
									).sort((a: any, b: any) => a.customId - b.customId);
									setStateProject({
										...resp.data,
										currentView: {
											...resp.data.views.filter(
												(view: View) => view.id === stateProject?.currentView.id
											)[0],
										},
										houses: housesFromResponse,
									});
								})
								.then(() => {
									setHistory((prev: any) => {
										return [...prev, projectFromTool];
									});
								});
						}}
						publishButtons
						setToolView={() => {
							dispatch({ type: TOOL_VIEW_SET, payload: false });
						}}
						tableButton
					/>
				);
			} else {
				return (
					<div style={{ display: "flex" }}>
						<ProjectTable
							project={stateProject}
							additionalColumns={additionalColumns}
							setAdditionalColumns={setAdditionalColumns}
							handleSave={handleSave}
							buttons
						/>
					</div>
				);
			}
		}
	};
	const handleSave = (editedProject: ProjectState) => {
		additionalColumns.forEach(column => {
			if (
				!projectTableColumns.find(
					baseColumn => baseColumn.accessor === column.accessor
				) &&
				column.accessor !== "type"
			) {
				if (!editedProject.currentView.additionalProperties) {
					editedProject.currentView.additionalProperties = [];
				}
				if (
					!editedProject.currentView.additionalProperties.find(
						property => property.name === column.accessor
					) &&
					column.accessor !== "additionalProperties"
				) {
					editedProject.currentView.additionalProperties.push({
						name: column.accessor,
						columnType: column ? column.type : "-1",
						values: column && column.values,
					});
				} else {
					console.log("Already exist");
				}
			}
		});
		editedProject.houses.forEach((house: any) => {
			for (const [key, value] of Object.entries(house)) {
				if (
					key !== "id" &&
					key !== "type" &&
					key !== "houseProperties" &&
					key !== "editorSettings" &&
					key !== "imagesMap" &&
					key !== "polygons" &&
					key !== "createdAt" &&
					key !== "updatedAt"
				) {
					if (!house.houseProperties) {
						house.houseProperties = {};
					}
					house.houseProperties[key] = value;

					delete house[key];
				}
			}
		});
		ProjectService.updateProject(project.id, editedProject)
			.then(resp => {
				setStateProject(resp.data);
				if (resp.data.additionalProperties) {
					setAdditionalColumns(
						mapAdditionalPropertiesToColumns(resp.data.additionalProperties)
					);
				}
			})
			.catch(e => {
				toast.error(e.message);
			});
	};

	return <>{renderProjectTable()}</>;
};

export default Project;
