import { AnyAction } from "redux";
import { ADD_VIEW, GET_PROJECT, SWITCH_VIEW } from "../actions/Actions";
import { ProjectState } from "../../../types/Project";

type State = {
	project: ProjectState | null;
};

const initialState: State = {
	project: null,
};

export const projectReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case GET_PROJECT:
			return {
				...state,
				project: action.payload,
			};
		case ADD_VIEW:
			return {
				...state,
				project: {
					...action.payload,
					currentView: {
						...state.project?.currentView,
					},
				},
			};
		case SWITCH_VIEW:
			return {
				...state,
				project: {
					...state.project,
					currentView: {
						...action.payload,
					},
				},
			};
		default:
			return { ...state };
	}
};
