import JSZip from "jszip";
import { ViewToExport } from "../components/PublishButton";

export const exportZip = (views: ViewToExport[], projectName?: string) => {
	const zip = new JSZip();
	views.forEach(view => {
		zip.file(view.svgName, view.html);
		zip.file(view.imageName, view.imageBlob);
	});
	zip.generateAsync({ type: "base64" }).then(function (content) {
		const link = document.createElement("a");
		link.href = "data:application/zip;base64," + content;
		link.download = projectName ? projectName + ".zip" : "export.zip";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	});
};
