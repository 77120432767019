import styled from "styled-components";

import pencilMenuIcon from "../../../assets/images/icons/tool/tabs/pencilMenu.svg";
import arrow from "../../../assets/images/icons/tool/tabs/down-arrow.svg";
import remove from "../../../assets/images/icons/tool/tabs/delete.svg";

const MenuIcon = styled.img`
	width: 12px;
	cursor: pointer;
`;

const SVGIcon = styled.svg.attrs({
	version: "1.1",
	xmlns: "http://www.w3.org/2000/svg",
	xmlnsXlink: "http://www.w3.org/2000/svg",
})``;

export const IsHomeSvg = styled(SVGIcon)<{ isHome: boolean }>`
	width: 11px;
	height: 10px;
	opacity: ${({ isHome }) => (isHome ? 1 : 0)};
`;

export const PencilMenuImg = styled(MenuIcon).attrs({
	src: pencilMenuIcon,
})``;

export const DeleteButton = styled(MenuIcon).attrs({
	src: remove,
})``;

export const Bar = styled.div`
	width: 228px;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 98;
	background-color: white;
	border-radius: 10px;
`;

export const BarHead = styled.div`
	width: 100%;
	display: flex;
`;

export const Arrow = styled.img.attrs({
	src: arrow,
})<{ isCollapsed: boolean }>`
	cursor: pointer;
	width: 15px;
	margin-right: 20px;
	transform: ${({ isCollapsed }) =>
		isCollapsed ? "rotate(180deg)" : "rotate(0deg)"};
	transition: all 200ms;

	position: absolute;

	right: 0;
	top: 23px;
`;

export const Menu = styled.div`
	height: 100%;
	display: flex;
	gap: 17px;
	margin-right: 40px;
	flex-grow: 2;
	justify-content: right;

	opacity: 0;
`;

export const Tab = styled.div`
	font-family: Montserrat, serif;
	color: black;
	width: 100%;
	height: 53px;
	display: flex;
	align-items: center;
	cursor: pointer;

	padding-left: 16px;

	&:hover {
		text-decoration: underline;
	}

	&:hover ${IsHomeSvg} {
		opacity: 1;
	}

	&:hover ${Menu} {
		opacity: 1;
	}
`;

export const AddView = styled(Tab)`
	color: #007bff;
	margin-left: 7px;
	font-weight: bold;
`;

export const ViewInput = styled.input`
	font-family: Montserrat, serif;
	font-size: 16px;
	line-height: 14px;
	font-weight: bolder;

	height: 50%;
	width: 45%;
	text-align: left;
	border-radius: 5px;
	margin-left: 17px;
	border-color: ${({ disabled }) => (disabled ? "white" : "black")};
	border-top-style: ${({ disabled }) => (disabled ? "hidden" : "solid")};
	border-right-style: ${({ disabled }) => (disabled ? "hidden" : "solid")};
	border-left-style: ${({ disabled }) => (disabled ? "hidden" : "solid")};
	border-bottom-style: ${({ disabled }) => (disabled ? "hidden" : "solid")};
	background-color: white;

	&:disabled {
		cursor: pointer;
		color: black;
	}
`;
