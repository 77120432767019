import { combineReducers } from "redux";
import { newUserFlowReducer } from "./NewUserFlowReducer";
import { fetchReducer } from "./FetchReducer";
import { userReducer } from "./UserReducer";
import { projectReducer } from "./ProjectReducer";
import { validationReducer } from "./ValidationReducer";
import { toolReducer } from "./Toolreducer";

export default combineReducers({
	newUserFlowReducer,
	fetchReducer,
	userReducer,
	projectReducer,
	validationReducer,
	toolReducer,
});
