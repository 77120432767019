import axios from "axios";
import { Dispatch } from "redux";
import {
	fetchStarted,
	fetchSuccessful,
} from "../platform/redux/actions/FetchActions";
import {
	getUserAction,
	logoutUserAction,
} from "../platform/redux/actions/UserActions";
import { User } from "../types/User";
import { USER_URL } from "../platform/utils/constants/UrlConstants";
import { authHeader } from "./AuthHeader";

const getUser = () => (dispatch: Dispatch) => {
	dispatch(fetchStarted());
	axios
		.get(USER_URL + "info", { headers: authHeader() })
		.then(resp => resp.data)
		.then((resp: User) => {
			dispatch(fetchSuccessful());
			dispatch(getUserAction(resp));
		})
		.catch(() => {
			dispatch(logoutUserAction());
			dispatch(fetchSuccessful());
		});
};

export default {
	getUser,
};
