import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	margin: 0 auto;
`;

export const Title = styled.h1`
	font-size: 34px;
	padding-bottom: 30px;
`;

export const ProjectListWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	margin-top: 50px;
`;

export const Header = styled.div`
	margin-top: 50px;
	display: flex;
	justify-content: space-between;

	input {
		width: 315px;
		height: 54px;
		border: none;
		padding: 0 40px;
		font-size: 16px;
		border: 1px solid transparent;
		transition: all 0.23s;

		&:hover,
		&:focus-visible {
			outline: none;
			border: 1px solid gray;
		}

		&::placeholder {
			color: #a7a7a7;
		}
	}

	div {
		position: relative;

		.search {
			position: absolute;
			top: 25%;
			width: 27px !important;
			height: 31px !important;
			left: 8px;
			transform: translate(0, -20%);
		}
	}
`;
