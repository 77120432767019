import styled from "styled-components";

export const PublishSVG = styled.button`
	width: 132px;
	height: 56px;
	padding: 20px;
	margin-right: 20px;
	margin-left: 20px;
	background-color: #007bff;
	padding: 2px;
	color: #fff;
	border: none;
	cursor: pointer;
	margin-right: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	border-radius: 5px;

	&:hover {
		color: #000;
		background-color: #fff;
	}
`;

export const SaveButton = styled.button`
	width: 224px;
	height: 55px;
	position: fixed;
	background: #3392ff;
	color: #ffffff;
	font-size: 16px;
	transition: all 0.23s;
	right: 134px;
	bottom: 38px;
	cursor: pointer;
	border: none;

	&:hover {
		background: #0064d6;
	}
`;

export const PublishButtons = styled.div`
	bottom: 30px;
	right: 60px;
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-end;
`;

export const PublishIframe = styled.button`
	width: 132px;
	height: 56px;
	padding: 10px;
	background-color: #007bff;
	padding: 2px;
	color: #fff;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	border-radius: 5px;

	&:hover {
		color: #000;
		background-color: #fff;
	}
`;
