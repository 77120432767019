import React, { useContext } from "react";
import { View } from "../../../../types/View";
import { MenuWrapper } from "./styles";
import cloneDeep from "clone-deep";
import { HouseContext } from "../../context/HouseContext";
import { ProjectState } from "../../../../types/Project";
import { House } from "../../../../types/House";

export type ViewChangeProps = {
	views: View[];
	disableContextMenu: (e: any) => void;
	enableWheel: boolean;
	setContextMenu: (contextMenu: boolean) => void;
	project: ProjectState;
	updateProjectData: (data: ProjectState) => void;
};

const ViewChange = ({
	views,
	disableContextMenu,
	enableWheel,
	setContextMenu,
	project,
	updateProjectData,
}: ViewChangeProps) => {
	const { house } = useContext(HouseContext);

	const getViewById = (viewId: string): View => {
		return project.views.filter((view: View) => view.id === viewId)[0];
	};

	const changeView = (viewId: string) => {
		if (house) {
			const viewToUpdate: View = getViewById(viewId);
			if (!viewToUpdate.houses) {
				viewToUpdate.houses = [];
			}

			viewToUpdate.houses.push(house);

			const clonedProject = cloneDeep(project);
			const viewIndexToRemove = project.views.findIndex(
				(view: View) => view.id === viewId
			);
			clonedProject.views.splice(viewIndexToRemove, 1);
			clonedProject.views.push(viewToUpdate);

			updateProjectData(clonedProject);
			setContextMenu(false);
		}
	};

	const isHouseAssignedToView = (viewId: string): boolean => {
		const view = getViewById(viewId);
		if (view.houses) {
			return view.houses.findIndex((item: House) => item.id === house?.id) !== -1;
		}
		return false;
	};

	return (
		<MenuWrapper
			onContextMenu={e => disableContextMenu(e)}
			id='views-menu'
			style={{ pointerEvents: enableWheel ? "none" : "auto" }}
		>
			{views.map((v: View, index: number) => {
				return (
					<p
						key={index}
						id='viewMenuItem'
						onClick={() => changeView(v.id)}
						style={isHouseAssignedToView(v.id) ? { backgroundColor: "#508ef2" } : {}}
					>
						{v.name}
					</p>
				);
			})}
		</MenuWrapper>
	);
};

export default ViewChange;
