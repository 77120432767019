import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import ProjectService from "services/ProjectService";
import { PROJECT_DRAW_PATH } from "../utils/constants/PathConstants";
import { TOOL_VIEW_SET } from "../redux/actions/Actions";

type Props = {
	projectId: string;
};

export const DrawingPage = ({ projectId }: Props) => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const token = localStorage.getItem("AccessToken");

	useEffect(() => {
		if (token && id) {
			dispatch(ProjectService.getProjectByPrimaryId(id, token));
		}
	}, []);

	dispatch({ type: TOOL_VIEW_SET, payload: true });
	return <Redirect to={PROJECT_DRAW_PATH + projectId} />;
};
