import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { HouseContext } from "tool/components/context/HouseContext";
import {
	AppMode,
	AppModeContext,
} from "tool/components/context/AppModeContext";
import penCursor from "assets/tool/penCursor.png";
import { ToolboxContext } from "tool/components/context/ToolboxContext";
import { Point, PolygonType, Segment } from "types/PolygonType";
import { House } from "types/House";
import { GuideLineType } from "types/GuideLineType";
import { DEFAULT_EDITOR_SETTINGS, EditorSettings } from "types/EditorSettings";
import { ProjectState } from "types/Project";
import { View } from "types/View";
import { getFillColor, getFillOpacity } from "../house-panel/HousesApi";
import PolygonContextMenu from "./PolygonContextMenu";
import GuideLine from "./components/GuideLine";
import { convertScreenToSVGCoordinates } from "./utils/MapBuildUtils";
import { Polygon } from "./components/Polygon";

export type ViewBoxScale = {
	point1: Point;
	point2: Point;
};

export function Canvas(props: {
	aerialView: string;
	onMouseMove: (
		target: any,
		x: number,
		y: number,
		clientX: number,
		clientY: number,
		ctrl: boolean,
		alt: boolean,
		shift: boolean
	) => void;
	onClickTargetXY: (
		target: any,
		x: number,
		y: number,
		ctrl: boolean,
		alt: boolean,
		shift: boolean
	) => void;
	onMouseDownTargetXY: (
		target: any,
		x: number,
		y: number,
		ctrl: boolean,
		alt: boolean,
		shift: boolean
	) => void;
	onMouseUpTargetXY: (
		target: any,
		x: number,
		y: number,
		ctrl: boolean,
		alt: boolean,
		shift: boolean
	) => void;
	onMouseLeave: () => void;
	newPath: any;
	newGuideLine: Point[] | null;
	houses: House[];
	views: View[];
	canvasMousePosition: Point | null;
	isDrawingPath: boolean;
	polygonToEditId?: string | null;
	guideLines: GuideLineType[] | null;
	orthoGuideLines: GuideLineType[] | null;
	isShiftHold: boolean;
	onRightClick: (e: any, x: number, y: number) => void;
	viewBoxScale: ViewBoxScale;
	handleKeyboardMove: (shift: { x: number; y: number }) => void;
	savePolygon: () => void;
	selectedGuideLineId: string | undefined | null;
	hoveredSegment: Segment | null;
	projectEditorSettings: EditorSettings;
	savedPolygons?: PolygonType[];
	updateProjectData?: any;
	project?: ProjectState;
	setSelectedPlot?: (id: string | null) => void;
	selectedPlot?: string | null;
	offsetTop: number;
	setOffsetTop: (value: number) => void;
}) {
	const {
		aerialView,
		guideLines,
		orthoGuideLines,
		isShiftHold,
		newGuideLine,
		houses,
		views,
		newPath,
		isDrawingPath,
		canvasMousePosition,
		onClickTargetXY,
		onMouseDownTargetXY,
		onMouseUpTargetXY,
		onMouseLeave,
		onMouseMove,
		onRightClick,
		viewBoxScale,
		handleKeyboardMove,
		savePolygon,
		selectedGuideLineId,
		hoveredSegment,
		projectEditorSettings,
		savedPolygons,
		updateProjectData,
		project,
		setSelectedPlot,
		selectedPlot,
		offsetTop,
		setOffsetTop,
	} = props;

	const svg = useRef<any>(null);
	const [svgPoint, setSvgPoint] = useState<any>(null);
	const [scrollY, setScrollY] = useState(0);
	const currentScale = useRef(1);
	const translateX = useRef(0);
	const translateY = useRef(0);
	const [enableWheel, setEnableWheel] = useState(false);
	const [allowPan, setAllowPan] = useState(false);
	const [isCanvasInited, setIsCanvasInited] = useState(false);
	const [isTransformDisabled, setIsTransformDisabled] = useState(false);
	const [contextMenu, setContextMenu] = useState<boolean>(false);

	const { house, polygonId } = useContext(HouseContext);
	const { appMode } = useContext(AppModeContext);
	const {
		isEditButtonClicked,
		isMovingPolygon,
		isMovingGuideLine,
		imageShown,
		guideLinesShown,
		guideLinesOnTop,
		isResetTransform,
		setIsResetTransform,
		setCurrentScale,
		isDrawingButtonClicked,
		isDrawGuideLineButtonClicked,
		isContextMenuButtonClicked,
		setIsContextMenuButtonClicked,
	} = useContext(ToolboxContext);

	const [contextMenuPosition, setContextMenuPosition] = useState<any>({
		x: -300,
		y: -300,
	});

	useEffect(() => {
		if (isContextMenuButtonClicked && polygonId != null) {
			document
				.getElementById(polygonId + "-houseId")
				?.setAttribute("stroke", "black");
		}
		return () =>
			document.getElementById(polygonId + "-houseId")?.removeAttribute("stroke");
	}, [isContextMenuButtonClicked, polygonId]);

	useEffect(() => {
		svg.current && setSvgPoint(svg.current.createSVGPoint());
	}, [svg]);

	useEffect(() => {
		if (appMode === AppMode.view && isCanvasInited) {
			setIsResetTransform(true);
		} else if (appMode === AppMode.edit && isCanvasInited) {
			setIsTransformDisabled(false);
		}
	}, [appMode, isCanvasInited]);

	useEffect(() => {
		if (appMode === AppMode.view && !isResetTransform) {
			setIsTransformDisabled(true);
		}
	}, [isResetTransform]);

	useEffect(() => {
		if (appMode === AppMode.view) {
			currentScale.current = 1;
			setCurrentScale(1);
			return;
		}
		setCurrentScale(currentScale.current);
	}, [currentScale.current]);

	useEffect(() => {
		if (isDrawingButtonClicked) {
			setContextMenu(false);
			setIsContextMenuButtonClicked(false);
		}
	}, [isDrawingButtonClicked]);

	useEffect(() => {
		if (!contextMenu) {
			setContextMenuPosition({
				x: -300,
				y: -300,
			});
		}
	}, [contextMenu]);

	const updateMousePosition = (
		e: React.MouseEvent<SVGSVGElement, MouseEvent>
	) => {
		if (!e.ctrlKey) e.stopPropagation();
		if (!svgPoint) return;
		const cursorpt = convertScreenToSVGCoordinates(
			e.clientX,
			e.clientY,
			svg.current,
			svgPoint,
			currentScale.current,
			translateX.current,
			translateY.current
		);
		// console.debug("MATRIX::", svg.current.getCTM(), svg.current.getScreenCTM());
		cursorpt &&
			onMouseMove &&
			onMouseMove(
				e.target,
				cursorpt.x,
				cursorpt.y,
				e.clientX,
				e.clientY + scrollY,
				e.ctrlKey,
				e.altKey,
				e.shiftKey
			);
	};

	function handleClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
		if (!e.ctrlKey) {
			e.preventDefault();
			if (!svgPoint) return;
			const cursorpt = convertScreenToSVGCoordinates(
				e.clientX,
				e.clientY,
				svg.current,
				svgPoint,
				currentScale.current,
				translateX.current,
				translateY.current
			);
			cursorpt &&
				onClickTargetXY(
					e.target,
					cursorpt.x,
					cursorpt.y,
					e.ctrlKey,
					e.altKey,
					e.shiftKey
				);
		}
	}

	function handleDoubleClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
		e.preventDefault();
		if (!e.ctrlKey && newPath) {
			savePolygon();
		}
	}

	function handleRightClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
		e.preventDefault();
		if (!e.ctrlKey) {
			const cursorpt = convertScreenToSVGCoordinates(
				e.clientX,
				e.clientY,
				svg.current,
				svgPoint,
				currentScale.current,
				translateX.current,
				translateY.current
			);
			cursorpt && onRightClick(e, cursorpt.x, cursorpt.y);
		}
	}

	function handleMouseDown(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
		if (!e.ctrlKey) {
			e.preventDefault();
			if (e.button === 1) {
				setAllowPan(true);
				return;
			}
			if (!svgPoint) return;
			const cursorpt = convertScreenToSVGCoordinates(
				e.clientX,
				e.clientY,
				svg.current,
				svgPoint,
				currentScale.current,
				translateX.current,
				translateY.current
			);
			cursorpt &&
				onMouseDownTargetXY(
					e.target,
					cursorpt.x,
					cursorpt.y,
					e.ctrlKey,
					e.altKey,
					e.shiftKey
				);
		}
	}

	function handleMouseUp(e: any) {
		if (!e.ctrlKey) {
			e.preventDefault();

			if (e.button === 1) {
				setAllowPan(false);
				return;
			}

			if (!svgPoint) return;

			setContextMenuPosition({
				x: e.clientX,
				y: e.clientY + offsetTop,
			});

			const cursorpt = convertScreenToSVGCoordinates(
				e.clientX,
				e.clientY,
				svg.current,
				svgPoint,
				currentScale.current,
				translateX.current,
				translateY.current
			);

			cursorpt &&
				onMouseUpTargetXY(
					e.target,
					cursorpt.x,
					cursorpt.y,
					e.ctrlKey,
					e.altKey,
					e.shiftKey
				);
		}
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		const { key } = event;
		if (event.ctrlKey && key === "ArrowRight") {
			event.preventDefault();
			handleKeyboardMove({ x: 1, y: 0 });
		} else if (event.ctrlKey && key === "ArrowLeft") {
			event.preventDefault();
			handleKeyboardMove({ x: -1, y: 0 });
		} else if (event.ctrlKey && key === "ArrowUp") {
			event.preventDefault();
			handleKeyboardMove({ x: 0, y: -1 });
		} else if (event.ctrlKey && key === "ArrowDown") {
			event.preventDefault();
			handleKeyboardMove({ x: 0, y: 1 });
		} else if (key === "Enter") {
			savePolygon();
		} else if (event.ctrlKey) {
			setEnableWheel(true);
		} else if (key === "Escape") {
			setContextMenu(false);
			setIsContextMenuButtonClicked(false);
		}
	};

	const handleKeyUp = (event: KeyboardEvent) => {
		if (!event.ctrlKey) {
			setEnableWheel(false);
		}
	};

	const handleWheel = (e: any) => {
		const MIN_SCROLL = 0;
		const MAX_SCROLL = svg.current.clientHeight - window.innerHeight;

		const scroll = offsetTop + e.deltaY;

		if (scroll < MIN_SCROLL) setOffsetTop(MIN_SCROLL);
		else if (scroll > MAX_SCROLL) setOffsetTop(MAX_SCROLL);
		else if (scroll >= MIN_SCROLL && scroll <= MAX_SCROLL) setOffsetTop(scroll);
	};

	const handleScroll = React.useCallback(() => {
		setScrollY(window.scrollY);
	}, []);

	const hideMenuHouses = (e: any) => {
		if (
			e.target.className.baseVal !== "changeHouseId" &&
			e.target.id !== "contextMenuTab" &&
			e.target.id !== "searchBar-house"
		) {
			setContextMenu(false);
			setIsContextMenuButtonClicked(false);
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown, false);
		document.addEventListener("keyup", handleKeyUp, false);
		window.document.addEventListener("click", hideMenuHouses);
		return () => {
			window.document.removeEventListener("click", hideMenuHouses);
			document.removeEventListener("keydown", handleKeyDown);
			document.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	useEffect(() => {
		document.addEventListener("scroll", handleScroll, false);
		return () => document.removeEventListener("scroll", handleScroll, false);
	}, []);

	return (
		<TransformWrapper
			options={{
				minScale: 0.6,
				limitToWrapper: false,
				limitToBounds: false,
			}}
			wheel={{
				step: 200,
				wheelEnabled: enableWheel,
				disabled: isTransformDisabled,
			}}
			pan={{
				disabled: !enableWheel,
			}}
			scalePadding={{
				disabled: true,
			}}
			zoomIn={{
				disabled: isTransformDisabled,
			}}
			zoomOut={{
				disabled: isTransformDisabled,
			}}
			pinch={{
				disabled: isTransformDisabled,
			}}
			doubleClick={{
				disabled: true,
			}}
			// @ts-ignore
			onInit={() => {
				setIsCanvasInited(true);
			}}
		>
			{({
				// @ts-ignore
				scale,
				// @ts-ignore
				positionX,
				// @ts-ignore
				positionY,
				// @ts-ignore
				resetTransform,
				// @ts-ignore
				onInit,
			}) => {
				if (isResetTransform) {
					resetTransform();
				}
				if (isResetTransform && scale === 1) {
					setIsResetTransform(false);
				}
				currentScale.current = scale;
				translateX.current = -positionX;
				translateY.current = -positionY;

				return (
					<>
						{contextMenu && (
							<PolygonContextMenu
								savedPolygons={savedPolygons}
								houses={houses}
								views={views}
								updateProjectData={updateProjectData}
								project={project!}
								contextMenuPosition={contextMenuPosition}
								setContextMenu={setContextMenu}
								setIsContextMenuButtonClicked={setIsContextMenuButtonClicked}
								enableWheel={enableWheel}
							/>
						)}
						<TransformComponent>
							<svg
								style={{
									width: "100vw",
									cursor: isMovingPolygon
										? "move"
										: isDrawingButtonClicked
										? `url(${penCursor}) 0 32, auto`
										: isDrawGuideLineButtonClicked
										? `crosshair`
										: "default",
									position: "relative",
								}}
								viewBox={`${viewBoxScale.point1.x} ${viewBoxScale.point1.y} 
              ${viewBoxScale.point2.x} ${viewBoxScale.point2.y}`}
								xmlns='http://www.w3.org/2000/svg'
								xmlnsXlink='http://www.w3.org/1999/xlink'
								ref={svg}
								onClick={e => handleClick(e)}
								onContextMenu={e => handleRightClick(e)}
								onDoubleClick={e => handleDoubleClick(e)}
								onMouseMove={e => updateMousePosition(e)}
								onMouseLeave={onMouseLeave}
								onMouseDown={e => handleMouseDown(e)}
								onMouseUp={e => handleMouseUp(e)}
								onWheel={e => handleWheel(e)}
							>
								<image
									style={{
										visibility: imageShown ? "visible" : "hidden",
										overflow: "scroll",
										width: "100%",
									}}
									width={viewBoxScale.point2.x}
									height={viewBoxScale.point2.y}
									href={aerialView}
									onDragStart={event => {
										event.preventDefault();
									}}
									id='image'
								/>

								{/* Guidelines */}
								{/* TODO: what is the reason to not just use guideline property instead of separating each property?*/}
								{guideLinesShown &&
									!guideLinesOnTop &&
									guideLines?.map((guideLine: GuideLineType, i: number) => (
										<GuideLine
											key={i}
											guideLine={{
												id: guideLine.id,
												linePoints: {
													point1: guideLine.linePoints.point1,
													point2: guideLine.linePoints.point2,
												},
												polygonPoints: {
													point1: guideLine.polygonPoints?.point1,
													point2: guideLine.polygonPoints?.point2,
													point3: guideLine.polygonPoints?.point3,
													point4: guideLine.polygonPoints?.point4,
												},
												style: guideLine.style,
												intersectionPoints: guideLine.intersectionPoints,
												lineParameters: guideLine.lineParameters,
											}}
											selectedGuideLineId={selectedGuideLineId}
											currentScale={currentScale.current}
											viewBoxScale={viewBoxScale}
											isDrawingGuideline={false}
											isDrawingPath={isDrawingPath}
										/>
									))}
								{guideLinesShown &&
									!guideLinesOnTop &&
									orthoGuideLines?.map((orthoGuideline: GuideLineType, i: number) => (
										<GuideLine
											key={i}
											guideLine={orthoGuideline}
											selectedGuideLineId={selectedGuideLineId}
											currentScale={currentScale.current}
											viewBoxScale={viewBoxScale}
											isDrawingGuideline={false}
											isDrawingPath={isDrawingPath}
										/>
									))}

								{/* Existing polygons*/}
								{project &&
									project.currentView.polygons &&
									project.currentView.polygons.map((polygon: PolygonType, i: number) => {
										const house: House | undefined = project.houses.find(
											(house: House) => house.id === polygon.houseId
										);
										if (house) {
											return (
												<Polygon
													key={i}
													houseId={house.houseProperties.customId}
													polygon={polygon}
													houseCurrent={house}
													fillColor={getFillColor(house, projectEditorSettings)}
													fillOpacity={getFillOpacity(house, projectEditorSettings)}
													strokeOpacity={
														projectEditorSettings
															? projectEditorSettings.strokeOpacity
															: DEFAULT_EDITOR_SETTINGS.strokeOpacity
													}
													cornersRadius={
														projectEditorSettings
															? projectEditorSettings.cornersRadius
															: DEFAULT_EDITOR_SETTINGS.cornersRadius
													}
													isDrawingPath={isDrawingPath}
													currentScale={currentScale.current}
													setMenuHouses={setContextMenu}
													setSelectedPlot={setSelectedPlot}
													selectedPlot={selectedPlot}
													isShiftHold={isShiftHold}
												/>
											);
										}
										return null;
									})}

								{/* Guidelines */}
								{guideLinesShown &&
									guideLinesOnTop &&
									guideLines?.map((guideLine: GuideLineType, i: number) => (
										<GuideLine
											key={i}
											guideLine={{
												id: guideLine.id,
												linePoints: {
													point1: guideLine.linePoints.point1,
													point2: guideLine.linePoints.point2,
												},
												polygonPoints: {
													point1: guideLine.polygonPoints?.point1,
													point2: guideLine.polygonPoints?.point2,
													point3: guideLine.polygonPoints?.point3,
													point4: guideLine.polygonPoints?.point4,
												},
												style: guideLine.style,
												intersectionPoints: guideLine.intersectionPoints,
												lineParameters: guideLine.lineParameters,
											}}
											selectedGuideLineId={selectedGuideLineId}
											currentScale={currentScale.current}
											viewBoxScale={viewBoxScale}
											isDrawingGuideline={false}
											isDrawingPath={isDrawingPath}
										/>
									))}
								{guideLinesShown &&
									guideLinesOnTop &&
									orthoGuideLines?.map((orthoGuideline: GuideLineType, i: number) => (
										<GuideLine
											key={i}
											guideLine={orthoGuideline}
											selectedGuideLineId={selectedGuideLineId}
											currentScale={currentScale.current}
											viewBoxScale={viewBoxScale}
											isDrawingGuideline={false}
											isDrawingPath={isDrawingPath}
										/>
									))}

								{/* New guideline drawing*/}
								{newGuideLine && newGuideLine.length === 2 && (
									<GuideLine
										guideLine={{
											id: "new-line",
											linePoints: {
												point1: newGuideLine[0],
												point2: newGuideLine[1],
											},
											intersectionPoints: [],
										}}
										selectedGuideLineId={selectedGuideLineId}
										currentScale={currentScale.current}
										viewBoxScale={viewBoxScale}
										isDrawingGuideline={true}
										isDrawingPath={isDrawingPath}
									/>
								)}

								{/* Drawing new polygon*/}
								{newPath && house && (
									<Polygon
										polygon={{
											id: "x-polygon",
											points: newPath,
											houseId: house.id,
											viewId: project!.currentView.id,
										}}
										fillColor={getFillColor(house, projectEditorSettings)}
										fillOpacity={getFillOpacity(house, projectEditorSettings)}
										strokeOpacity={
											projectEditorSettings
												? projectEditorSettings.strokeOpacity
												: DEFAULT_EDITOR_SETTINGS.strokeOpacity
										}
										cornersRadius={
											projectEditorSettings
												? projectEditorSettings.cornersRadius
												: DEFAULT_EDITOR_SETTINGS.cornersRadius
										}
										isDrawingPath={isDrawingPath}
										currentScale={currentScale.current}
										selectedPlot={selectedPlot}
										isShiftHold={isShiftHold}
									/>
								)}

								{/* New points on existing polygons*/}
								{hoveredSegment &&
									hoveredSegment.polygon.id === polygonId &&
									isEditButtonClicked &&
									!isMovingGuideLine &&
									!isMovingPolygon && (
										<GraySVGCircle
											pointerEvents='none'
											cx={(hoveredSegment.sides[0].x + hoveredSegment.sides[1].x) / 2}
											cy={(hoveredSegment.sides[0].y + hoveredSegment.sides[1].y) / 2}
											currentScale={currentScale.current}
										/>
									)}

								{/* Snap points*/}
								{canvasMousePosition && (
									<SVGCircle
										pointerEvents='none'
										cx={canvasMousePosition.x}
										cy={canvasMousePosition.y}
										// r={canvasSettings.cornersRadius}
										// fill={canvasSettings.strokeColor}
										currentScale={currentScale.current}
									/>
								)}
							</svg>
						</TransformComponent>
					</>
				);
			}}
		</TransformWrapper>
	);
}

const SVGCircle = styled.circle<{ currentScale: number }>`
	fill: white;
	r: ${props => 2 / props.currentScale}pt;
	stroke: #508ef2;
	stroke-width: ${props => 1 / props.currentScale}pt;
	stroke-dasharray: 0;
	stroke-linejoin: round;
`;

const GraySVGCircle = styled.circle<{ currentScale: number }>`
	fill: white;
	r: ${props => 2 / props.currentScale}pt;
	stroke: gray;
	stroke-width: ${props => 2 / props.currentScale}pt;
	stroke-dasharray: 0;
	stroke-linejoin: round;
`;
