import React, { useState } from "react";
import { Button } from "../../components/buttons/ButtonElements";
import {
	ArrowForward,
	ArrowRight,
	HeroBtnWrapper,
	HeroContainer,
	HeroContent,
	HeroH1,
	HeroP,
} from "./HeroSectionElements";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { NEW_USER_FLOW_PATH } from "../../utils/constants/PathConstants";

const HeroSection = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [hover, setHover] = useState(false);

	const onHover = () => {
		setHover(!hover);
	};

	return (
		<HeroContainer>
			<HeroContent>
				<HeroH1>Map Tool</HeroH1>
				<HeroP>{t("landing.tryAccount")}</HeroP>
				<HeroBtnWrapper>
					<Button
						onClick={() => {
							history.push(NEW_USER_FLOW_PATH);
						}}
						onMouseEnter={onHover}
						onMouseLeave={onHover}
						primary
						dark
					>
						{t("landing.getStarted")}
						{hover ? <ArrowForward /> : <ArrowRight />}
					</Button>
				</HeroBtnWrapper>
			</HeroContent>
		</HeroContainer>
	);
};

export default HeroSection;
