import React from "react";
import ProjectItem from "../../components/Project/ProjectItem";
import { Container, Header, ProjectListWrapper, Title } from "./styles";
import ProjectService from "../../../services/ProjectService";
import { BiSearchAlt2 } from "react-icons/bi";
import { toast } from "react-toastify";
import { Project } from "../../../types/Project";
import { getHomeView } from "../../../tool/components/utils/projectUtils";

type Props = {
	data: Project[];
	onGo: (id: string) => void;
	setData: (project: any) => void;
};

const ProjectList = ({ data, onGo, setData }: Props) => {
	const [searchName, setSearchName] = React.useState("");
	const filteredData = (name?: any) => {
		return data.filter((project: any) => {
			if (name === "" || !name) {
				return data;
			}
			return project.name.toLowerCase().indexOf(name.toLowerCase()) > -1;
		});
	};

	return (
		<Container>
			<Header>
				<Title>Project List</Title>
				<div>
					<BiSearchAlt2 className='search' />
					<input
						name='search-project'
						placeholder='Search project.....'
						onChange={e => setSearchName(e.target.value)}
					/>
				</div>
			</Header>
			<ProjectListWrapper>
				{filteredData(searchName).map((project: Project) => {
					const homeView = getHomeView(project);
					return (
						<ProjectItem
							key={project.id}
							aerialViewImage={homeView.aerialViewImage}
							name={project.name}
							houses={project.houses.length}
							projectId={project.id}
							onGo={onGo}
							onDelete={() =>
								ProjectService.deleteProject(project.id).then(() => {
									setData(data.filter((pr: any) => pr.id !== project.id));
									toast.success(`Project "${project.name}" deleted`);
								})
							}
						/>
					);
				})}
			</ProjectListWrapper>
		</Container>
	);
};

export default ProjectList;
