import React, { useEffect } from "react";
import LoginForm from "./LoginForm";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../../../services/AuthService";
import { useHistory } from "react-router-dom";
import { ACCOUNT_PATH } from "../../../utils/constants/PathConstants";
import { loginUserAction } from "../../../redux/actions/UserActions";
import { toast } from "react-toastify";

const Login = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { user } = useSelector((state: any) => state.userReducer);

	useEffect(() => {
		if (user) {
			history.push(ACCOUNT_PATH);
		}
	}, [user]);

	return (
		<div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
			<LoginForm
				onSubmit={(username: string, password: string) => {
					AuthService.login(username, password)
						.then(resp => {
							const user = resp.data.user;
							const token = resp.data.token;
							localStorage.setItem("token", token);
							dispatch(loginUserAction(user));
						})
						.catch(err =>
							toast.error(err.response.data.message || err.response.data)
						);
				}}
			/>
		</div>
	);
};

export default Login;
