import { Dispatch } from "redux";
import {
	fetchFailed,
	fetchStarted,
	fetchSuccessful,
} from "../platform/redux/actions/FetchActions";
import axios from "axios";
import { Project, ProjectState } from "../types/Project";
import { getProject as getProjectAction } from "../platform/redux/actions/ProjectActions";
import {
	PRIMARY_PROJECT_URL,
	PROJECT_URL,
} from "../platform/utils/constants/UrlConstants";
import { authHeader } from "./AuthHeader";
import { ProjectCreationDto } from "../types/dto/ProjectCreationDto";
import { getHomeView } from "../tool/components/utils/projectUtils";
import jwt from "jsonwebtoken";
import { TOKEN_KEY } from "tool/components/constants/constants";

const getProject = (id: string) => (dispatch: Dispatch) => {
	dispatch(fetchStarted());
	axios
		.get(PROJECT_URL + id, { headers: authHeader() })
		.then(resp => resp.data)
		.then((resp: Project) => {
			dispatch(fetchSuccessful());
			const projectState: ProjectState = {
				...resp,
				currentView: getHomeView(resp),
			};
			dispatch(getProjectAction(projectState));
		})
		.catch(err => dispatch(fetchFailed(err.response.data)));
};

const getProjectByPrimaryId =
	(id?: string, accessToken?: string) => (dispatch: Dispatch) => {
		dispatch(fetchStarted());
		return axios
			.get(PRIMARY_PROJECT_URL + id + "/" + accessToken, { headers: authHeader() })
			.then(resp => resp.data)
			.then((resp: Project) => {
				dispatch(fetchSuccessful());
				const projectState: ProjectState = {
					...resp,
					currentView: getHomeView(resp),
				};
				dispatch(getProjectAction(projectState));
			})
			.catch(err => dispatch(fetchFailed(err.response.data)));
	};

const getProjectPlain = (id: string): Promise<Project> => {
	return axios
		.get(PROJECT_URL + id, { headers: authHeader() })
		.then(resp => resp.data);
};

const getProjectByPrimaryIdPlain = (
	id: string,
	accessToken: string
): Promise<Project> => {
	return axios
		.get(PRIMARY_PROJECT_URL + id + "/" + accessToken, { headers: authHeader() })
		.then(resp => resp.data);
};

const getAllProjectsForUser = (): Promise<Project[]> => {
	return axios
		.get(PROJECT_URL, { headers: authHeader() })
		.then(resp => resp.data);
};

const createProject = (data: ProjectCreationDto, numberOfHouses: number) => {
	return axios.post(
		PROJECT_URL,
		{ data, numberOfHouses },
		{ headers: authHeader() }
	);
};

const updateProject = (projectId?: string, data?: ProjectState) => {
	return axios.put(
		PROJECT_URL,
		{ id: projectId, data },
		{ headers: authHeader() }
	);
};

const createView = (projectId: string) => {
	return axios.put(PROJECT_URL + projectId);
};

const deleteProject = (projectId: string) => {
	return axios.delete(PROJECT_URL + projectId, { headers: authHeader() });
};

const checkForProjectRequest = () => {
	const token = jwt.sign({}, TOKEN_KEY, {
		expiresIn: "2h",
	});

	return axios.get(PROJECT_URL + `request/${token}`);
};

const deleteRequests = () => {
	return axios.post(PROJECT_URL + `request/clear`);
};

export default {
	getProject,
	getProjectByPrimaryId,
	getProjectPlain,
	getProjectByPrimaryIdPlain,
	getAllProjects: getAllProjectsForUser,
	createProject,
	updateProject,
	createView,
	deleteProject,
	checkForProjectRequest,
	deleteRequests,
};
