import React from "react";
import { ViewInput } from "./ViewsBarElements";

const ViewNameInput = (props: {
	disabled: boolean;
	onChange: (e: any) => void;
	value?: string;
}) => {
	const { disabled, onChange, value } = props;

	return <ViewInput value={value} onChange={onChange} disabled={disabled} />;
};

export default ViewNameInput;
