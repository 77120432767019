import axios from "axios";
import { TYPE_URL } from "../platform/utils/constants/UrlConstants";
import { Type } from "../types/Type";
import { authHeader } from "./AuthHeader";

const getAllTypes = (): Promise<Type[]> => {
	return axios.get(TYPE_URL, { headers: authHeader() }).then(resp => resp.data);
};

const createType = (customId: string, label: string, mapProperties: any) => {
	return axios.post(
		TYPE_URL,
		{ customId, label, mapProperties },
		{ headers: authHeader() }
	);
};

const updateType = (
	id: string,
	customId: string,
	label: string,
	mapProperties: any
) => {
	return axios.put(TYPE_URL, { id, customId, label, mapProperties });
};

const updateAllTypesForAccount = (types: Type[]) => {
	return axios.put(TYPE_URL + "/account", { types }, { headers: authHeader() });
};

const deleteType = (id: string) => {
	return axios.delete(TYPE_URL + id);
};

export default {
	getAllTypes,
	createType,
	updateType,
	updateAllTypesForAccount,
	deleteType,
};
