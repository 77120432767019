import axios from "axios";
import { HOUSE_URL } from "../platform/utils/constants/UrlConstants";

const deleteHouse = (houseId: string) => {
	return axios.delete(HOUSE_URL + houseId);
};

export default {
	deleteHouse,
};
