import React from "react";
import { DeleteButton } from "./ViewsBarElements";
import ViewService from "../../../services/ViewService";
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectState } from "../../../types/Project";
import { getHomeView } from "../utils/projectUtils";
import { getProject as getProjectAction } from "../../../platform/redux/actions/ProjectActions";
import { getProject } from "../../../platform/redux/Selectors";

export const DeleteView = (props: { viewId: string; disabled: boolean }) => {
	const dispatch = useDispatch();
	const project: ProjectState = useSelector(getProject);

	const handleDelete = (event: any) => {
		event.stopPropagation();

		if (props.disabled) return;

		if (project.views.length > 1) {
			ViewService.deleteView(props.viewId)
				.then(resp => resp.data)
				.then((res: Project) => {
					const projectState: ProjectState = {
						...res,
						currentView: getHomeView(res),
					};
					dispatch(getProjectAction(projectState));
				});
		}
	};

	return <DeleteButton onClick={handleDelete} />;
};

export default DeleteView;
