import React from "react";
import { CheckBoxCustom } from "./CheckBoxElements";
import { FaCheck } from "react-icons/all";

const CheckBox = (props: {
	checked: boolean;
	setChecked: (checked: boolean) => void;
	disabled?: boolean;
}) => {
	return (
		<CheckBoxCustom
			onClick={() => props.setChecked(!props.checked)}
			active={props.checked}
			disabled={props.disabled}
		>
			{props.checked && (
				<div
					style={{
						width: "14px",
						height: "14px",
						borderRadius: "10px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						zIndex: 2,
						backgroundColor: "white",
					}}
				>
					<FaCheck
						style={{
							fontSize: "9px",
							color: "#06B900",
						}}
					/>
				</div>
			)}
		</CheckBoxCustom>
	);
};

export default CheckBox;
