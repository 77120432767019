import styled from "styled-components";

export const ToolPanelWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
	width: 100%;
	height: 100vh;
`;

export const ToolBox = styled.div`
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	background-color: black;
	overflow: scroll;
`;

export const ToolBoxImage = styled.div<{ toolBoxShown: boolean }>`
	width: 100%;
	padding-left: ${({ toolBoxShown }) => (toolBoxShown ? 0 : 12)}px;
`;
