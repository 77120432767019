import styled from "styled-components";

export const SVGLine = styled.line<{ currentScale: number }>`
	stroke-width: ${props => 0.25 / props.currentScale}pt;
`;

export const SVGPolygon = styled.polygon<{ currentScale: number }>`
	stroke: white;
	stroke-width: ${props => 1 / props.currentScale}pt;
	stroke-opacity: 0;
	fill-opacity: 0;
`;

export const SVGIntersectionCircle = styled.circle<{ currentScale: number }>`
	fill: white;
	fill-opacity: 0;
	stroke: purple;
	stroke-width: ${props => 2 / props.currentScale}pt;
	stroke-opacity: 0;
	r: ${props => 2 / props.currentScale}pt;

	&:hover {
		fill-opacity: 1;
		stroke-opacity: 1;

		r: ${props => 2 / props.currentScale}pt;
	}
`;

export const SVGCircle = styled.circle<{ currentScale: number }>`
	fill: white;
	r: ${props => 1.5 / props.currentScale}pt;
	stroke: red;
	stroke-width: ${props => 1 / props.currentScale}pt;
	stroke-dasharray: 0;
	stroke-linejoin: round;
`;
