import axios from "axios";
import React, { useState } from "react";
import { LOGIN_PATH } from "../../../utils/constants/PathConstants";
import { AUTH_URL } from "../../../utils/constants/UrlConstants";
import { useHistory } from "react-router";
import {
	ChangePasswordButton,
	ResetPassword as ResetPasswordWrapper,
	StyledInput,
	StyledLable,
	WrapperLabelInput,
} from "./styled";
import { toast } from "react-toastify";

const ResetPassword = () => {
	const [password, setPassword] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [token, setToken] = useState<string>("");
	const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const history = useHistory();

	const handlePassword = (e: any) => {
		setPassword(e.target.value);
	};

	const handleEmail = (e: any) => {
		setEmail(e.target.value);
	};

	const handleToken = (e: any) => {
		setToken(e.target.value);
	};

	const sendCode = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		await axios({
			method: "put",
			url: `${AUTH_URL}reset-password`,
			data: { email },
		})
			.then(() => {
				setIsCodeSent(true);
				setLoading(false);
				toast.success("Code sent");
			})
			.catch(() => {
				toast.error(`User with this email doesn't exist`);
				setLoading(false);
			});
	};

	const fetchPassword = async (e: any) => {
		e.preventDefault();
		await axios({
			method: "put",
			url: `${AUTH_URL}new-password`,
			data: {
				email,
				password,
			},
			headers: { Authorization: token },
		})
			.then(() => {
				history.push(LOGIN_PATH);
				toast.success("Password changed.");
			})
			.catch(() => toast.error("Error to change password."));
	};

	if (loading) {
		return <h1>Sending code...</h1>;
	}

	return (
		<ResetPasswordWrapper>
			<h1 style={{ fontSize: 26, marginBottom: 30 }}>Reset password</h1>
			{!isCodeSent ? (
				<WrapperLabelInput>
					<StyledLable style={{ left: -56 }} htmlFor='email'>
						Email
					</StyledLable>
					<StyledInput
						id='email'
						onChange={handleEmail}
						type='email'
						value={email}
					/>
				</WrapperLabelInput>
			) : (
				<WrapperLabelInput>
					<StyledLable style={{ left: -56 }} htmlFor='code'>
						Code
					</StyledLable>
					<StyledInput id='code' onChange={handleToken} type='text' value={token} />
				</WrapperLabelInput>
			)}
			{isCodeSent && (
				<WrapperLabelInput>
					<StyledLable style={{ left: -130 }} htmlFor='new-password'>
						New password
					</StyledLable>
					<StyledInput
						id='new-password'
						onChange={handlePassword}
						type='password'
						value={password}
					/>
				</WrapperLabelInput>
			)}

			<ChangePasswordButton
				onClick={e => {
					isCodeSent ? fetchPassword(e) : sendCode(e);
				}}
			>
				{isCodeSent ? "Reset password" : "Sent reset code"}
			</ChangePasswordButton>
		</ResetPasswordWrapper>
	);
};

export default ResetPassword;
