import {
	FINISH_NEW_USER_FLOW,
	GO_STAGE,
	GO_TO_NEXT_STAGE,
	START_NEW_USER_FLOW,
} from "../actions/Actions";
import { AnyAction } from "redux";

const initialStore = {
	isFlowStarted: false,
	currentStep: 1,
};

export const newUserFlowReducer = (state = initialStore, action: AnyAction) => {
	switch (action.type) {
		case START_NEW_USER_FLOW:
			return {
				...state,
				isFlowStarted: true,
			};
		case GO_TO_NEXT_STAGE:
			return {
				...state,
				currentStep: state.currentStep + 1,
			};
		case GO_STAGE:
			return {
				...state,
				currentStep: action.payload,
			};
		case FINISH_NEW_USER_FLOW:
			return {
				...state,
				isFlowStarted: false,
			};
		default:
			return {
				...state,
			};
	}
};
