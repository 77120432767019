import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { Canvas, ViewBoxScale } from "./Canvas";
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import {
	applyIntersectionPointsToGuideline,
	calculateDistanceBetweenPoints,
	calculateDistanceFromPointToGuideLine,
	calculateGuideLinesIntersection,
	calculateLineNumber,
	calculateLineParametersFromPoints,
	calculateMovingGuidelineVertex,
	calculateProjectionPoint,
	findClosestSegment,
	findGuideLineIntersection,
	findIndexOfClosestPointToSelect,
	findLinePolygonIntersection,
	findMagneticPolygonIntersection,
	GUIDE_LINE_HIDDEN_POLYGON_WIDTH,
	isLineClose,
	pointsAreClose,
} from "./utils/MapBuildUtils";
import { GuideLineTooltip } from "../../tooltips/GuideLineTooltip";
import {
	AUTO_HIDDEN_ALERT_DURATION,
	INITIAL_VIEW_BOX_X_VALUE,
	INITIAL_VIEW_BOX_Y_VALUE,
} from "../../constants/constants";
import { DeletePointTooltip } from "../../tooltips/DeletePointTooltip";
import { HouseContext } from "../../context/HouseContext";
import cloneDeep from "clone-deep";
import { useUndoRedo } from "../../hooks/mapBuildHooks";
import { ToolboxContext } from "../../context/ToolboxContext";
import { MovePolygonTooltip } from "../../tooltips/MovePolygonToolTip";
import { GuideLineType } from "../../../../types/GuideLineType";
import { House } from "../../../../types/House";
import {
	DEFAULT_EDITOR_SETTINGS,
	EditorSettings,
} from "../../../../types/EditorSettings";
import { Point, PolygonType, Segment } from "../../../../types/PolygonType";
import { IFrameClipboardButton } from "../toolbox/IFrameClipboardButton";
import { PublishButtons } from "../../../../platform/pages/publish/PublishElements";
import { ProjectState } from "../../../../types/Project";
import { View } from "../../../../types/View";
import PublishButton from "./components/PublishButton";
import SaveSvgButton from "./components/SaveSvgButton";

export function MapBuild(props: {
	isDrawingPage?: boolean;
	aerialView: string;
	savedPolygons: PolygonType[];
	savePolygons: (polygons: any[]) => void;
	saveGuideLine: (line: GuideLineType, moving?: boolean) => void;
	selectedPlot: string | null;
	setSelectedPlot: (id: string | null) => void;
	isAlertPolygonShown: boolean;
	showPolygonAlert: Dispatch<SetStateAction<boolean>>;
	handleAlertPolygonClose: () => void;
	savedGuideLines: GuideLineType[] | null;
	deleteGuideLine: (line: GuideLineType) => void;
	houses: House[] | null;
	views: View[] | null;
	setIsUndoHistoryPresent: Dispatch<SetStateAction<boolean>>;
	setIsRedoHistoryPresent: Dispatch<SetStateAction<boolean>>;
	projectEditorSettings: EditorSettings | undefined;
	publishButtons?: boolean;
	stepMode?: boolean;
	projectId?: string;
	updateProjectData: any;
	project: ProjectState;
	deletePolygon: any;
	handleUndoPolygon: any;
	handleRedoPolygon: any;
}) {
	const {
		isDrawingPage,
		aerialView,
		savedPolygons,
		saveGuideLine,
		savePolygons,
		selectedPlot,
		setSelectedPlot,
		isAlertPolygonShown,
		showPolygonAlert,
		handleAlertPolygonClose,
		savedGuideLines,
		deleteGuideLine,
		houses,
		views,
		setIsRedoHistoryPresent,
		setIsUndoHistoryPresent,
		projectEditorSettings,
		publishButtons,
		stepMode,
		projectId,
		updateProjectData,
		project,
		deletePolygon,
		handleUndoPolygon,
		handleRedoPolygon,
	} = props;

	const [guideLines, setGuideLines] = useState<GuideLineType[]>([]);
	const [orthoGuideLines, setOrthoGuideLines] = useState<GuideLineType[]>([]);
	const [newGuideLinePoint, setNewGuideLinePoint] = useState<Point[]>([]);
	const [newGuideLinePoints, setNewGuideLinePoints] = useState<Point[]>([]);
	const [isDrawingPath, setDrawingPath] = useState<boolean>(false);
	const [isShiftHold, setIsShiftHold] = useState<boolean>(false);
	const [componentSavedPolygons, setComponentSavedPolygons] = useState<
		PolygonType[]
	>([]);
	const [editedPolygon, setEditedPolygon] = useState<PolygonType>(
		{} as PolygonType
	);

	// polygon to copy
	const [polygonToCopy, setPolygonToCopy] = useState<PolygonType | null>(null);
	// guideline to copy
	const [guidelineToCopy, setGuidelineToCopy] = useState<GuideLineType | null>(
		null
	);

	const [right, setRight] = useState<Point[]>([]);
	const [showCanvasMousePosition, setShowCanvasMousePosition] =
		useState<boolean>(false);
	const [canvasMousePosition, setCurrentCanvasMousePosition] =
		useState<Point | null>(null);
	const [hoveredPlot, setHoveredPlot] = useState<PolygonType | null>(null);
	const [hoveredGuideLine, setHoveredGuideLine] = useState<GuideLineType | null>(
		null
	);
	const [hoveredPoint, setHoveredPoint] = useState<SVGCircleElement | null>(
		null
	);
	const [hoveredSegment, setHoveredSegment] = useState<Segment | null>(null);
	const [viewBoxScale, setViewBoxScale] = useState<ViewBoxScale>({
		point1: { x: 0, y: 0 },
		point2: { x: INITIAL_VIEW_BOX_X_VALUE, y: INITIAL_VIEW_BOX_Y_VALUE },
	});
	const [enterPressed, setEnterPressed] = useState<boolean>(false);
	const [offsetTop, setOffsetTop] = useState<number>(0);
	const { house, setHouse, newPlot, setNewPlot, polygonId, setPolygonId } =
		useContext(HouseContext);
	const {
		isUndoButtonClicked,
		isRedoButtonClicked,
		setIsRedoButtonClicked,
		isDrawGuideLineButtonClicked,
		setIsDrawGuideLineButtonClicked,
		isDrawingButtonClicked,
		setIsDrawingButtonClicked,
		isEditButtonClicked,
		setIsEditButtonClicked,
		isSavePolygonButtonClicked,
		setIsSavePolygonButtonClicked,
		isMovingPolygon,
		setIsMovingPolygon,
		isMovingGuideLine,
		setIsMovingGuideLine,
		isMovingGuideLinePoint,
		setIsMovingGuideLinePoint,
		currentScale,
		setGuideLinesShown,
		guideLinesShown,
		setGuideLinesOnTop,
		guideLinesOnTop,
		imageShown,
		setImageShown,
		isPointerButtonClicked,
		setIsResetTransform,
		isHouseNumbersShown,
		setIsHouseNumbersShown,
	} = useContext(ToolboxContext);

	const popupPosition = useRef<{ top: number; left: number }>({
		top: -99,
		left: -99,
	});
	const maxPlotId = useRef<number>(1);

	const [beforeMovePolygonCenter, setBeforeMovePolygonCenter] =
		useState<Point>();
	const [movingGuideLine, setMovingGuideLine] = useState<GuideLineType>();
	const [beforeMoveGuideLineCenter, setBeforeMoveGuideLineCenter] =
		useState<Point>();
	const [isMovingGuideLinePoint1, setIsMovingGuideLinePoint1] =
		useState<boolean>(false);
	const [isMovingGuideLinePoint2, setIsMovingGuideLinePoint2] =
		useState<boolean>(false);
	const [selectedGuideLineId, setSelectedGuideLineId] = useState<
		string | undefined
	>(undefined);

	const [historyPoints, setHistoryPoints] = useState<any>([]);

	// ref for SvgCircle which is point to edit. (changing stroke color for circle point).
	const selectedVertexRef: any = useRef(null);

	// so that there will always be selected house
	useEffect(() => {
		if (houses) {
			setHouse(houses[0]);
		}
	}, []);

	useEffect(() => {
		window.document.addEventListener("keyup", handleKeyUp);
		window.document.addEventListener("keydown", handleKeyDown);
		return () => {
			window.document.removeEventListener("keyup", handleKeyUp);
			window.document.removeEventListener("keydown", handleKeyDown);
		};
	});

	const { pushUndoHistory, clearHistory, handleUndo, handleRedo } = useUndoRedo({
		setIsRedoHistoryPresent,
		setIsUndoHistoryPresent,
	});

	useEffect(() => {
		if (isUndoButtonClicked) {
			handleUndo(
				isDrawingPath,
				newPlot,
				setNewPlot,
				setDrawingPath,
				componentSavedPolygons,
				setComponentSavedPolygons
			);
		}
		savePolygon();
	}, [isUndoButtonClicked]);

	useEffect(() => {
		if (isRedoButtonClicked) {
			handleRedo(
				isDrawingPath,
				newPlot,
				setNewPlot,
				setDrawingPath,
				componentSavedPolygons,
				setComponentSavedPolygons
			);
			setIsRedoButtonClicked(false);
		}
		savePolygon();
	}, [isRedoButtonClicked]);

	useEffect(() => {
		setDrawingPath(false);
		clearHistory();
		setNewPlot(null);
		setRight([]);
	}, [house]);

	useEffect(() => {
		if (savedGuideLines) {
			initIntersectionsBetweenGuidelines(savedGuideLines);
		}
	}, [savedGuideLines]);

	useEffect(() => {
		const img = new Image();
		img.src = aerialView;
		img.onload = () => {
			const y = (viewBoxScale.point2.x / img.width) * img.height;
			setViewBoxScale({
				point1: { x: 0, y: 0 },
				point2: { x: INITIAL_VIEW_BOX_X_VALUE, y: y },
			});
		};
	}, [aerialView]);

	useEffect(() => {
		setComponentSavedPolygons([...savedPolygons]);
		let maxId = 0;
		savedPolygons.forEach(function (p) {
			if (p.id && p.id.startsWith("polygon")) {
				const numberId = Number(p.id.substring(7));
				if (numberId > maxId) {
					maxId = numberId;
				}
			}
		});
		maxPlotId.current = maxId + 1;
	}, [savedPolygons]);

	useEffect(() => {
		if (selectedPlot) {
			if (selectedPlot.startsWith("polygon")) {
				setPolygonId(selectedPlot);
				setIsEditButtonClicked(true);
			}
		} else {
			setHoveredPlot(null);
			setPolygonId(null);
			setIsEditButtonClicked(false);
		}
	}, [selectedPlot]);

	useEffect(() => {
		if (isDrawingButtonClicked) {
			setEditedPolygon({
				id: "",
				points: [],
				houseId: "",
				viewId: "",
			});
			setDrawingPath(true);
			setIsDrawGuideLineButtonClicked(false);
			setIsEditButtonClicked(false);
			setPolygonId(null);
			setNewPlot(null);
			if (!house) {
				setHouse(project.houses[0]);
			}
			return;
		}
		setDrawingPath(false);
		setHouse(null);
	}, [isDrawingButtonClicked]);

	useEffect(() => {
		if (!isDrawingPath) {
			setCurrentCanvasMousePosition(null);
		}
	}, [isDrawingPath]);

	// TODO REFACTOR
	useEffect(() => {
		if (isSavePolygonButtonClicked) {
			clearHistory();
			savePolygon();
		} else if (enterPressed) {
			if (newPlot) {
				if (isDrawingButtonClicked) {
					clearHistory();
				}
				savePolygon();
			} else if (editedPolygon.points) {
				savePolygon();
			}
			setDrawingPath(false);
			setNewPlot(null);
			setRight([]);
			setEnterPressed(false);
		}
		setIsSavePolygonButtonClicked(false);
		setEnterPressed(false);
	}, [isSavePolygonButtonClicked, enterPressed]);

	useEffect(() => {
		if (isEditButtonClicked) {
			setIsDrawGuideLineButtonClicked(false);
			setSelectedGuideLineId(undefined);
		}
	}, [isEditButtonClicked]);

	useEffect(() => {
		if (isDrawGuideLineButtonClicked) {
			setIsEditButtonClicked(false);
			setIsDrawingButtonClicked(false);
			return;
		}

		setNewGuideLinePoint([]);
		setNewGuideLinePoints([]);
	}, [isDrawGuideLineButtonClicked]);

	useEffect(() => {
		setHistoryPoints([]);
	}, [enterPressed]);

	function initIntersectionsBetweenGuidelines(savedGuideLines: GuideLineType[]) {
		const guideLinesWithIntersections = cloneDeep(savedGuideLines);
		for (let i = 0; i < savedGuideLines.length; i++) {
			guideLinesWithIntersections[i].intersectionPoints = [];
			for (let j = 0; j < savedGuideLines.length; j++) {
				if (savedGuideLines[i] === savedGuideLines[j]) {
					continue;
				}
				const intersectionPoint = calculateGuideLinesIntersection(
					savedGuideLines[i],
					savedGuideLines[j]
				);
				guideLinesWithIntersections[i].intersectionPoints.push(intersectionPoint);
			}
		}
		setGuideLines([...guideLinesWithIntersections]);
	}

	// Creates new empty polygon
	function createNewPolygon(): PolygonType {
		return {
			id: `polygon${maxPlotId.current++}`,
			points: [] as Point[],
			houseId: house?.id || "-1",
			viewId: project.currentView.id || "-1",
		};
	}

	// Creates guidelines which flow around the polygon
	function createPolygonGuideLines(): GuideLineType[] {
		const extraLines: GuideLineType[] = [];
		componentSavedPolygons.forEach((polygon, i) => {
			for (let j = 0; j < polygon.points.length; j++) {
				const k = (j + 1) % polygon.points.length;
				const polygonLinePoints = [
					{ x: polygon.points[j].x, y: polygon.points[j].y },
					{ x: polygon.points[k].x, y: polygon.points[k].y },
				];

				const polygonLine: GuideLineType = {
					id: `guide-line-polygon-${polygon.id}-${j}`,
					linePoints: {
						point1: polygonLinePoints[0],
						point2: polygonLinePoints[1],
					},
					style: {
						color: "#ffffff",
						width: 0,
					},
					intersectionPoints: [],
				};
				extraLines.push(polygonLine);
			}
		});
		return extraLines;
	}

	// Creates vertical and horizontal guidelines
	function createOrthoGuideLines(fromPoint: Point): GuideLineType[] {
		const { x, y }: any = { ...fromPoint };
		const extraLines: GuideLineType[] = [];
		const verticalLinePoints = [
			{ x: x, y: viewBoxScale.point1.y },
			{ x: x, y: viewBoxScale.point2.y },
		];
		const horizontalLinePoints = [
			{ x: viewBoxScale.point1.x, y },
			{ x: viewBoxScale.point2.x, y },
		];
		const verticalLineParameters = calculateLineParametersFromPoints(
			verticalLinePoints[0],
			verticalLinePoints[1]
		);
		const horizontalLineParameters = calculateLineParametersFromPoints(
			horizontalLinePoints[0],
			horizontalLinePoints[1]
		);
		const verticalLine: GuideLineType = {
			id: `guide-line-vertical-${fromPoint.x}`,
			linePoints: {
				point1: verticalLinePoints[0],
				point2: verticalLinePoints[1],
			},
			lineParameters: verticalLineParameters,
			style: {
				color: "#00ff00",
				width: 1,
			},
			intersectionPoints: [],
		};

		const horizontalLine: GuideLineType = {
			id: `guide-line-horizontal-${fromPoint.y}`,
			linePoints: {
				point1: horizontalLinePoints[0],
				point2: horizontalLinePoints[1],
			},
			lineParameters: horizontalLineParameters,
			style: {
				color: "#00ff00",
				width: 1,
			},
			intersectionPoints: [],
		};

		verticalLine.polygonPoints = {
			point1: {
				x: verticalLinePoints[0].x - GUIDE_LINE_HIDDEN_POLYGON_WIDTH,
				y: verticalLinePoints[0].y,
			},
			point2: {
				x: verticalLinePoints[0].x + GUIDE_LINE_HIDDEN_POLYGON_WIDTH,
				y: verticalLinePoints[0].y,
			},
			point3: {
				x: verticalLinePoints[1].x + GUIDE_LINE_HIDDEN_POLYGON_WIDTH,
				y: verticalLinePoints[1].y,
			},
			point4: {
				x: verticalLinePoints[1].x - GUIDE_LINE_HIDDEN_POLYGON_WIDTH,
				y: verticalLinePoints[1].y,
			},
		};
		horizontalLine.polygonPoints = {
			point1: {
				y: horizontalLinePoints[0].y - GUIDE_LINE_HIDDEN_POLYGON_WIDTH,
				x: horizontalLinePoints[0].x,
			},
			point2: {
				y: horizontalLinePoints[0].y + GUIDE_LINE_HIDDEN_POLYGON_WIDTH,
				x: horizontalLinePoints[0].x,
			},
			point3: {
				y: horizontalLinePoints[1].y + GUIDE_LINE_HIDDEN_POLYGON_WIDTH,
				x: horizontalLinePoints[1].x,
			},
			point4: {
				y: horizontalLinePoints[1].y - GUIDE_LINE_HIDDEN_POLYGON_WIDTH,
				x: horizontalLinePoints[1].x,
			},
		};
		extraLines.push(horizontalLine);
		extraLines.push(verticalLine);
		return extraLines; // [verticalLine, horizontalLine];
	}

	function removeOrthoGuideLines() {
		setOrthoGuideLines([]);
	}

	function createGuideLine(x: number, y: number) {
		const pointsCoords = [newGuideLinePoint[0], { x: x, y: y }];
		const lineParameters = calculateLineParametersFromPoints(
			pointsCoords[0],
			pointsCoords[1]
		);
		const transientLine: GuideLineType = {
			id: calculateLineNumber(guideLines || []),
			linePoints: {
				point1: pointsCoords[0],
				point2: pointsCoords[1],
			},
			lineParameters,
			intersectionPoints: [],
		};
		applyIntersectionPointsToGuideline(transientLine, viewBoxScale);
		setNewGuideLinePoint([]);
		return transientLine;
	}

	function handleKeyUp(e: any) {
		const keyCode = e.which;
		switch (keyCode) {
			case 16: {
				// shift
				if (newPlot && isDrawingPath) {
					removeOrthoGuideLines();
					setIsShiftHold(false);
				}
				break;
			}
		}
	}

	// savedPolygons
	function handleKeyDown(e: any) {
		// copy ctrl + c
		if ((e.metaKey || e.ctrlKey) && e.key === "c") {
			if (polygonId) {
				const copiedPolygon = cloneDeep(
					savedPolygons.find(polygon => polygon.id === polygonId)
				);
				if (copiedPolygon) {
					copiedPolygon.id = `polygon${maxPlotId.current + 1}`;
					copiedPolygon.points.forEach((item: any, index: number) => {
						copiedPolygon.points[index].x = item.x + 10;
						copiedPolygon.points[index].y = item.y + 10;
					});
					maxPlotId.current += 2;
					setPolygonToCopy(copiedPolygon);
				}
			}
			if (selectedGuideLineId) {
				const copiedGuideline = cloneDeep(
					savedGuideLines?.find(guideLine => guideLine.id === selectedGuideLineId)
				);
				if (
					copiedGuideline &&
					copiedGuideline.linePoints.point1 &&
					copiedGuideline.linePoints.point2
				) {
					copiedGuideline.id = calculateLineNumber(guideLines || []);

					copiedGuideline.linePoints.point1.x += 10;
					copiedGuideline.linePoints.point1.y += 10;
					copiedGuideline.linePoints.point2.x += 10;
					copiedGuideline.linePoints.point2.y += 10;

					setGuidelineToCopy(copiedGuideline);
				}
			}
		}

		// copy paste ctrl + v
		if ((e.metaKey || e.ctrlKey) && e.key === "v") {
			if (house && polygonToCopy) {
				const pasteToHouseId = house?.id;
				const pastePolygon = cloneDeep(polygonToCopy);
				pastePolygon.houseId = pasteToHouseId;
				polygonToCopy && savePolygons([...savedPolygons, pastePolygon]);
				setPolygonToCopy(null);
			}
			if (guidelineToCopy) {
				const pasteGuideline = cloneDeep(guidelineToCopy);
				pasteGuideline && saveGuideLine(pasteGuideline);
				setGuidelineToCopy(null);
			}
		}

		// undo action ctrl + z
		if ((e.metaKey || e.ctrlKey) && e.key === "z") {
			// if you are drawing
			if (newPlot && isDrawingButtonClicked) {
				if (newPlot.points.length === 1) {
					setNewPlot(null);
					return;
				}
				const lastPoint = newPlot.points.pop();
				historyPoints.push(lastPoint);

				const points = newPlot.points;
				setNewPlot({
					...newPlot,
					points,
				});
			} else {
				handleUndoPolygon(e);
			}
		}

		// redo action ctrl + y
		if ((e.metaKey || e.ctrlKey) && e.key === "y") {
			// if you are drawing
			if (newPlot && isDrawingButtonClicked) {
				if (historyPoints.length > 0) {
					const lastPoint = historyPoints.pop();

					const points = newPlot.points;
					points.push(lastPoint);
					setNewPlot({
						...newPlot,
						points,
					});
				}
			} else {
				handleRedoPolygon(e);
			}
		}

		// fit image
		if ((e.metaKey || e.ctrlKey) && e.key === "0") {
			setIsResetTransform(true);
			setIsDrawingButtonClicked(false);
		}

		// delete
		if (e.key === "Delete") {
			if (polygonId && house) {
				deletePolygon();
				setPolygonId(null);
				setSelectedPlot(null);
				setHouse(null);
			} else if (guideLines && selectedGuideLineId) {
				for (const line of guideLines) {
					if (line.id === selectedGuideLineId) {
						deleteGuideLine(line);
						break;
					}
				}
			}
		}

		// draw on P key
		if (e.key === "p") {
			setIsDrawingButtonClicked(!isDrawingButtonClicked);
		}

		// edit polygon on E key
		if (e.key === "e" && !isDrawingButtonClicked) {
			if (selectedPlot) {
				setSelectedPlot(null);
				setIsEditButtonClicked(false);
			}
		}

		// draw guideline on G key
		if (e.key === "g") {
			setIsDrawGuideLineButtonClicked(!isDrawGuideLineButtonClicked);
		}

		// toggle guideLines on T key
		if (e.key === "t") {
			if (guideLinesShown) {
				setGuideLinesOnTop(false);
			}
			setGuideLinesShown(!guideLinesShown);
		}

		if (e.key === "f" && guideLinesShown) {
			setGuideLinesOnTop(!guideLinesOnTop);
		}

		if (e.key === "h" && guideLinesShown) {
			setIsHouseNumbersShown(!isHouseNumbersShown);
		}

		// toggle image on I key
		if (e.key === "i") {
			setImageShown(!imageShown);
		}

		// escape
		if (e.key === "Escape") {
			if (!isDrawingButtonClicked) setSelectedPlot(null);
			else setIsDrawingButtonClicked(false);

			if (isDrawGuideLineButtonClicked) {
				setIsDrawGuideLineButtonClicked(false);
				setNewGuideLinePoint([]);
				setNewGuideLinePoints([]);
			}
		}

		const keyCode = e.which;
		switch (keyCode) {
			case 27: {
				// esc
				if (newPlot) {
					e.preventDefault();
					if (isEditButtonClicked) {
						setComponentSavedPolygons([...componentSavedPolygons, editedPolygon]);
					} else {
						clearHistory();
					}
					setNewPlot(null);
					// setDrawingPath(false);
				}
				break;
			}
			case 16: {
				// shift
				if (newPlot && isDrawingPath && newPlot.points.length > 0) {
					let orthoGuideLines = createOrthoGuideLines(
						newPlot.points[newPlot.points.length - 1]
					);
					const polygonGuideLines = createPolygonGuideLines();
					if (newPlot.points.length > 1) {
						orthoGuideLines = orthoGuideLines.concat(
							createOrthoGuideLines(newPlot.points[0])
						);
					}
					setOrthoGuideLines([...polygonGuideLines, ...orthoGuideLines]);
					setIsShiftHold(true);
				}
				break;
			}
		}
	}

	function handleTargetXYClicked(
		target: any,
		x: number,
		y: number,
		ctrl: boolean,
		alt: boolean,
		shift: boolean
	) {
		// If drawing guideline
		if (isDrawGuideLineButtonClicked) {
			if (newGuideLinePoint.length === 0) {
				const guideLinePoint: Point[] = [{ x: x, y: y }];
				setNewGuideLinePoint(guideLinePoint);
			} else {
				let transientLine;
				if (shift && canvasMousePosition) {
					transientLine = createGuideLine(
						canvasMousePosition.x,
						canvasMousePosition.y
					);
				} else {
					transientLine = createGuideLine(x, y);
				}
				setGuideLines([...guideLines, transientLine]);
				setNewGuideLinePoints([]);
				saveGuideLine(transientLine);
				setSelectedGuideLineId(transientLine.id);
			}
		} else if (newPlot && isDrawingPath) {
			// If drawing a polygon
			if (target.tagName === "polygon" || target.tagName === "circle") {
				if (target.id === "x-polygon") {
					addPointToNewPlotPath(x, y);
				} else if (target.tagName === "circle") {
					addPointToNewPlotPath(target.cx.baseVal.value, target.cy.baseVal.value);
				} else if (canvasMousePosition) {
					addPointToNewPlotPath(canvasMousePosition.x, canvasMousePosition.y);
				}
			} else {
				addPointToNewPlotPath(x, y, true);
			}
		} else {
			if (target.tagName === "circle" && !newPlot) {
				addPointToNewPlotPath(target.cx.baseVal.value, target.cy.baseVal.value);
				// TODO: unselecting a plot is broken: } else if (selectedPlotId && !newPlot) {
				// setSelectedPlotId(null);
			} else if (house && !newPlot) {
				if (target.id.includes("guide-line")) {
					if (canvasMousePosition)
						addPointToNewPlotPath(canvasMousePosition.x, canvasMousePosition.y);
				} else {
					addPointToNewPlotPath(x, y);
				}
			}
		}
	}

	function addPointToNewPlotPath(
		x: number,
		y: number,
		backgroundClick?: boolean
	) {
		if (!newPlot) {
			if (!house) return;
			const newPolygon = createNewPolygon();
			newPolygon.points.push({ x, y });
			setDrawingPath(true);
			clearHistory();
			setNewPlot(newPolygon);
		} else {
			const newPolygon = { ...newPlot };
			if (
				!backgroundClick &&
				pointsAreClose(newPolygon.points[0], { x, y }, currentScale)
			) {
				setEnterPressed(true);
				return;
			} else {
				newPolygon.points = newPolygon.points.concat([{ x, y }]);
			}
			pushUndoHistory({ polygon: newPlot, isDrawingPath });
			setNewPlot(newPolygon);
		}
	}

	function setPopUpPosition(target: Element) {
		const boundingRect = target.getBoundingClientRect();
		popupPosition.current = {
			left: (boundingRect.left + boundingRect.right) / 2 - 50,
			top: boundingRect.top + window.pageYOffset + -150,
		};
	}

	function handlePolygonIntersection(
		x: number,
		y: number,
		target: SVGPolygonElement
	) {
		let polygon: { points: Point[] };

		if (newPlot && newPlot.points.length > 0) {
			polygon = newPlot;
		} else {
			const points = right; // todo possible bug here
			polygon = { ...newPlot, points };
		}

		let intersection = findLinePolygonIntersection(
			{
				x,
				y,
			},
			polygon.points[polygon.points.length - 1],
			target.points
		);
		if (
			intersection &&
			pointsAreClose(
				intersection,
				polygon.points[polygon.points.length - 1],
				currentScale
			)
		) {
			intersection = findLinePolygonIntersection(
				{ x, y },
				polygon.points[0],
				target.points
			);
		}
		setCurrentCanvasMousePosition(intersection || { x, y });
	}

	function handleGuideLineIntersection(
		x: number,
		y: number,
		target: SVGPolygonElement,
		guideLines: GuideLineType[]
	) {
		let currentLine = guideLines[0];
		let closestLines = Array<{ l: GuideLineType; d: number }>();
		let distance;
		let mousePosition = null;
		guideLines.forEach(line => {
			distance = calculateDistanceFromPointToGuideLine({ x, y }, line);
			if (distance < 3) {
				closestLines.push({ l: line, d: distance });
			}
			if (line.id === target.id) currentLine = line;
		});
		closestLines.sort((ld1, ld2) => ld1.d - ld2.d);
		// remove duplicates (overlapping lines)
		closestLines = closestLines.filter((line, pos, arr) => {
			return !pos || Math.abs(line.d - arr[pos - 1].d) > 0.001;
		});
		if (closestLines.length < 2) {
			mousePosition = calculateProjectionPoint(
				currentLine.linePoints.point1!,
				currentLine.linePoints.point2!,
				{ x: x, y: y }
			);
		} else {
			// if (!ctrl) {
			const intersection = findGuideLineIntersection(
				closestLines[0].l,
				closestLines[1].l
			);
			if (
				intersection.x &&
				intersection.y &&
				intersection.onLine1 &&
				calculateDistanceBetweenPoints(
					{ x, y },
					{ x: intersection.x!, y: intersection.y! }
				) < 3
			) {
				mousePosition = { x: intersection.x, y: intersection.y };
			} else {
				mousePosition = calculateProjectionPoint(
					closestLines[0].l.linePoints.point1!,
					closestLines[0].l.linePoints.point2!,
					{ x: x, y: y }
				);
			}
		}
		setCurrentCanvasMousePosition(mousePosition || { x, y });
	}

	function isPointOutOfCanvas(
		canvasMousePosition: { x: number; y: number },
		shift: { x: number; y: number },
		viewBoxScale: ViewBoxScale
	) {
		return (
			canvasMousePosition.x + shift.x > viewBoxScale.point2.x ||
			canvasMousePosition.x + shift.x < viewBoxScale.point1.x ||
			canvasMousePosition.y + shift.y > viewBoxScale.point2.y ||
			canvasMousePosition.y + shift.y < viewBoxScale.point1.y
		);
	}

	const handleKeyboardMove = (shift: { x: number; y: number }) => {
		setViewBoxScale(viewBoxScale => {
			setCurrentCanvasMousePosition((canvasMousePosition: any) => {
				if (canvasMousePosition == null) return null;
				if (isPointOutOfCanvas(canvasMousePosition, shift, viewBoxScale))
					return {
						x: canvasMousePosition.x,
						y: canvasMousePosition.y,
					};

				return {
					x: canvasMousePosition.x + shift.x,
					y: canvasMousePosition.y + shift.y,
				};
			});

			return viewBoxScale;
		});
	};

	function handleMouseMove(value: {
		target: any;
		x: number;
		y: number;
		clientX: number;
		clientY: number;
		ctrl: boolean;
		alt: boolean;
		shift: boolean;
	}) {
		const { target, x, y, clientY, clientX, ctrl, alt, shift } = value;

		if (target.id === "edit-circle") {
			target.style.stroke = "#1ac91a";
			selectedVertexRef.current = target;
		} else if (selectedVertexRef.current !== null) {
			selectedVertexRef.current.style.stroke = "#5288f5";
			selectedVertexRef.current = null;
		}

		if (isMovingPolygon) {
			if (
				house &&
				house.polygons.length > 0 &&
				beforeMovePolygonCenter &&
				editedPolygon.points &&
				editedPolygon.points.length > 0
			) {
				const movingPolygon = document.getElementById(`${polygonId}`);
				const differenceX = x - beforeMovePolygonCenter.x;
				const differenceY = y - beforeMovePolygonCenter.y;
				const newPoints = editedPolygon.points.map(
					(point: Point) =>
						({
							x: point.x + differenceX,
							y: point.y + differenceY,
						} as Point)
				);
				const polygons = [...componentSavedPolygons];
				const newPointsStringArray = newPoints
					.map((item: any) => `${item.x}, ${item.y}`)
					.join(", ");
				movingPolygon?.setAttribute("points", newPointsStringArray);
				const editedIndex = polygons.findIndex(
					polygon => polygon.id === editedPolygon.id
				);
				const movedPolygon = { ...polygons[editedIndex] };
				movedPolygon.points = newPoints;
				polygons[editedIndex] = movedPolygon;
				setComponentSavedPolygons(polygons);
			}
		}
		if (isMovingGuideLine) {
			if (
				movingGuideLine &&
				movingGuideLine.lineParameters &&
				beforeMoveGuideLineCenter
			) {
				// ne zahodit
				const differenceX = x - beforeMoveGuideLineCenter.x;
				const differenceY = y - beforeMoveGuideLineCenter.y;
				const lines = [...guideLines];
				const editedIndex = guideLines.findIndex(
					line => line.id === movingGuideLine.id
				);
				const movedLine = { ...lines[editedIndex] };
				if (
					movedLine.lineParameters &&
					movedLine.linePoints.point1 &&
					movedLine.linePoints.point2
				) {
					movedLine.linePoints = {
						point1: {
							x: movedLine.linePoints.point1.x + differenceX,
							y: movedLine.linePoints.point1.y + differenceY,
						},
						point2: {
							x: movedLine.linePoints.point2.x + differenceX,
							y: movedLine.linePoints.point2.y + differenceY,
						},
					};

					if (movedLine.linePoints.point1 && movedLine.linePoints.point2) {
						movedLine.lineParameters = calculateLineParametersFromPoints(
							movedLine.linePoints.point1,
							movedLine.linePoints.point2
						);
					}

					setBeforeMoveGuideLineCenter({
						x: beforeMoveGuideLineCenter.x + differenceX,
						y: beforeMoveGuideLineCenter.y + differenceY,
					});
				}
				lines[editedIndex] = movedLine;
				setGuideLines(lines);
			}
		}
		if (isMovingGuideLinePoint && movingGuideLine) {
			let newCanvasMousePosition = { x, y };
			const movedGuideLinePoint = isMovingGuideLinePoint1
				? movingGuideLine.linePoints.point1
				: movingGuideLine.linePoints.point2;
			if (
				shift &&
				movedGuideLinePoint &&
				movingGuideLine.linePoints.point1 &&
				movingGuideLine.linePoints.point2
			) {
				const staticPoint = isMovingGuideLinePoint1
					? movingGuideLine.linePoints.point2
					: movingGuideLine.linePoints.point1;
				if (
					Math.abs(newCanvasMousePosition.x - staticPoint.x) <
					Math.abs(newCanvasMousePosition.y - staticPoint.y)
				) {
					newCanvasMousePosition = {
						x: staticPoint.x,
						y: y,
					};
				} else {
					newCanvasMousePosition = {
						x: x,
						y: staticPoint.y,
					};
				}
			}

			const lines = [...guideLines];
			const editedIndex = guideLines.findIndex(
				line => line.id === movingGuideLine.id
			);
			const movedLine = { ...lines[editedIndex] };
			if (isMovingGuideLinePoint1) {
				const newVertexCoords = calculateMovingGuidelineVertex(
					movedLine.linePoints.point2!,
					{
						x: newCanvasMousePosition.x,
						y: newCanvasMousePosition.y,
					}
				);
				movedLine.linePoints.point1 = {
					x: newVertexCoords.x,
					y: newVertexCoords.y,
				};
			} else if (isMovingGuideLinePoint2) {
				const newVertexCoords = calculateMovingGuidelineVertex(
					movedLine.linePoints.point1!,
					{
						x: newCanvasMousePosition.x,
						y: newCanvasMousePosition.y,
					}
				);
				movedLine.linePoints.point2 = {
					x: newVertexCoords.x,
					y: newVertexCoords.y,
				};
			}

			if (movedLine.linePoints.point1 && movedLine.linePoints.point2) {
				movedLine.lineParameters = calculateLineParametersFromPoints(
					movedLine.linePoints.point1,
					movedLine.linePoints.point2
				);
			}

			lines[editedIndex] = movedLine;
			setGuideLines(lines);
		}
		if (isDrawGuideLineButtonClicked) {
			let newCanvasMousePosition = { x, y };
			if (
				newGuideLinePoint.length > 0 &&
				newCanvasMousePosition?.x &&
				newCanvasMousePosition?.y
			) {
				if (shift) {
					if (
						Math.abs(newCanvasMousePosition.x - newGuideLinePoint[0].x) >
						Math.abs(newCanvasMousePosition.y - newGuideLinePoint[0].y)
					) {
						newCanvasMousePosition = {
							x: newCanvasMousePosition.x,
							y: newGuideLinePoint[0].y,
						};
					} else {
						newCanvasMousePosition = {
							x: newGuideLinePoint[0].x,
							y: newCanvasMousePosition.y,
						};
					}
				}
				const points = [
					newGuideLinePoint[0],
					{
						x: newCanvasMousePosition?.x,
						y: newCanvasMousePosition?.y,
					},
				];
				setNewGuideLinePoints([...points]);
			}
			setCurrentCanvasMousePosition(newCanvasMousePosition);
		} else if (newPlot && isDrawingPath) {
			setShowCanvasMousePosition(false);
			if (target.tagName === "circle" && !isShiftHold) {
				setCurrentCanvasMousePosition({
					x: target.cx.baseVal.value,
					y: target.cy.baseVal.value,
				});
			} else if (target.id.startsWith("magnetic-polygon") && !isShiftHold) {
				const polygon = savedPolygons.find(
					polygon => polygon.id === target.id.split(";")[1]
				);
				if (polygon) {
					const intersection = findMagneticPolygonIntersection({ x, y }, polygon);
					setCurrentCanvasMousePosition(intersection);
					setShowCanvasMousePosition(true);
				}
			} else if (
				target.tagName === "polygon" &&
				target.id !== "x-polygon" &&
				target.id !== polygonId &&
				!target.id.includes("guide-line") &&
				!isShiftHold
			) {
				handlePolygonIntersection(x, y, target as SVGPolygonElement);
				setShowCanvasMousePosition(true);
			} else if (
				target.tagName === "polygon" &&
				target.id.includes("guide-line") &&
				!isShiftHold
			) {
				if (hoveredGuideLine) setHoveredGuideLine(null);
				handleGuideLineIntersection(x, y, target as SVGPolygonElement, guideLines);
				setShowCanvasMousePosition(true);
			} else if (
				target.tagName === "polygon" &&
				(target.id.includes("horizontal") || target.id.includes("vertical")) &&
				isShiftHold
			) {
				if (hoveredGuideLine) setHoveredGuideLine(null);
				handleGuideLineIntersection(
					x,
					y,
					target as SVGPolygonElement,
					orthoGuideLines
				);
				setShowCanvasMousePosition(true);
			} else {
				setCurrentCanvasMousePosition({ x, y });
			}
		} else if (target.id.includes("guide-line") && !isEditButtonClicked) {
			handleGuideLineIntersection(x, y, target as SVGPolygonElement, guideLines);
			setHoveredPlot(null);
			isDrawingButtonClicked && setShowCanvasMousePosition(true);
			popupPosition.current = {
				left: clientX,
				top: clientY + offsetTop,
			};
			setHoveredSegment(null);
			let line;
			if (guideLines) {
				line = guideLines?.find(line => line.id === target.id);
			}
			setHoveredGuideLine(line || null);
		} else if (target.tagName === "polygon") {
			setHoveredGuideLine(null);
			setHoveredPoint(null);
			setShowCanvasMousePosition(false);
			if ((!hoveredPlot || target.id !== hoveredPlot.id) && !isEditButtonClicked) {
				// setPopUpPosition(target);
				// setHoveredPlot(savedPolygons.find(polygon => polygon.id === target.id));
			} else {
				const polygon = componentSavedPolygons.find(
					polygon => polygon.id === target.id
				);
				if (polygon && isEditButtonClicked) {
					const closestSegment = findClosestSegment(polygon, { x, y }, currentScale);
					if (closestSegment) {
						popupPosition.current = {
							left: clientX,
							top: clientY,
						};
						setHoveredPoint(null);
						setHoveredSegment(closestSegment);
					} else {
						setHoveredSegment(null);
					}
				}
			}
		} else if (target.tagName === "circle" && isEditButtonClicked) {
			setHoveredGuideLine(null);
			setPopUpPosition(target as SVGCircleElement);
			setHoveredSegment(null);
			setHoveredPoint(target as SVGCircleElement);
			setShowCanvasMousePosition(false);
		} else {
			setShowCanvasMousePosition(false);
			// if (hoveredPlot) setHoveredPlot(null);
			if (hoveredGuideLine) setHoveredGuideLine(null);
			if (hoveredPoint) setHoveredPoint(null);
			if (hoveredSegment) setHoveredSegment(null);
		}
	}

	function handleMouseOutCanvas() {
		setCurrentCanvasMousePosition(null);
	}

	function selectPoint(polygon: PolygonType, i: number) {
		const noEditedPolygons: PolygonType[] = [...componentSavedPolygons];
		noEditedPolygons.splice(noEditedPolygons.indexOf(polygon), 1);
		setComponentSavedPolygons(noEditedPolygons);
		setEditedPolygon(polygon);
		const left = polygon.points.slice(0, i);
		const right = polygon.points.slice(i + 1);
		polygon.points = right.concat(left);
		setNewPlot(polygon);
		setDrawingPath(true);
	}

	function handleCreatePolygon(polygonUpdatedPoints: {
		houseId: string;
		viewId: string;
		id: string;
		points: Point[];
	}) {
		if (!polygonUpdatedPoints.houseId) {
			polygonUpdatedPoints.houseId = house!.id;
			polygonUpdatedPoints.id = `polygon${maxPlotId.current++}`;
			polygonUpdatedPoints.viewId = project.currentView.id;
		}
	}

	// TODO REFACTOR
	function pointNewPosition(value: {
		x: number;
		y: number;
		alternativePointsArr?: Point[];
	}) {
		const { x, y, alternativePointsArr } = value;
		let clickPointCoords;

		if (canvasMousePosition) {
			clickPointCoords = canvasMousePosition;
		} else {
			clickPointCoords = { x: x, y: y };
		}

		let points: Point[];

		alternativePointsArr
			? (points = [...alternativePointsArr])
			: (points = [...editedPolygon.points, clickPointCoords, ...right]);

		const uniquePoints = points.filter((thing, index) => {
			const _thing = JSON.stringify(thing);
			return (
				index ===
				points.findIndex(obj => {
					return JSON.stringify(obj) === _thing;
				})
			);
		});

		const polygonUpdatedPoints = { ...editedPolygon };
		polygonUpdatedPoints.points = [...uniquePoints];

		// TODO 5th step - updating polygon and returning array of all polygons

		handleCreatePolygon(polygonUpdatedPoints);

		setComponentSavedPolygons([...componentSavedPolygons, polygonUpdatedPoints]);

		setNewPlot(null);
		setDrawingPath(false);
		removeOrthoGuideLines();
		return [...componentSavedPolygons, polygonUpdatedPoints];
	}

	function insertPointIntoSegment(
		polygon: PolygonType,
		closestSegment: Segment,
		point: Point
	) {
		const findPoint = polygon.points.find(
			(point: Point) =>
				point.y === closestSegment.sides[0].y &&
				point.x === closestSegment.sides[0].x
		);

		if (findPoint) {
			const index = polygon.points.indexOf(findPoint);
			if (!closestSegment.isLastSegment) {
				polygon.points.splice(index + 1, 0, point);
				selectPoint(polygon, index + 1);
			} else {
				polygon.points.splice(polygon.points.length, 0, point);
				selectPoint(polygon, polygon.points.length - 1);
			}
		}
	}

	const handlePolygonDrawing = (
		target: any,
		x: number,
		y: number,
		ctrl: boolean,
		alt: boolean,
		shift: boolean
	) => {
		// TODO 1st step - click
		let polygon;
		if (house && house.polygons.length > 0) {
			polygon = componentSavedPolygons.find(polygon => polygon.id === polygonId);
		}
		if (isMovingGuideLine || isMovingGuideLinePoint) {
			// setIsDrawGuideLineButtonClicked(true)
			setIsMovingGuideLinePoint(false);
			setIsMovingGuideLinePoint1(false);
			setIsMovingGuideLinePoint2(false);
			setIsMovingGuideLine(false);
			return;
		}
		if (isDrawGuideLineButtonClicked) {
			handleTargetXYClicked(target, x, y, ctrl, alt, shift);
		} else if (isDrawingButtonClicked) {
			handleTargetXYClicked(target, x, y, ctrl, alt, shift);
		} else if (
			(isEditButtonClicked && isDrawingPath) ||
			(enterPressed && isDrawingPath)
		) {
			// TODO 4th step - set new position of vertex
			savePolygons(pointNewPosition({ x, y }));
		} else if (isEditButtonClicked && componentSavedPolygons) {
			// TODO 3d step - selecting vertex
			// if (hoveredPlot) setHoveredPlot(null);
			if (hoveredGuideLine) setHoveredGuideLine(null);
			if (hoveredPoint) setHoveredPoint(null);
			if (hoveredSegment) setHoveredSegment(null);
			if (polygon) {
				const indexOfClosetPointToSelect = findIndexOfClosestPointToSelect(
					polygon.points,
					{ x, y },
					currentScale
				);
				if (indexOfClosetPointToSelect !== -1) {
					saveToUndoHistory(polygon);
					selectPoint(polygon, indexOfClosetPointToSelect);
					setCurrentCanvasMousePosition({ x, y });
					return;
				}

				const closestSegment = findClosestSegment(polygon, { x, y }, currentScale);
				if (closestSegment) {
					saveToUndoHistory(polygon);
					insertPointIntoSegment(polygon, closestSegment, { x, y });
					return;
				}
			}
		}
		if (
			target.tagName === "polygon" &&
			target.id !== polygonId &&
			!isDrawingButtonClicked &&
			isPointerButtonClicked
		) {
			// TODO 2nd step - selecting plot
			setSelectedPlot(target.id);
		}

		if (
			!isMovingPolygon &&
			!isMovingGuideLine &&
			!isMovingGuideLinePoint &&
			hoveredGuideLine
		) {
			setSelectedGuideLineId(hoveredGuideLine.id);
		}
		//   handleTargetXYClicked(target, x, y);
	};

	const handleMouseDown = (
		target: any,
		x: number,
		y: number,
		ctrl: boolean,
		alt: boolean,
		shift: boolean
	) => {
		let polygon;
		if (house && house.polygons.length > 0) {
			polygon = componentSavedPolygons.find(polygon => polygon.id === polygonId);
		}

		if (isEditButtonClicked) {
			setIsDrawGuideLineButtonClicked(false);
			if (componentSavedPolygons) {
				if (
					target.id === "center" &&
					target.getAttribute("data-polygonId") === polygonId
				) {
					if (!isMovingPolygon) {
						setIsMovingPolygon(true);
						setNewPlot(null);
						setBeforeMovePolygonCenter({
							x: target.cx.baseVal.value,
							y: target.cy.baseVal.value,
						});
						if (polygon) {
							setEditedPolygon(polygon);
						}
					}
					return;
				}
				if (target.id === "houseId") {
					if (
						!isMovingPolygon &&
						target.getAttribute("data-polygonId") === polygonId
					) {
						setIsMovingPolygon(true);
						setNewPlot(null);
						setBeforeMovePolygonCenter({
							x: target.x.baseVal[0].value,
							y: target.y.baseVal[0].value,
						});
						if (polygon) {
							setEditedPolygon(polygon);
						}
						// setIsEditButtonClicked(true);
					}
					return;
				}
			}
		}

		const targetIdSplitted = target.id.split(";");
		if (targetIdSplitted[0] === "move") {
			if (!isMovingGuideLine) {
				setIsMovingGuideLine(true);
				setIsDrawGuideLineButtonClicked(false);
				setMovingGuideLine(
					guideLines.find(line => line.id === targetIdSplitted[1])
				);
				setBeforeMoveGuideLineCenter({
					x: target.cx.baseVal.value,
					y: target.cy.baseVal.value,
				});
			}
		} else if (targetIdSplitted[0] === "point1") {
			setMovingGuideLine(guideLines.find(line => line.id === targetIdSplitted[1]));
			setIsDrawGuideLineButtonClicked(false);
			setIsMovingGuideLinePoint1(true);
			setIsMovingGuideLinePoint(true);
		} else if (targetIdSplitted[0] === "point2") {
			setMovingGuideLine(guideLines.find(line => line.id === targetIdSplitted[1]));
			setIsDrawGuideLineButtonClicked(false);
			setIsMovingGuideLinePoint2(true);
			setIsMovingGuideLinePoint(true);
		} else if (
			!isMovingPolygon &&
			!isMovingGuideLine &&
			!isMovingGuideLinePoint &&
			!hoveredGuideLine
		) {
			setSelectedGuideLineId(undefined);
		}
	};

	const handleMouseUp = (
		target: any,
		x: number,
		y: number,
		ctrl: boolean,
		alt: boolean,
		shift: boolean
	) => {
		if (isEditButtonClicked && componentSavedPolygons && isMovingPolygon) {
			setIsMovingPolygon(false);
			savePolygons(componentSavedPolygons);
		}

		if (isMovingGuideLine) {
			if (movingGuideLine) {
				const newLine = guideLines.find(line => line.id === movingGuideLine.id);
				if (newLine) {
					applyIntersectionPointsToGuideline(newLine, viewBoxScale);
					saveGuideLine(newLine, true);
				}
			}
		}

		if (isMovingGuideLinePoint) {
			if (movingGuideLine) {
				const newLine = guideLines.find(line => line.id === movingGuideLine.id);
				if (newLine) {
					// deleteGuideLine(movingGuideLine);
					applyIntersectionPointsToGuideline(newLine, viewBoxScale);
					saveGuideLine(newLine, true);
				}
			}
		}
	};

	function savePolygon() {
		let newSavedPolygons = [];
		if (newPlot) {
			const viewIndex = project.views.findIndex(
				(view: View) => view.id === project.currentView.id
			);
			if (newPlot.points.length < 3) {
				showPolygonAlert(true);
			} else {
				if (!project.currentView.polygons) {
					project.currentView.polygons = [newPlot];
					project.views[viewIndex].polygons = [newPlot];
				} else {
					project.currentView.polygons.push(newPlot);
					// project.views[viewIndex].polygons.push(newPlot);
				}
				newSavedPolygons.push(newPlot);
			}
			setDrawingPath(false);
			clearHistory();
			setNewPlot(null);
			setRight([]);
		}
		newSavedPolygons = [...componentSavedPolygons, ...newSavedPolygons];

		savePolygons(newSavedPolygons);
	}

	function saveToUndoHistory(polygon: PolygonType) {
		pushUndoHistory({
			isDrawingPath: isDrawingPath,
			polygon: cloneDeep(polygon),
		});
	}

	function handleRightClick(e: any, x: number, y: number) {
		if (isEditButtonClicked) {
			const polygon = componentSavedPolygons.find(
				polygon => polygon.id === polygonId
			);
			if (polygon && polygon.points.length > 3) {
				for (let i = 0; i < polygon.points.length; i++) {
					const areClose = pointsAreClose(polygon.points[i], { x, y }, currentScale);
					if (areClose) {
						saveToUndoHistory(polygon);
						polygon.points.splice(i, 1);
						savePolygon();
						return;
					}
				}
			} else {
				if (e.target.id === "") {
					showPolygonAlert(true);
				}
			}
		} else if (guideLines && selectedGuideLineId) {
			for (const line of guideLines) {
				if (line.id === selectedGuideLineId) {
					const updatedGuideLines = [...guideLines];
					updatedGuideLines.splice(guideLines.indexOf(line), 1);
					setGuideLines([...updatedGuideLines]);
					deleteGuideLine(line);
				}
			}
		}
	}

	return (
		<div style={{ flex: 1 }}>
			{aerialView ? (
				<>
					{hoveredPoint &&
						hoveredPoint.getAttribute("data-polygonId") === polygonId &&
						hoveredPoint.id === "center" && (
							<MovePolygonTooltip position={popupPosition.current} />
						)}
					{hoveredGuideLine && <GuideLineTooltip position={popupPosition.current} />}
					<Snackbar
						open={isAlertPolygonShown}
						autoHideDuration={AUTO_HIDDEN_ALERT_DURATION}
						onClose={handleAlertPolygonClose}
					>
						<Alert
							variant='filled'
							severity='error'
							onClose={handleAlertPolygonClose}
						>
							Polygon should contain at least 3 points!
						</Alert>
					</Snackbar>
					<Canvas
						newGuideLine={[...newGuideLinePoints]}
						guideLines={[...guideLines]}
						orthoGuideLines={[...orthoGuideLines]}
						isShiftHold={isShiftHold}
						aerialView={aerialView}
						newPath={
							newPlot &&
							isDrawingPath &&
							(canvasMousePosition
								? [...newPlot.points, canvasMousePosition, ...right]
								: newPlot.points)
						}
						houses={houses || []}
						views={views || []}
						canvasMousePosition={showCanvasMousePosition ? canvasMousePosition : null}
						onClickTargetXY={(
							target: any,
							x: number,
							y: number,
							ctrl: boolean,
							alt: boolean,
							shift: boolean
						) => handlePolygonDrawing(target, x, y, ctrl, alt, shift)}
						onMouseDownTargetXY={(
							target: any,
							x: number,
							y: number,
							ctrl: boolean,
							alt: boolean,
							shift: boolean
						) => handleMouseDown(target, x, y, ctrl, alt, shift)}
						onMouseUpTargetXY={(
							target: any,
							x: number,
							y: number,
							ctrl: boolean,
							alt: boolean,
							shift: boolean
						) => handleMouseUp(target, x, y, ctrl, alt, shift)}
						isDrawingPath={isDrawingPath}
						onMouseMove={(
							target: any,
							x: number,
							y: number,
							clientX: number,
							clientY: number,
							ctrl: boolean,
							alt: boolean,
							shift: boolean
						) =>
							handleMouseMove({
								target,
								x,
								y,
								clientX,
								clientY,
								ctrl,
								alt,
								shift,
							})
						}
						onMouseLeave={handleMouseOutCanvas}
						onRightClick={(e: any, x: number, y: number) => handleRightClick(e, x, y)}
						viewBoxScale={viewBoxScale}
						handleKeyboardMove={handleKeyboardMove}
						savePolygon={() => {
							setEnterPressed(true);
						}}
						selectedGuideLineId={selectedGuideLineId}
						hoveredSegment={hoveredSegment}
						projectEditorSettings={{
							...(projectEditorSettings || DEFAULT_EDITOR_SETTINGS),
						}}
						savedPolygons={savedPolygons}
						updateProjectData={updateProjectData}
						project={project}
						setSelectedPlot={setSelectedPlot}
						selectedPlot={selectedPlot}
						offsetTop={offsetTop}
						setOffsetTop={setOffsetTop}
					/>
					{isDrawingPage ? (
						<SaveSvgButton project={project} aerialViewImage={aerialView}>
							Opslaan
						</SaveSvgButton>
					) : (
						publishButtons && (
							<PublishButtons>
								<IFrameClipboardButton projectId={projectId} />
								<PublishButton project={project}>Publish</PublishButton>
							</PublishButtons>
						)
					)}
				</>
			) : (
				"Loading ..."
			)}
		</div>
	);
}
