import styled from "styled-components";

export const Container = styled.div`
	min-height: 100vh;
	min-width: 80vw;
	padding-top: 8%;
	overflow: hidden;

	.regErrorText {
		right: -300px;
		bottom: 20px;
	}

	.logInErrorText {
		right: -200px;
		bottom: 20px;
	}
`;
