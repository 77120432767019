import axios from "axios";
import { VIEW_URL } from "../platform/utils/constants/UrlConstants";

const deleteView = (viewId: string) => {
	return axios.delete(VIEW_URL + viewId);
};

export default {
	deleteView,
};
