import React, { useState } from "react";
import AuthService from "../../../services/AuthService";
import { useDispatch } from "react-redux";
import Sidebar from "../SideBar";
import {
	MobileIcon,
	Nav,
	NavBarContainer,
	NavBtn,
	NavBtnLink,
	NavItem,
	NavLinkRedirect,
	NavLinks,
	NavLogo,
	NavMenu,
} from "../NavBar/NavBarElements";
import { HOME_PATH } from "../../utils/constants/PathConstants";
import { FaBars } from "react-icons/fa";

const LoggedUserHeader = () => {
	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Sidebar isOpen={isOpen} toggle={toggle} />
			<Nav>
				<NavBarContainer>
					<NavLogo to={HOME_PATH}>MapTool</NavLogo>
					<MobileIcon onClick={toggle}>
						<FaBars />
					</MobileIcon>
					<NavMenu>
						<NavItem>
							<NavLinkRedirect to='/new-project-flow'>
								Create New Project
							</NavLinkRedirect>
						</NavItem>
						<NavItem>
							<NavLinks to='language'>Language</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinkRedirect to='/account'>Account</NavLinkRedirect>
						</NavItem>
					</NavMenu>
					<NavBtn>
						<NavBtnLink to={HOME_PATH} onClick={() => dispatch(AuthService.logout())}>
							Log out
						</NavBtnLink>
					</NavBtn>
				</NavBarContainer>
			</Nav>
		</>
	);
};

export default LoggedUserHeader;
