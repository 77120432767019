import React from "react";
import { ToolPanel } from "./drawing-view/ToolPanel";
import { ProjectState } from "../../types/Project";
import { AppMode, ContextProvider } from "./context/AppModeContext";
import { HouseContextProvider } from "./context/HouseContext";
import { ToolboxContextProvider } from "./context/ToolboxContext";
import { Image } from "../../types/House";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ViewsBar from "./views/ViewsBar";
import { View } from "../../types/View";

export type ToolProps = {
	project: ProjectState;
	updateProjectData?: (data: ProjectState) => void;
	barVisible?: boolean;
	appMode?: AppMode;
	publishButtons?: boolean;
	stepMode?: boolean;
	setToolView?: (toolView: boolean) => void;
	tableButton?: boolean;
	isDrawingPage?: boolean;
	handleUndo?: any;
	handleRedo?: any;
};

const Tool = ({
	project,
	updateProjectData,
	barVisible,
	appMode,
	publishButtons,
	stepMode,
	setToolView,
	tableButton,
	isDrawingPage,
	handleUndo,
	handleRedo,
}: ToolProps) => {
	const formatHousesData = (): void => {
		project.houses.forEach((house: any) => {
			house.imagesMap = new Map<string, Image>();
			if (!house.polygons) {
				house.polygons = [];
			}
		});
		if (project.currentView.viewProperties) {
			if (!project.currentView.viewProperties.guideLines) {
				project.currentView.viewProperties.guideLines = [];
			}
		}
	};

	const formatViewsData = (): void => {
		project.views.forEach((view: View) => {
			if (!view.houses) {
				view.houses = [];
			}
		});
	};

	const formatProjectData = (): ProjectState => {
		if (project) {
			if (project.houses) {
				formatHousesData();
			}
			if (project.views) {
				formatViewsData();
			}
		}
		return project;
	};

	const formattedProject: ProjectState = formatProjectData();

	return (
		<>
			<ContextProvider>
				<HouseContextProvider>
					<ToolboxContextProvider>
						<DndProvider backend={HTML5Backend}>
							<ViewsBar project={formattedProject} />
							<ToolPanel
								isDrawingPage={isDrawingPage}
								project={formattedProject}
								updateProjectData={updateProjectData}
								barVisible={barVisible}
								appMode={appMode || AppMode.edit}
								publishButtons={publishButtons}
								stepMode={stepMode}
								setToolView={setToolView}
								tableButton={tableButton}
								handleUndo={handleUndo}
								handleRedo={handleRedo}
							/>
						</DndProvider>
					</ToolboxContextProvider>
				</HouseContextProvider>
			</ContextProvider>
		</>
	);
};

export default Tool;
