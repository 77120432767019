import React, { useEffect, useState } from "react";
import Projects from "../projects/Projects";
import ProjectService from "../../../services/ProjectService";
import { Wrapper } from "./styles";
import { Project } from "../../../types/Project";

const UserAccount = () => {
	const [projects, setProjects] = useState<Project[]>([]);

	useEffect(() => {
		ProjectService.getAllProjects().then((resp: any) => setProjects(resp));
		document.body.style.background = "#EEEEEE";
	}, []);

	return (
		<Wrapper>
			<Projects projects={projects} setProjects={setProjects} />
		</Wrapper>
	);
};

export default UserAccount;
