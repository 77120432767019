import styled from "styled-components";
import React from "react";
import uploadImg from "../../../assets/images/icons/create-project/upload_img.svg";

// $ variables dependant on Data.ts property values

interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
	primary?: boolean;
	big?: boolean;
	dark?: boolean;
	fontBig?: boolean;
}

export const Button = styled.div<ButtonProps>`
	font-family: "Silka", sans-serif;
	border-radius: 50px;
	background: ${props => (props.primary ? "#46acfa" : "#010606")};
	white-space: nowrap;
	padding: ${props => (props.big ? "14px 48px" : "12px 30px")};
	color: ${props => (props.dark ? "#010606" : "#fff")};
	font-size: ${props => (props.fontBig ? "20px" : "16px")};
	outline: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	box-shadow: 0px 2px 2px 2px #00000018;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: ${props => (props.primary ? "#fff" : "#000")};
	}
`;

export const UploadFormButton = styled.div`
	background: url(${uploadImg}) no-repeat;
	background-color: white;
	background-size: contain;
	background-position: center;
	border: 2px solid transparent;
	color: #000;
	display: block;
	width: 92px;
	height: 56px;
	margin-right: 15px;
	transition: all 0.23s;
	cursor: pointer;

	&:hover {
		border: 2px solid gray;
	}
`;
