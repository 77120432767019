import React, { useContext } from "react";
import { GuideLineType } from "types/GuideLineType";
import { Point } from "types/PolygonType";
import { ViewBoxScale } from "tool/components/drawing-view/map/Canvas";
import {
	AppMode,
	AppModeContext,
} from "tool/components/context/AppModeContext";
import {
	calculateCanvasIntersectionPoints,
	calculateGuidelineControls,
	calculateGuideLineHiddenScalingPolygon,
} from "tool/components/drawing-view/map/utils/MapBuildUtils";
import {
	SVGCircle,
	SVGIntersectionCircle,
	SVGLine,
	SVGPolygon,
} from "./styles";

type Props = {
	guideLine: GuideLineType;
	selectedGuideLineId: string | undefined | null;
	viewBoxScale: ViewBoxScale;
	currentScale: number;
	isDrawingGuideline: boolean;
	isDrawingPath?: boolean;
};

const GuideLine = ({
	guideLine,
	selectedGuideLineId,
	viewBoxScale,
	currentScale,
	isDrawingGuideline,
	isDrawingPath,
}: Props) => {
	const { appMode } = useContext(AppModeContext);
	const isSelected = guideLine.id === selectedGuideLineId;
	const stroke = guideLine.style?.color
		? guideLine.style?.color
		: isSelected
		? "#3392FF"
		: "red";
	const adjustmentPoints = calculateGuidelineControls(
		guideLine.linePoints.point1!,
		guideLine.linePoints.point2!
	);
	const [intersectionPoint1, intersectionPoint2]: Point[] | null[] =
		isDrawingGuideline
			? calculateCanvasIntersectionPoints(guideLine, viewBoxScale)
			: [null, null];

	guideLine.adjustmentPoints = {
		point1: adjustmentPoints.point2,
		point2: adjustmentPoints.point1,
	};

	if (appMode === AppMode.view) {
		return <></>;
	}

	return (
		<g>
			<g id={guideLine.id!.toString()}>
				{isDrawingGuideline && intersectionPoint1 && intersectionPoint2 && (
					<>
						<SVGLine
							stroke={stroke}
							strokeWidth={guideLine.style?.width || "0.25pt"}
							x1={intersectionPoint1.x}
							y1={intersectionPoint1.y}
							x2={guideLine.linePoints.point1?.x}
							y2={guideLine.linePoints.point1?.y}
							currentScale={currentScale}
						/>
						<SVGLine
							stroke={stroke}
							strokeWidth={guideLine.style?.width || "0.25pt"}
							x1={guideLine.linePoints.point2?.x}
							y1={guideLine.linePoints.point2?.y}
							x2={intersectionPoint2.x}
							y2={intersectionPoint2.y}
							currentScale={currentScale}
						/>
					</>
				)}
				<SVGLine
					stroke={stroke}
					strokeWidth={guideLine.style?.width || "0.25pt"}
					x1={guideLine.linePoints.point1?.x}
					y1={guideLine.linePoints.point1?.y}
					x2={guideLine.linePoints.point2?.x}
					y2={guideLine.linePoints.point2?.y}
					currentScale={currentScale}
				/>
			</g>
			{guideLine.polygonPoints && (
				<SVGPolygon
					id={guideLine.id!.toString()}
					points={Array.from(
						calculateGuideLineHiddenScalingPolygon(
							guideLine,
							viewBoxScale,
							currentScale
						)
					).reduce((str, point) => str + " " + point!.x + "," + point!.y, "")}
					currentScale={currentScale}
				/>
			)}
			{guideLine.linePoints.point1 && guideLine.linePoints.point2 && isSelected && (
				<>
					<SVGCircle
						id={"move;" + guideLine.id!.toString()}
						cx={(guideLine.linePoints.point1.x + guideLine.linePoints.point2.x) / 2}
						cy={(guideLine.linePoints.point1.y + guideLine.linePoints.point2.y) / 2}
						style={{ cursor: "move" }}
						currentScale={currentScale}
					/>
					<SVGCircle
						id={"point1;" + guideLine.id!.toString()}
						cx={guideLine.adjustmentPoints?.point1.x}
						cy={guideLine.adjustmentPoints?.point1.y}
						style={{ stroke: "yellow", fill: "white", cursor: "nesw-resize" }}
						currentScale={currentScale}
					/>
					<SVGCircle
						id={"point2;" + guideLine.id!.toString()}
						cx={guideLine.adjustmentPoints?.point2.x}
						cy={guideLine.adjustmentPoints?.point2.y}
						style={{ stroke: "yellow", fill: "white", cursor: "nesw-resize" }}
						currentScale={currentScale}
					/>
				</>
			)}
			{guideLine.intersectionPoints &&
				isDrawingPath &&
				Array.from(guideLine.intersectionPoints).map(
					(point: Point, index: number) => (
						<SVGIntersectionCircle
							key={index}
							cx={point.x}
							cy={point.y}
							currentScale={currentScale}
						/>
					)
				)}
		</g>
	);
};

export default GuideLine;
