import styled from "styled-components";
import React from "react";

interface CheckBoxProps extends React.HTMLAttributes<HTMLElement> {
	active?: boolean;
	disabled?: boolean;
}

export const CheckBoxCustom = styled.div<CheckBoxProps>`
	border-radius: 2px;
	margin-right: 15px;
	background: ${props => (props.active ? "#06B900" : "#ffffff")};
	outline: none;
	cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	box-shadow: 0px 2px 2px 2px #00000018;
	width: 20px;
	height: 20px;

	pointer-events: ${props => props.disabled && "none"};

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #06b900;
	}
`;
