import React from "react";
import { Menu, PencilMenuImg } from "./ViewsBarElements";
import DeleteView from "./DeleteView";
import { View } from "../../../types/View";

type TabMenuProps = {
	view: View;
	canBeDeleted: boolean;
	handlePencilMenu: () => void;
};

const TabMenu = ({ view, canBeDeleted, handlePencilMenu }: TabMenuProps) => {
	const onClickPencil = (event: any) => {
		event.stopPropagation();
		handlePencilMenu();
	};

	return (
		<Menu>
			<PencilMenuImg onClick={onClickPencil} />
			<DeleteView viewId={view.id} disabled={!canBeDeleted} />
		</Menu>
	);
};

export default TabMenu;
