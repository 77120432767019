import React, { useState } from "react";
import { AddView, Arrow, Bar, BarHead } from "./ViewsBarElements";
import ViewTab from "./ViewTab";
import { ProjectState } from "../../../types/Project";
import { View } from "../../../types/View";
import cloneDeep from "clone-deep";
import ProjectService from "../../../services/ProjectService";
import {
	addView,
	getProject,
	switchView,
} from "../../../platform/redux/actions/ProjectActions";
import { useDispatch } from "react-redux";
import Expand from "react-expand-animated";

const ViewsBar = ({ project }: { project: ProjectState }) => {
	const dispatch = useDispatch();
	const [isExpanded, setIsExpanded] = useState(false);

	const sortedViews: View[] = project.views
		.sort((a: View, b: View) => a.viewNumber - b.viewNumber)
		.filter((view: View) => view.id !== project.currentView.id);

	const handleUpdateView = (viewToUpdate: View): void => {
		const projectToUpdate: ProjectState = cloneDeep(project);

		if (projectToUpdate.currentView.id === viewToUpdate.id) {
			projectToUpdate.currentView = viewToUpdate;
		}

		const viewsToSave: View[] = projectToUpdate.views.filter(
			(view: View) => view.id !== viewToUpdate.id
		);

		if (viewToUpdate.isHome) {
			viewsToSave.forEach((view: View) => {
				if (view.isHome) {
					view.isHome = false;
				}
			});
		}

		viewsToSave.push(viewToUpdate);
		projectToUpdate.views = viewsToSave;

		ProjectService.updateProject(projectToUpdate.id, projectToUpdate)
			.then(res => res.data)
			.then((project: ProjectState) => {
				dispatch(
					getProject({
						...project,
						currentView: project.views.filter(
							(view: View) => view.id === projectToUpdate.currentView.id
						)[0],
					})
				);
			});
	};

	const createNewView = () => {
		ProjectService.createView(project.id).then(res => {
			const views = res.data.views as View[];
			const newView = views.sort((a, b) => a.viewNumber - b.viewNumber).pop();
			if (newView) {
				dispatch(addView(res.data));
				dispatch(switchView(newView));
			}
		});
	};

	return (
		<Bar onClick={() => setIsExpanded(!isExpanded)}>
			<BarHead>
				<ViewTab
					view={project.currentView}
					handleUpdateView={handleUpdateView}
					canBeDeleted={project.views.length > 1}
				/>
				<Arrow isCollapsed={isExpanded} />
			</BarHead>
			<Expand open={isExpanded}>
				{sortedViews.map(view => (
					<ViewTab
						key={view.id}
						view={view}
						handleUpdateView={handleUpdateView}
						canBeDeleted={project.views.length > 1}
					/>
				))}
				<AddView onClick={createNewView}>+ Add views</AddView>
			</Expand>
		</Bar>
	);
};

export default ViewsBar;
