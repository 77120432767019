import styled from "styled-components";

export const MenuWrapper = styled.div<{ $units?: boolean }>`
	width: 100%;
	max-height: 302px;
	display: flex;
	flex-wrap: wrap;
	padding: ${({ $units }) => ($units ? "0 0 15px;" : "15px 0 15px;")};
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: white;
	overflow-y: scroll;
	overflow-x: hidden;

	p {
		cursor: pointer;
		width: ${({ $units }) => ($units ? 50 : 100)}%;
		text-align: center;
		padding: 10px;

		&:hover {
			background-color: #508ef2;
		}
	}
`;

export const SearchBar = styled.input`
	width: 100%;
	border: none;
	background-color: #f1f1f1;
	padding: 15px 7px;
	position: sticky;
	top: 0;
`;

export const PolygonContextMenuWrapper = styled.div<{ top: any; left: any }>`
	position: absolute;
	top: ${props => props.top}px;
	left: ${props => props.left - 77}px;
	z-index: 999;
	display: flex;
	flex-direction: column;

	width: 221px;
`;

export const PolygonContextMenuHead = styled.div`
	background-color: white;
	display: flex;
	opacity: 0.8;
	height: 50px;
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`;

export const PolygonContextMenuTab = styled.div<{ isActive: boolean }>`
	font-size: 16px;
	font-weight: bold;
	font-family: Silka, serif;
	display: flex;
	justify-content: center;
	padding-top: 16px;
	width: 50%;
	height: 100%;
	cursor: pointer;
	color: ${({ isActive }) => (isActive ? "#4e92f7" : "black")};
	border-bottom: ${({ isActive }) => (isActive ? "#4e92f7 7px solid" : "none")};

	&:hover {
		border-bottom: #4e92f7 7px solid;
		color: #4e92f7;
	}
`;
