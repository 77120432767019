import React, { useEffect, useState } from "react";
import {
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from "react-table";
import * as AiIcons from "react-icons/ai";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import "./table.css";
import GlobalSearch from "./TableFunctions/GlobalSearch";
import { Checkbox } from "./TableFunctions/Checkbox";
import { ColumnType } from "./house-table/HouseTable";
import {
	TableButtonAddColumn,
	TableButtonAddHouse,
	TableButtonContainer,
	TableButtonEdit,
	TableButtonRemove,
} from "./TableElements";
import { AddColumn, AddRow } from "./styles";
import deleteIcon from "../../../assets/images/icons/project/delete.png";
import IconButton from "@material-ui/core/IconButton";
import SelectCustom, { ColoredCircle } from "../select";

const EditableCell = (props: {
	value: any;
	row: any;
	column: any;
	updateData: any;
}) => {
	const [value, setValue] = useState("");
	const [isSelected, setIsSelected] = useState<boolean>(false);

	useEffect(() => {
		if (!props.value) {
			setValue("");
		} else {
			setValue(props.value);
		}
	}, []);

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	const onChange = (e: any) => {
		setValue(e.target.value);
	};
	const onChangeValue = (value: any) => {
		setValue(value);
	};
	const onBlur = () => {
		if (isSelected) {
			setIsSelected(false);
			props.updateData(props.row.id, props.column.id, value);
		}
	};
	const renderInput = () => {
		switch (props.column.type) {
			case ColumnType.SELECT:
				return (
					<div>
						<SelectCustom
							data={props.column.values}
							selected={value}
							onChange={onChangeValue}
							save={onBlur}
						/>
					</div>
				);
			default:
				return (
					<input
						autoFocus
						onChange={onChange}
						onFocus={e => e.target.select()}
						value={value}
						type={ColumnType[props.column.type]}
						style={{
							width: "100%",
							border: "none",
							background: "transparent",
							outline: 0,
						}}
					/>
				);
		}
	};

	const renderValue = () => {
		if (value) {
			if (value.length >= 12) {
				return value.substr(0, 12) + "...";
			}
			return value;
		}

		return "-";
	};

	return (
		<div
			onBlur={onBlur}
			onClick={() => setIsSelected(true)}
			style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
		>
			{value === "Offer" && !isSelected && <ColoredCircle color={"#FF9900"} />}
			{value === "Available" && !isSelected && <ColoredCircle color={"#00FF22"} />}
			{value === "Sold" && !isSelected && <ColoredCircle color={"#FF0000"} />}
			{isSelected ? renderInput() : renderValue()}
		</div>
	);
};
const defaultColumn = {
	Cell: EditableCell,
};

export interface FeatureProps {
	editable?: boolean;
	paginationEnabled?: boolean;
	searchable?: boolean;
	sortable?: boolean;
	selectable?: boolean;
	batchDeletable?: boolean;
	hiddenColumns?: string[];
}

export interface TableProps extends FeatureProps {
	columns: any;
	data: any;
	currentPage?: number;
	setCurrentPage?: (page: number) => void;
	onEdit?: () => void;
	onAddRow?: () => void;
	onAddColumn?: () => void;
	onDelete?: (ids: string[]) => void;
	onGo?: (id: string) => void;
	onClickRow?: (id: number) => void;
	updateData?: (rowIndex: string, columnId: string, value: any) => void;
}

const Table = ({
	columns,
	data,
	onClickRow,
	onAddRow,
	onAddColumn,
	onGo,
	currentPage,
	setCurrentPage,
	onDelete,
	updateData,
	editable,
	paginationEnabled,
	searchable,
	batchDeletable,
	sortable,
	selectable,
	hiddenColumns,
}: TableProps) => {
	const makeRowsSelectable = (hooks: any) => {
		if (selectable) {
			hooks.visibleColumns.push((columns: any) => [
				{
					id: "selection",
					Header: ({ getToggleAllRowsSelectedProps }: any) => (
						<div>
							<Checkbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					Cell: ({ row }: any) => (
						<div>
							<Checkbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				...columns,
			]);
		}
	};

	const addActionsButton = (hooks: any) => {
		if (onGo) {
			hooks.visibleColumns.push((columns: any) => [
				...columns,
				{
					id: "deleteAction",
					Header: "Actions",
					Cell: ({ row }: any) => (
						<>{onGo && <button onClick={() => onGo(row.values.id)}>Go</button>}</>
					),
				},
			]);
		}
	};

	const renderEditButtons = () => {
		const addRowButton = (
			<TableButtonAddHouse title='Add Row'>
				<AddRow onClick={onAddRow} />
			</TableButtonAddHouse>
		);
		const addColumnButton = (
			<TableButtonAddColumn title='Add Column'>
				<AddColumn onClick={onAddColumn} />
			</TableButtonAddColumn>
		);
		const deleteRowsButton = (
			<TableButtonRemove title='Remove Row(s)'>
				<AiIcons.AiFillDelete
					onClick={() => {
						onDelete && onDelete(selectedFlatRows.map(row => row.values.id));
					}}
				/>
			</TableButtonRemove>
		);

		return (
			<>
				<TableButtonContainer>
					{onAddRow && addRowButton}
					{onAddColumn && addColumnButton}
					{/* {selectedFlatRows.length > 0 && bulkEditButton} */}
					{selectedFlatRows.length > 0 &&
						onDelete &&
						batchDeletable &&
						deleteRowsButton}
				</TableButtonContainer>
			</>
		);
	};

	const table = useTable(
		{
			columns,
			data,
			autoResetPage: false,
			initialState: {
				pageIndex: 0,
				hiddenColumns: hiddenColumns || [],
				sortBy: [
					{
						id: "customId",
						desc: false,
					},
				],
			},
			...(editable && { defaultColumn }),
			updateData: updateData,
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			makeRowsSelectable(hooks);
			addActionsButton(hooks);
		}
	);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setGlobalFilter,
		setPageSize,
		selectedFlatRows,
		state: { globalFilter, pageIndex, pageSize },
	} = table;

	const renderSearch = () => (
		<GlobalSearch filter={globalFilter} setFilter={setGlobalFilter} />
	);
	const renderHeader = () => (
		<thead>
			{headerGroups.map((headerGroup, i) => (
				<tr {...headerGroup.getHeaderGroupProps()} key={i}>
					{headerGroup.headers.map((column: any, i) =>
						sortable ? (
							<th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
								{column.render("Header")}
								<span>
									{column.isSorted ? (
										column.isSortedDesc ? (
											<AiFillCaretUp />
										) : (
											<AiFillCaretDown />
										)
									) : (
										""
									)}
								</span>
							</th>
						) : (
							<th {...column.getHeaderProps()} key={i}>
								{column.render("Header")}
							</th>
						)
					)}
				</tr>
			))}
		</thead>
	);

	const renderBody = () => (
		<tbody {...getTableBodyProps()}>
			{page &&
				page.map((row, i) => {
					prepareRow(row);
					return (
						<tr
							{...row.getRowProps()}
							onClick={() => {
								onClickRow && onClickRow(row.values.id);
							}}
							key={i}
						>
							{row.cells.map((cell, i) => {
								return cell.column.Header !== "Action" ? (
									<td {...cell.getCellProps()} key={i}>
										{cell.render("Cell")}
									</td>
								) : (
									<td {...cell.getCellProps()} key={i}>
										<IconButton
											size={"medium"}
											onClick={() => {
												onDelete && onDelete([row.values.id]);
											}}
											className={"TableButtonRemove"}
										>
											<img src={deleteIcon} />
										</IconButton>
									</td>
								);
							})}
						</tr>
					);
				})}
		</tbody>
	);

	const renderPagination = () => (
		<div className='paginationBox'>
			<div className='paginationButtons'>
				<button
					className={canPreviousPage ? "prevButton" : "prevButton disabled"}
					onClick={() => {
						previousPage();
					}}
					disabled={!canPreviousPage}
				>
					{`< Previous`}
				</button>
				<button
					className={canNextPage ? "nextButton" : "nextButton disabled"}
					onClick={() => {
						nextPage();
					}}
					disabled={!canNextPage}
				>
					{`Next >`}
				</button>
			</div>
		</div>
	);

	return (
		<>
			{searchable && renderSearch()}
			{editable && renderEditButtons()}
			<table {...getTableProps()} className='fl-table'>
				{renderHeader()}
				{renderBody()}
				{/* {selectedFlatRows &&*/}
			</table>
			{paginationEnabled && renderPagination()}
		</>
	);
};

export default Table;
