import React, { useEffect, useRef, useState } from "react";
import Table from "../index";
import TypeService from "../../../../services/TypeService";
import { Property, Type } from "../../../../types/Type";
import { ColumnType, Orientation, Status } from "../house-table/HouseTable";
import Modal from "react-modal";
import { BackButton } from "../TableElements";
import { toast } from "react-toastify";

export type CustomColumnType = {
	Header: string;
	accessor: string;
	type?: any;
	values?: any[];
};

const baseTypeTableColumns = [
	{
		Header: "Id",
		accessor: "id",
	},
	{
		Header: "Type",
		accessor: "customId",
	},
	{
		Header: "Label",
		accessor: "label",
	},
	{
		Header: "Status",
		accessor: "status",
		type: ColumnType.SELECT,
		values: Object.keys(Status).filter((key: any) => !isNaN(Number(Status[key]))),
	},
	{
		Header: "House Area",
		accessor: "houseArea",
		type: ColumnType.NUMBER,
	},
	{
		Header: "Room count",
		accessor: "roomsQuantity",
		type: ColumnType.NUMBER,
	},
	{
		Header: "Orientation",
		accessor: "orientation",
		type: ColumnType.SELECT,
		values: Object.keys(Orientation).filter(
			(key: any) => !isNaN(Number(Orientation[key]))
		),
	},
	{
		Header: "Terrace",
		accessor: "terrace",
	},
	{
		Header: "Price",
		accessor: "price",
		type: ColumnType.NUMBER,
	},
	{
		Header: "Action",
		accessor: "delete",
		type: ColumnType.NUMBER,
	},
];

const TypeTable = () => {
	const [types, setTypes] = useState<Type[]>([]);
	const [data, setData] = useState<any>([]);
	const previousValues = useRef({ data });
	const [typesIdsToDelete, setTypesIdsToDelete] = useState<string[]>([]);
	const [columns, setColumns] = useState<any[]>(baseTypeTableColumns);

	const [isNewColumnModalOpen, setIsNewColumnModalOpen] =
		useState<boolean>(false);

	const [newColumnName, setNewColumnName] = useState<string>("");
	const [newColumnType, setNewColumnType] = useState<number>(-1);
	const [newColumnSelectValues, setNewColumnSelectValues] = useState<string>("");

	useEffect(() => {
		TypeService.getAllTypes().then(types => {
			setTypes(types);
		});
	}, []);

	useEffect(() => {
		if (types && types.length > 0) {
			parseMapPropertiesToNewColumnsAndData();
		}
	}, [types]);

	useEffect(() => {
		if (typesIdsToDelete && typesIdsToDelete.length !== 0) {
			setData(data.filter((house: any) => !typesIdsToDelete.includes(house.id)));
			//setTypesIdsToDelete([]);
		}
	}, [typesIdsToDelete]);

	const isColumnAlreadyIn = (
		newColumns: CustomColumnType[],
		typeName: string
	) => {
		return ![
			...newColumns.map((column: CustomColumnType) =>
				column.accessor.toLowerCase()
			),
		].includes(typeName.toLowerCase());
	};

	const mapPropertiesToMap = (type: Type): Map<string, Property> => {
		const propertyMap = new Map<string, Property>();
		if (type && type.mapProperties) {
			for (const key in type.mapProperties) {
				propertyMap.set(key, type.mapProperties[key]);
			}
		}

		return propertyMap;
	};

	const parseMapToNewColumns = (
		typeName: string,
		typeProperty: Property,
		newColumns: CustomColumnType[]
	) => {
		if (isColumnAlreadyIn(newColumns, typeName)) {
			const newColumn: CustomColumnType = {
				Header: typeProperty.name,
				accessor: typeName,
				type: typeProperty.columnType,
			};
			if (newColumn.type === ColumnType.SELECT) {
				newColumn.values = typeProperty.values;
			}
			newColumns.push(newColumn);
		}
	};

	const parseMapPropertiesToNewColumnsAndData = () => {
		if (types && types.length > 0) {
			const newColumns: CustomColumnType[] = [];
			const data: any = [];
			types.forEach(type => {
				const propertyMap = mapPropertiesToMap(type);
				const newEntry: any = {
					id: type.id,
					customId: type.customId,
					label: type.label,
				};
				for (const [typeName, typeProperty] of propertyMap.entries()) {
					if (
						!baseTypeTableColumns.find(column => column.Header === typeProperty.name)
					) {
						parseMapToNewColumns(typeName, typeProperty, newColumns);
					}
					newEntry[typeName] = typeProperty.value;
				}
				data.push(newEntry);
			});

			setColumns([...baseTypeTableColumns].concat(newColumns));
			setData(data);
		}
	};

	const updateData = (rowIndex: string, columnId: string, value: any) => {
		setData((old: any) =>
			old.map((row: any, index: any) => {
				if (index === parseInt(rowIndex)) {
					return {
						...old[rowIndex],
						[columnId]: value,
					};
				}
				return row;
			})
		);
	};

	useEffect(() => {
		if (typesIdsToDelete.length > 0) {
			setTypesIdsToDelete([]);
		}
	}, [data]);
	useEffect(() => {
		if (typesIdsToDelete.length > 0)
			typesIdsToDelete.forEach(typeIdToDelete => {
				TypeService.deleteType(typeIdToDelete)
					.then(() => toast.success("Type deleted"))
					.catch(e => toast.error(e.message));
			});
	}, [typesIdsToDelete]);
	const handleSave = () => {
		const typesToUpdate: Type[] = [];
		data.forEach((type: any) => {
			const mapProperties: any = {};
			let currentAccessor = "";
			for (const [key, value] of Object.entries(columns)) {
				if (
					value.accessor !== "customId" &&
					value.accessor !== "id" &&
					value.accessor !== "label"
				) {
					currentAccessor = value.accessor;
					for (const [typeKey, typeValue] of Object.entries(type)) {
						if (typeKey === currentAccessor) {
							mapProperties[currentAccessor] = {
								name: value.Header,
								columnType: value.type,
								value: typeValue,
							};
							if (value.type === ColumnType.SELECT) {
								mapProperties[currentAccessor].values = value.values;
							}
						}
					}
				}
			}

			typesToUpdate.push({
				id: type.id,
				customId: type.customId,
				label: type.label,
				mapProperties,
			});
		});
		TypeService.updateAllTypesForAccount(typesToUpdate).then(resp => {
			setTypes(resp.data);
		});
	};

	const renderModal = () => {
		return (
			<Modal
				isOpen={isNewColumnModalOpen}
				onRequestClose={() => setIsNewColumnModalOpen(false)}
				ariaHideApp={false}
				style={{
					overlay: {
						// backgroundColor: 'papayawhip'
						zIndex: 1000,
					},
					content: {
						width: "400px",
						height: "200px",
						top: "50%",
						left: "50%",
						right: "auto",
						bottom: "auto",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
					},
				}}
			>
				<input
					type='text'
					placeholder='column name'
					onChange={e => setNewColumnName(e.target.value)}
				/>
				<select
					value={newColumnType}
					onChange={e => setNewColumnType(parseInt(e.target.value))}
				>
					<option value={-1} selected>
						Text
					</option>
					<option value={0}>Number</option>
					<option value={1}>Select</option>
					<option value={2}>Money</option>
				</select>
				{newColumnType === 1 && (
					<input
						type='text'
						onChange={e => setNewColumnSelectValues(e.target.value)}
					/>
				)}
				<button
					onClick={() => {
						const newColumn: CustomColumnType = {
							Header: newColumnName,
							accessor: newColumnName,
							type: newColumnType,
						};
						if (newColumnType === ColumnType.SELECT) {
							newColumn.values = newColumnSelectValues
								.trim()
								.split(",")
								.map(value => value.replaceAll(/\s/g, ""));
						}
						setColumns((prevState: any[]) => [...prevState, { ...newColumn }]);
						setIsNewColumnModalOpen(false);
					}}
				>
					Add new column
				</button>
			</Modal>
		);
	};

	return (
		<>
			<Table
				columns={columns}
				data={data}
				updateData={updateData}
				hiddenColumns={["id"]}
				paginationEnabled
				editable
				selectable
				onAddRow={() => {
					const typeDefaultValue = "Type #" + (data.length + 1);
					setData((prevState: any[]) => [
						...prevState,
						{ customId: typeDefaultValue },
					]);
					toast.success("Type added");
				}}
				onAddColumn={() => {
					setIsNewColumnModalOpen(true);
				}}
				onDelete={(ids: string[]) => {
					setTypesIdsToDelete(ids);
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "end",
				}}
			>
				<BackButton onClick={handleSave}>Save</BackButton>
			</div>
			{renderModal()}
		</>
	);
};
export default TypeTable;
