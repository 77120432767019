import { ADD_VIEW, GET_PROJECT, SWITCH_VIEW } from "./Actions";
import { ProjectState } from "../../../types/Project";
import { View } from "../../../types/View";

export const getProject = (project: ProjectState) => ({
	type: GET_PROJECT,
	payload: project,
});

export const addView = (project: ProjectState) => ({
	type: ADD_VIEW,
	payload: project,
});

export const switchView = (view: View) => ({
	type: SWITCH_VIEW,
	payload: view,
});
