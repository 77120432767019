import styled from "styled-components";

export const Nav = styled.nav`
	padding-top: 100px;
	position: fixed;
	height: 100vh;
	width: 380px;
	background: #201c1d;
	display: flex;
	flex-direction: column;
	z-index: 999;

	a {
		cursor: pointer;
		color: #ffffff;
		font-size: 16px;
		font-family: Silka;
		text-decoration: none;
		padding: 22px;
		width: 100%;
		transition: all 0.23s;
		display: flex;
		align-items: center;

		img {
			padding-right: 20px;
		}

		&:hover {
			background: #707070;
		}
	}

	a.logout {
		margin-top: 50px;
	}

	.disabled {
		cursor: not-allowed;

		&:hover {
			background: red !important;
		}
	}
`;

export const UserInfoWrapper = styled.div`
	width: 100%;
	padding-bottom: 150px;
	padding-left: 30px;

	p {
		color: white;
		font-size: 16px;
		font-family: Silka;
	}

	.user-icon {
		padding-right: 15px;
	}

	p:last-child {
		opacity: 70%;
	}
`;
