import { AnyAction } from "redux";
import { TOOL_VIEW_GET, TOOL_VIEW_SET } from "../actions/Actions";

const initialState = {
	view: false,
};

export const toolReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case TOOL_VIEW_GET:
			return { ...state };
		case TOOL_VIEW_SET:
			return {
				...state,
				view: action.payload,
			};
		default:
			return { ...state };
	}
};
