import JSZip from "jszip";
import React from "react";
import { IFrameClipboardButton } from "./IFrameClipBoardButton";
import { Container, DeleteButton, NumberOfHouses, SvgDownload } from "./styles";
import { BsFillFileZipFill } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";

type Props = {
	aerialViewImage: string;
	name: string;
	houses: number;
	projectId: string;
	onGo: (id: string) => void;
	onDelete: () => void;
};

const ProjectItem = ({
	aerialViewImage,
	name,
	houses,
	onGo,
	projectId,
	onDelete,
}: Props) => {
	const handleExport = () => {
		if (aerialViewImage) {
			let imageName: string = aerialViewImage ? name : "image";
			const svgName: string = aerialViewImage ? name + ".svg" : "export.svg";
			fetch(aerialViewImage)
				.then(res => res.blob()) // Gets the response and returns it as a blob
				.then(imageBlob => {
					if (imageBlob.type === "image/jpeg") {
						imageName += ".jpg";
					} else if (imageBlob.type === "image/png") {
						imageName += ".png";
					}
					const img = new Image();
					img.src = aerialViewImage;

					const zip = new JSZip();
					zip.file(svgName, "asd");
					zip.file(imageName, imageBlob);
					zip.generateAsync({ type: "base64" }).then(function (content: any) {
						const link = document.createElement("a");
						link.href = "data:application/zip;base64," + content;
						link.download = name ? name + ".zip" : "export.zip";
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					});
				});
		}
	};

	return (
		<Container id={projectId}>
			<DeleteButton onClick={() => onDelete()}>
				<IoIosClose className='close-btn' />
			</DeleteButton>
			<img src={aerialViewImage} onClick={() => onGo(projectId)} />
			<NumberOfHouses>{houses}</NumberOfHouses>
			<p>{name}</p>
			<span className='btn-wrapper'>
				<SvgDownload onClick={() => handleExport()}>
					<BsFillFileZipFill />
					SVG
				</SvgDownload>
				<IFrameClipboardButton projectId={projectId} />
			</span>
		</Container>
	);
};

export default ProjectItem;
