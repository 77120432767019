import cloneDeep from "clone-deep";
import React, { useCallback, useContext, useState } from "react";
import { HouseContext } from "tool/components/context/HouseContext";
import { ProjectState } from "types/Project";
import { House } from "types/House";
import { MenuWrapper, SearchBar } from "./styles";

type HouseChangeProps = {
	houses: House[];
	savedPolygons: any;
	updateProjectData: (data: ProjectState) => void;
	project: ProjectState;
	disableContextMenu: (e: any) => void;
	setContextMenu: (contextMenu: boolean) => void;
	setIsContextMenuButtonClicked: (value: boolean) => void;
	enableWheel: boolean;
};

const HouseChange = ({
	houses,
	savedPolygons,
	updateProjectData,
	project,
	disableContextMenu,
	setContextMenu,
	setIsContextMenuButtonClicked,
	enableWheel,
}: HouseChangeProps) => {
	const [housesState, setHousesState] = useState<House[]>(houses);
	const { house, setHouse, polygonId } = useContext(HouseContext);

	const updateHouse = (
		cloneProject: ProjectState,
		houseId: string,
		currentPolygon: any
	) => {
		const houseIndexToRemovePolygon = cloneProject.houses.findIndex(
			(item: any) => item.id === house?.id
		);
		const polygonIndex = cloneProject.houses[
			houseIndexToRemovePolygon
		].polygons.findIndex((polygon: any) => polygon.id === polygonId);
		cloneProject.houses[houseIndexToRemovePolygon].polygons.splice(
			polygonIndex,
			1
		);
		const houseIndexToPushPolygon = cloneProject.houses.findIndex(
			(item: any) => item.id === houseId
		);
		cloneProject.houses[houseIndexToPushPolygon].polygons.push(currentPolygon);

		setHouse(cloneProject.houses[houseIndexToPushPolygon]);
	};

	const updateView = (cloneProject: ProjectState, currentPolygon: any) => {
		const viewIndexToRemovePolygon = cloneProject.views.findIndex(
			(item: any) => item.id === cloneProject.currentView.id
		);
		const polygonViewIndex = cloneProject.views[
			viewIndexToRemovePolygon
		].polygons.findIndex((polygon: any) => polygon.id === polygonId);
		cloneProject.views[viewIndexToRemovePolygon].polygons.splice(
			polygonViewIndex,
			1
		);
		cloneProject.views[viewIndexToRemovePolygon].polygons.push(currentPolygon);
	};

	const changeHouse = (houseId: string) => {
		const clonePolygons = cloneDeep(savedPolygons);
		const indexOfCurrentPolygon = clonePolygons.findIndex(
			(polygon: any) => polygon.id === polygonId
		);
		clonePolygons[indexOfCurrentPolygon].houseId = houseId;
		const currentPolygon = clonePolygons[indexOfCurrentPolygon];

		const cloneProject: ProjectState = cloneDeep(project);

		updateHouse(cloneProject, houseId, currentPolygon);
		updateView(cloneProject, currentPolygon);

		updateProjectData(cloneProject);

		setContextMenu(false);
		setIsContextMenuButtonClicked(false);
	};

	const filterHouses = useCallback(
		(filter: string) => {
			const filteredHouses = houses.filter((h: any) =>
				String(h.customId).includes(filter)
			);
			setHousesState(filteredHouses);
		},
		[houses]
	);

	return (
		<MenuWrapper
			onContextMenu={e => disableContextMenu(e)}
			id='menu'
			style={{ pointerEvents: enableWheel ? "none" : "auto" }}
			$units
		>
			<SearchBar
				id='searchBar-house'
				placeholder='Zoeken'
				onChange={event => filterHouses(event.target.value)}
			/>
			{housesState.map((h: any, index: number) => {
				return (
					<p
						key={index}
						id='menuItem'
						onClick={() => changeHouse(h.id)}
						style={house?.id === h.id ? { backgroundColor: "#508ef2" } : {}}
					>
						{h.customId}
					</p>
				);
			})}
		</MenuWrapper>
	);
};

export default HouseChange;
