import React, { useContext } from "react";
import styled from "styled-components";
import { HouseContext } from "../../../context/HouseContext";
import { findPolygonCenter } from "../utils/MapBuildUtils";
import { Point, PolygonType } from "../../../../../types/PolygonType";
import { ToolboxContext } from "../../../context/ToolboxContext";
import { House } from "../../../../../types/House";

export function Polygon(props: {
	polygon: PolygonType;
	fillColor: string;
	fillOpacity: number;
	strokeOpacity: number;
	cornersRadius: number;
	isDrawingPath?: boolean | null | undefined;
	currentScale: number;
	setMenuHouses?: (value: boolean) => void;
	houseId?: string;
	houseCurrent?: House;
	setSelectedPlot?: (id: string | null) => void;
	selectedPlot?: string | null;
	isContextMenuButtonClicked?: boolean;
	setIsContextMenuButtonClicked?: (value: boolean) => void;
	isShiftHold: boolean;
}) {
	const {
		polygon,
		fillColor,
		fillOpacity,
		strokeOpacity,
		cornersRadius,
		currentScale,
		isDrawingPath,
		setMenuHouses,
		houseId,
		houseCurrent,
		setSelectedPlot,
		selectedPlot,
		isShiftHold,
	} = props;

	const {
		isMovingPolygon,
		setIsDrawingButtonClicked,
		isPointerButtonClicked,
		isDrawGuideLineButtonClicked,
		isDrawingButtonClicked,
		isEditButtonClicked,
		isContextMenuButtonClicked,
		setIsContextMenuButtonClicked,
		isHouseNumbersShown,
	} = useContext(ToolboxContext);

	const center: Point = findPolygonCenter(polygon);
	const magneticPolygonScale = 1 + 0.07 / currentScale;
	const scaledCenterX = center.x - magneticPolygonScale * center.x;
	const scaledCenterY = center.y - magneticPolygonScale * center.y;
	const { house, polygonId, setHouse, newPlot } = useContext(HouseContext);
	const disableEvents = polygon.id === "x-polygon";

	function leftClick(e: any) {
		if (polygonId !== polygon.id && isPointerButtonClicked) {
			setIsDrawingButtonClicked(false);
			if (setSelectedPlot && houseCurrent) {
				setSelectedPlot(polygon.id);
				setHouse(houseCurrent);
			}
		}

		if (
			e.target.className.baseVal === "changeHouseId" &&
			isPointerButtonClicked
		) {
			if (setMenuHouses) {
				setMenuHouses(true);
			}
			setIsContextMenuButtonClicked(true);
		}
	}

	function rightClick(e: any) {
		if (isPointerButtonClicked && selectedPlot && setMenuHouses) {
			setMenuHouses(true);
		}
	}

	return (
		<g>
			{isDrawingPath && (
				<MagneticSVGPolygon
					transform={`matrix(${magneticPolygonScale}, 0, 0, ${magneticPolygonScale}, ${scaledCenterX}, ${scaledCenterY})`}
					style={{ pointerEvents: disableEvents ? "none" : "auto" }}
					id={"magnetic-polygon;" + polygon.id}
					points={Array.from(polygon.points).reduce(
						(str: string, point: Point) => str + " " + point.x + "," + point.y,
						""
					)}
				/>
			)}

			{/* Polygon*/}
			<SVGPolygon
				style={{ pointerEvents: disableEvents ? "none" : "auto" }}
				id={polygon.id}
				fill={fillColor}
				fillOpacity={fillOpacity}
				stroke={polygon.id === polygonId ? "red" : "white"}
				strokeOpacity={strokeOpacity}
				strokeWidth={
					polygon.id === polygonId
						? `${2 / currentScale}px`
						: `${1 / currentScale}px`
				}
				points={Array.from(polygon.points).reduce(
					(str: string, point: Point) => str + " " + point.x + "," + point.y,
					""
				)}
				currentScale={currentScale}
				isActive={
					polygon.id ===
						(house && house.polygons.length > 0 ? polygonId : undefined) ||
					polygon.id === "x-polygon" ||
					polygon.id === polygonId
				}
				onContextMenu={e =>
					!isDrawGuideLineButtonClicked && !isDrawingButtonClicked && rightClick(e)
				}
			/>

			{/* Points*/}
			{!isMovingPolygon && (polygon.id === "x-polygon" || polygon.id === polygonId)
				? Array.from(polygon.points).map(
						(point: Point, i) =>
							(!props.isDrawingPath || i < polygon.points.length - 1) && (
								<SVGCircle
									style={{
										pointerEvents: disableEvents && i > 0 ? "none" : "auto",
										// stroke: isNewPath ? "green" : "",
									}}
									key={i}
									id={"edit-circle"}
									cx={point.x}
									cy={point.y}
									sizer={2}
									r={cornersRadius / currentScale}
									fill={"#5288f5"}
									data-polygonId={polygon.id}
									currentScale={currentScale}
								/>
							)
				  )
				: (isDrawingButtonClicked || isEditButtonClicked) &&
				  !isShiftHold &&
				  Array.from(polygon.points).map((point: Point, i) => (
						<SVGMagneticCircle
							key={i}
							cx={point.x}
							cy={point.y}
							data-polygonId={polygon.id}
							currentScale={currentScale}
						/>
				  ))}

			{/* White circle*/}
			{!isMovingPolygon && !newPlot && isHouseNumbersShown && (
				<SVGCircle
					id={"center"}
					style={{
						pointerEvents: disableEvents ? "none" : "auto",
						fill: "white",
						stroke: "white",
						cursor:
							!isDrawGuideLineButtonClicked &&
							!isDrawingButtonClicked &&
							polygon.id === polygonId
								? "move"
								: "",
					}}
					key={polygon.points.length}
					cx={center.x}
					cy={center.y}
					sizer={4.1}
					r={cornersRadius / currentScale}
					fill={fillColor}
					data-polygonId={polygon.id}
					currentScale={currentScale}
					onClick={e =>
						!isDrawGuideLineButtonClicked && !isDrawingButtonClicked && leftClick(e)
					}
				/>
			)}

			{/* Text in white circle*/}
			{!isMovingPolygon && !newPlot && isHouseNumbersShown && (
				<>
					<circle
						onClick={e =>
							!isDrawGuideLineButtonClicked && !isDrawingButtonClicked && leftClick(e)
						}
						cx={center.x + 4.5 / currentScale}
						cy={center.y - 8 / currentScale}
						id={`${polygon.id}-houseId`}
						className={"changeHouseId"}
						r={3.5 / currentScale}
						style={{
							fill: "orange",
							border: isContextMenuButtonClicked ? "black solid 1px" : "",
							strokeWidth: 0.5 / currentScale,
							position: "absolute",
							cursor:
								isDrawingButtonClicked ||
								isDrawGuideLineButtonClicked ||
								polygon.id !== polygonId
									? "default"
									: "pointer",
						}}
					/>
					<text
						onClick={e =>
							!isDrawGuideLineButtonClicked && !isDrawingButtonClicked && leftClick(e)
						}
						x={center.x + 2 / currentScale}
						y={center.y - 7.8 / currentScale}
						id={`${polygon.id}-houseId`}
						className={"changeHouseId"}
						fontSize={7 / currentScale}
						fontFamily="'Silka', sans-serif"
						fill='black'
						style={{
							position: "absolute",
							fontWeight: "bold",
							cursor:
								isDrawingButtonClicked ||
								isDrawGuideLineButtonClicked ||
								polygon.id !== polygonId
									? "default"
									: "pointer",
						}}
					>
						...
					</text>
					<text
						x={
							houseId && houseId.length > 1
								? center.x - 4.7 / currentScale
								: center.x - 4 / currentScale
						}
						y={center.y + 2 / currentScale}
						onClick={e =>
							!isDrawGuideLineButtonClicked && !isDrawingButtonClicked && leftClick(e)
						}
						id={`houseId`}
						data-polygonId={polygon.id}
						fontSize={
							houseId && houseId.length > 1 ? 6 / currentScale : 7 / currentScale
						}
						fontFamily="'Silka', sans-serif"
						fill='black'
						style={{
							position: "absolute",
							fontWeight: "bold",
							cursor:
								!isDrawGuideLineButtonClicked &&
								!isDrawingButtonClicked &&
								polygon.id === polygonId
									? "move"
									: "",
						}}
					>
						{houseId}
					</text>
				</>
			)}
		</g>
	);
}

const SVGCircle = styled.circle<{ currentScale: number; sizer: number }>`
	fill: white;
	r: ${props => props.sizer / props.currentScale}pt;
	stroke: ${props => props.fill};
	stroke-width: ${props => props.sizer / props.currentScale}pt;
	stroke-dasharray: 0;
	stroke-linejoin: round;
`;

const SVGMagneticCircle = styled.circle<{ currentScale: number }>`
	fill: white;
	fill-opacity: 0;
	stroke: red;
	stroke-width: ${props => 2 / props.currentScale}pt;
	stroke-opacity: 0;
	r: ${props => 2 / props.currentScale}pt;

	&:hover {
		fill-opacity: 1;
		stroke-opacity: 1;

		r: ${props => 2 / props.currentScale}pt;
	}
`;

const SVGPolygon = styled.polygon<{ currentScale: number; isActive: boolean }>`
	stroke-width: ${props => Number(props.strokeWidth) / props.currentScale};

	&:hover {
		stroke-width: ${props =>
			(Number(props.strokeWidth) + 1) / props.currentScale};
		fill-opacity: ${props =>
			props.fillOpacity
				? props.isActive
					? Number(props.fillOpacity) + 0.2
					: props.fillOpacity
				: 0};
		stroke-opacity: ${props => props.strokeOpacity};
	}
`;

const MagneticSVGPolygon = styled.polygon`
	fill-opacity: 0;
	stroke-width: ${props => Number(props.strokeWidth)};

	&:hover {
		fill-opacity: 0;
	}
`;
