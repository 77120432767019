import React, { useEffect, useState } from "react";
import { Tab } from "./ViewsBarElements";
import { View } from "../../../types/View";
import TabMenu from "./TabMenu";
import { useDispatch } from "react-redux";
import { switchView } from "../../../platform/redux/actions/ProjectActions";
import cloneDeep from "clone-deep";
import ViewNameInput from "./ViewNameInput";
import { IsHome } from "./IsHome";

export type TabProps = {
	view: View;
	canBeDeleted: boolean;
	handleUpdateView: (view: View) => void;
};

const ViewTab = ({ view, canBeDeleted, handleUpdateView }: TabProps) => {
	const dispatch = useDispatch();
	const [name, setName] = useState<string>(view.name);
	const [inputDisabled, setInputDisabled] = useState<boolean>(true);

	useEffect(() => {
		setName(view.name);
	}, [view]);

	const handleSwitchTab = () => {
		dispatch(switchView(view));
	};

	const makeHome = (event: any) => {
		event.stopPropagation();

		if (view.isHome) return;

		const viewToUpdate: View = cloneDeep(view);
		viewToUpdate.isHome = true;
		handleUpdateView(viewToUpdate);
	};

	return (
		<Tab onClick={handleSwitchTab}>
			<IsHome isHome={view.isHome} onClick={makeHome} />
			<ViewNameInput
				value={name}
				onChange={e => setName(e.target.value)}
				disabled={inputDisabled}
			/>
			<TabMenu
				canBeDeleted={canBeDeleted}
				view={view}
				handlePencilMenu={() => setInputDisabled(!inputDisabled)}
			/>
		</Tab>
	);
};

export default ViewTab;
