import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: 100vh;
	background: #484848;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const AwaitingRequest = styled.text`
	color: #ffffff;
	font-family: Silka, serif;
	font-size: 1.5rem;
	font-weight: 500;
`;
