import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
	ACCOUNT_PATH,
	HOME_PATH,
	LOGIN_PATH,
	NEW_PASSWORD,
	NEW_PROJECT_FLOW,
	NEW_USER_FLOW_PATH,
	PROJECT_DRAW_PATH,
	PROJECTS_PATH,
} from "./platform/utils/constants/PathConstants";
import { useReduxFetch } from "./platform/utils/hooks/useReduxFetch";
import { useSelector } from "react-redux";
import "./platform/configs/i18n/i18nConfig";
import UserService from "./services/UserService";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { RequestHandler } from "./platform/v2/RequestHandler";
import View from "./platform/pages/view/View";
import Project from "./platform/pages/project/Project";
import HeaderLayout from "./platform/components/layouts/HeaderLayout";
import Home from "./platform/pages/home/Home";
import SideBar from "./platform/components/SideBar-new/SideBar";
import Login from "./platform/pages/auth/login/Login";
import NewUserFlow from "./platform/pages/new-user-flow/NewUserFlow";
import ResetPassword from "./platform/pages/auth/reset-password/ResetPassword";
import PrivateRoute from "./platform/utils/PrivateRoute";
import Account from "./platform/pages/account/Account";

const App = () => {
	const { user } = useSelector((state: any) => state.userReducer);
	useReduxFetch(user, UserService.getUser());

	return (
		<BrowserRouter>
			<Switch>
				{/* New Project flow*/}
				<Route exact path={NEW_PROJECT_FLOW} component={RequestHandler} />

				{/* Root & Home */}
				<Route exact path={["/", HOME_PATH]}>
					<HeaderLayout>
						<Home />
					</HeaderLayout>
				</Route>

				{/* Tool */}
				<Route path={"/currentView/:id"} component={View} />
				<Route exact path={PROJECT_DRAW_PATH + ":id/"} component={Project} />

				{/*/!* Auth, Sign up *!/*/}
				<Route path={[LOGIN_PATH, NEW_USER_FLOW_PATH, NEW_PASSWORD]}>
					<SideBar user={user}>
						<Route exact path={LOGIN_PATH} component={Login} />
						<Route exact path={NEW_USER_FLOW_PATH} component={NewUserFlow} />
						<Route exact path={NEW_PASSWORD} component={ResetPassword} />
					</SideBar>
				</Route>

				{/*/!* Private *!/*/}
				<PrivateRoute path={[ACCOUNT_PATH, PROJECTS_PATH]}>
					{user && (
						<SideBar user={user}>
							<PrivateRoute exact path={ACCOUNT_PATH} component={Account} />
							<PrivateRoute exact path={PROJECTS_PATH + ":id/"} component={Project} />
						</SideBar>
					)}
				</PrivateRoute>

				{/* Not found */}
				<Route path='*' exact={true}>
					<Redirect to={"/"} />
				</Route>
			</Switch>
			<ToastContainer autoClose={1500} pauseOnHover={false} />
		</BrowserRouter>
	);
};

export default App;
