import React, { useState } from "react";
import HouseChange from "./HouseChange";
import {
	PolygonContextMenuHead,
	PolygonContextMenuTab,
	PolygonContextMenuWrapper,
} from "./styles";
import { ProjectState } from "types/Project";
import { View } from "types/View";
import { House } from "types/House";
import ViewChange from "./ViewChange";

type PolygonContextMenuProps = {
	contextMenuPosition: any;
	houses: House[];
	views: View[];
	savedPolygons: any;
	updateProjectData: (data: ProjectState) => void;
	project: ProjectState;
	setContextMenu: (contextMenu: boolean) => void;
	setIsContextMenuButtonClicked: (value: boolean) => void;
	enableWheel: boolean;
};

const PolygonContextMenu = ({
	contextMenuPosition,
	houses,
	views,
	savedPolygons,
	updateProjectData,
	project,
	setContextMenu,
	setIsContextMenuButtonClicked,
	enableWheel,
}: PolygonContextMenuProps) => {
	const housesTabId: string = "menu";
	const viewTabId: string = "viewMenu";
	const [activeTab, setActiveTab] = useState<string>(housesTabId);

	const disableContextMenu = (e: any) => {
		e.preventDefault();
	};

	return (
		<PolygonContextMenuWrapper
			top={contextMenuPosition.y}
			left={contextMenuPosition.x}
		>
			<PolygonContextMenuHead>
				<PolygonContextMenuTab
					id={"contextMenuTab"}
					onClick={() => setActiveTab(housesTabId)}
					isActive={housesTabId === activeTab}
				>
					Units
				</PolygonContextMenuTab>
				<PolygonContextMenuTab
					id={"contextMenuTab"}
					onClick={() => setActiveTab(viewTabId)}
					isActive={viewTabId === activeTab}
				>
					Views
				</PolygonContextMenuTab>
			</PolygonContextMenuHead>
			{activeTab === housesTabId && (
				<HouseChange
					houses={houses}
					savedPolygons={savedPolygons}
					updateProjectData={updateProjectData}
					project={project}
					disableContextMenu={disableContextMenu}
					setContextMenu={setContextMenu}
					setIsContextMenuButtonClicked={setIsContextMenuButtonClicked}
					enableWheel={enableWheel}
				/>
			)}

			{activeTab === viewTabId && (
				<ViewChange
					views={views}
					disableContextMenu={disableContextMenu}
					enableWheel={enableWheel}
					setContextMenu={setContextMenu}
					project={project}
					updateProjectData={updateProjectData}
				/>
			)}
		</PolygonContextMenuWrapper>
	);
};

export default PolygonContextMenu;
