import React, { useEffect } from "react";
import UserAccount from "./UserAccount";
import { useDispatch, useSelector } from "react-redux";
import { useReduxFetch } from "../../utils/hooks/useReduxFetch";
import UserService from "../../../services/UserService";
import { ACCOUNT_PATH } from "../../utils/constants/PathConstants";
import { useHistory } from "react-router";
import { TOOL_VIEW_SET } from "../../redux/actions/Actions";

const Account = () => {
	const { user } = useSelector((state: any) => state.userReducer);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		// to show sidebar if currentView is true
		dispatch({ type: TOOL_VIEW_SET, payload: false });
	}, []);

	useEffect(() => {
		if (user) {
			history.push(ACCOUNT_PATH);
		}
	}, [user]);

	useReduxFetch(user, UserService.getUser());
	return (
		<div style={{ display: "flex", paddingBottom: 20 }}>
			<UserAccount />
		</div>
	);
};
export default Account;
